import React, { useEffect, useState } from 'react'
import Container from '@mlcl-digital/mlcl-design/lib/base/Container'
import { toast } from 'react-toastify'
import { Heading, Input, Button, Select, Checkbox, Loader } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'

// Redux
// @ts-expect-error non-ts code
import { actionCreators } from '../../../../actions'
// Components
import InputPhone from '../../../molecules/InputPhone'
// @ts-expect-error non-ts code
import AddressLookUp from '../../../molecules/AddressLookUp'

// Utils
// @ts-expect-error non-ts code
import history from '../../../../utils/browserHistory'
import { renderTextField, reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../utils/telemetry'
import {
  isPartyTypeOrg,
  getContactDetailsData,
  getLatestAddressBasedOnType,
  contactDetailsFormToRequestPayload,
} from '../../../../utils/contactUtils'

// Constants
import {
  STATES,
  PREFERRED_METHOD_OF_COMMS,
  PREFERRED_METHOD_OF_COMMS_EVENT_MAP,
} from '../../../../constants/forms'
import {
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_STATEMENT,
  ADDRESS_TYPE_WORK,
} from '../../../../constants/policies'
import {
  TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
  TOAST_ID_UPDATE_CONTACT_ERROR,
  TOAST_ID_UPDATE_CONTACT_SUCCESS,
} from '../../../../constants/toast'

// Types
import { Store } from '../../../../types/store'
import { propTypes } from '../../../../types/components/PersonalAndContactDetails'

// Form
import SCHEMA, {
  FORM_ID,
  addressFields,
  secondaryAddressFields,
  mapAddressToForm,
} from './form.schema'

import styles from './contactDetailsForm.styles'

const SectionHeading = styled(Heading)(styles.sectionHeading)
const Section = styled('div')(styles.section)
const CheckboxContainer = styled('div')(styles.chechboxContainer)

type Props = propTypes & {
  closeForm: () => void
}

const ContactDetailsForm = (props: Props) => {
  const {
    fields,
    data: { relatedParty },
    closeForm,
  } = props

  const {
    MobileNumber,
    EmailAddress,
    ResidentialAddress,
    PostalAddress,
    WorkAddress,
    AutoAddressToggleLabel,
    AutoAddressPlaceholder,
    Street,
    HouseNo,
    POBox,
    Locality,
    State,
    Country,
    PostCode,
    ManualAddressToggle,
    PostalAddressToggle,
    PreferredMethodOfComms,
    Confirm,
    Cancel,
    IDC,
    CountryRequiredError,
    LocalityRequiredError,
    PostCodeRequiredError,
    StateRequiredError,
    StreetRequiredError,
    EmailRequiredError,
    EmailValidError,
    PhoneRequiredError,
    PhoneValidError,
    PreferredMethodOfCommsRequiredError,
    AutoAddressRequiredError,
  } = reduceAuthorableFields(fields) as { [key: string]: string }

  const FormLabels = {
    CountryRequiredError,
    LocalityRequiredError,
    PostCodeRequiredError,
    StateRequiredError,
    StreetRequiredError,
    EmailRequiredError,
    EmailValidError,
    PhoneRequiredError,
    PhoneValidError,
    PreferredMethodOfCommsRequiredError,
  }

  const {
    contactMethods: { addresses },
  } = relatedParty

  const dispatch = useDispatch()
  const {
    emailAddress,
    contactNumber,
    residentialAddress,
    postalAddress: currentPostalAddress,
  } = getContactDetailsData(relatedParty) as {
    emailAddress: string
    contactNumber: string
    residentialAddress: string
    preferredMethodOfComms: string
    postalAddress: string
  }
  const isPartyTypeBusiness = isPartyTypeOrg(relatedParty?.partyType)

  const primaryAddressType = isPartyTypeBusiness ? ADDRESS_TYPE_WORK : ADDRESS_TYPE_HOME

  const [hasSecondaryAddress, setSecondaryAddress] = useState(!!currentPostalAddress)

  const formData = useSelector((state: Store) => state.forms)[FORM_ID]
  const bancsCustomerNo = useSelector((state: Store) => state?.policies?.clientId)
  const masterList = useSelector((state: Store) => state.masterList)
  const isLoading = useSelector((state: Store) => state.client.isSubmitting)

  useEffect(() => {
    const { formInit } = actionCreators
    const primaryAddressFields = getLatestAddressBasedOnType(addresses, primaryAddressType)
    const postalAddressFields = getLatestAddressBasedOnType(addresses, ADDRESS_TYPE_STATEMENT)
    const schema = SCHEMA(hasSecondaryAddress, FormLabels)
    dispatch(
      formInit(FORM_ID, schema, {
        email: emailAddress,
        phoneCode: contactNumber.split(' ')[0],
        mobilePhone: contactNumber.split(' ')[1],
        address: residentialAddress,
        ...(relatedParty.preferredCommChannel && {
          preferredMethodOfComms: relatedParty.preferredCommChannel,
        }),
        ...(currentPostalAddress && {
          postalAddress: currentPostalAddress,
        }),
        ...mapAddressToForm(primaryAddressFields, false),
        ...(Object.keys(postalAddressFields).length > 0 && {
          ...mapAddressToForm(postalAddressFields, true),
        }),
      })
    )
  }, [])

  const {
    phoneCode,
    mobilePhone,
    email,
    address,
    street,
    houseNo,
    postalNumber,
    locality,
    state,
    country,
    postCode,
    postalAddress,
    postalStreet,
    postalHouseNo,
    postalPostalNumber,
    postalLocality,
    postalState,
    postalCountry,
    postalPostCode,
    preferredMethodOfComms,
  } = formData?.fields ?? {}

  const handleInputChange = ({ value, name }: { value: string; name: string }) => {
    const { formUpdateField } = actionCreators
    dispatch(formUpdateField(FORM_ID, name, { error: false, value }))
  }

  const handleSelectChange = ({
    value,
    name,
  }: {
    value: { value: string; label: string }
    name: string
  }) => {
    const { formUpdateField } = actionCreators
    dispatch(formUpdateField(FORM_ID, name, { error: false, value: value.value }))
    if (name === PREFERRED_METHOD_OF_COMMS) {
      const event = createEvent({
        GA: {
          category: 'Contact details edit modal',
          action: PREFERRED_METHOD_OF_COMMS_EVENT_MAP[value.value],
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Advisor client preferred method of comms edit',
            value: PREFERRED_METHOD_OF_COMMS_EVENT_MAP[value.value],
          },
        },
      })
      event.end()
    }
  }

  const handleAutomaticAddressChange = (addressData: {
    data: { [key: string]: string }
    name: string
    value: string
  }) => {
    const { formUpdate } = actionCreators
    const { data, name, value } = addressData
    const formFields = Object.keys(data).reduce(
      (addressObject, key) => ({
        ...addressObject,
        [key]: {
          value: data[key],
          error: false,
        },
      }),
      {
        [name]: {
          value,
          error: false,
        },
      }
    )
    dispatch(formUpdate(FORM_ID, formFields))
  }

  const handleAddressToggle = (isManual: boolean, name: string) => {
    const { formUpdate } = actionCreators
    const refreshedAddressFields = Object.keys(
      name === 'address' ? addressFields() : secondaryAddressFields()
    ).reduce(
      (formFields, key) => ({
        ...formFields,
        [key]: {
          value: '',
          error: false,
        },
      }),
      {}
    )
    dispatch(formUpdate(FORM_ID, refreshedAddressFields))
  }

  const handleSecondaryAddressToggle = ({ value }: { value: string }) => {
    const isSet = !value
    setSecondaryAddress(isSet)
    const schema = SCHEMA(isSet, FormLabels)
    const { formInit } = actionCreators
    dispatch(
      formInit(
        FORM_ID,
        schema,
        Object.keys(formData.fields).reduce(
          (formFields, key) => ({
            ...formFields,
            [key]: formData.fields[key].value,
          }),
          {}
        )
      )
    )
  }

  const handleFormSubmit = () => {
    const schema = SCHEMA(hasSecondaryAddress, FormLabels)
    const { formSubmit, formValidate, updateClientDetails, getPolicies } = actionCreators
    const event = createEvent({
      GA: {
        category: 'Contact details edit modal',
        action: 'CP - confirm contact details',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Contact details edit modal submit',
        },
      },
    })
    dispatch(formValidate(FORM_ID, schema))
    dispatch(
      formSubmit(FORM_ID, schema, (data: { [key: string]: string }) => {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          updateClientDetails(
            {
              clientId: props.data.bancsCustomerNo,
              data: contactDetailsFormToRequestPayload(
                data,
                relatedParty,
                isPartyTypeBusiness ? ADDRESS_TYPE_WORK : ADDRESS_TYPE_HOME,
                masterList
              ),
            },
            (response: object, err: object) => {
              if (err) {
                const errorEvent = createEvent({
                  GA: {
                    category: 'Contact details edit modal',
                    action: 'error',
                  },
                  Splunk: {
                    attributes: {
                      'workflow.name': 'Contact details edit modal submit',
                      error: true,
                      'error.message': 'Error submitting advisor client contact details',
                    },
                  },
                })
                errorEvent.end()
                toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
                  toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
                  type: toast.TYPE.ERROR,
                })
              } else {
                toast(TOAST_ID_UPDATE_CONTACT_SUCCESS, {
                  toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
                  type: toast.TYPE.SUCCESS,
                })
              }
              if (!err) {
                event.end()
                window.scrollTo(0, 0)
                closeForm()
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                const { isSMSF } = queryString.parse(history.location.search)
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                dispatch(getPolicies(bancsCustomerNo, isSMSF))
              }
            }
          )
        )
      })
    )
  }

  return (
    <Container>
      <Section>
        <SectionHeading variant="h2" size="small">
          {renderTextField(fields.ContactDetailsTitle)}
        </SectionHeading>
        <InputPhone
          selectPhoneCode={{
            placeholder: IDC,
            label: IDC,
            value: phoneCode?.value,
            name: 'phoneCode',
            changeHandler: handleSelectChange,
            error: phoneCode?.error?.error,
            caption: phoneCode?.error?.error && phoneCode?.error?.errorMsg,
          }}
          inputPhoneNumber={{
            placeholder: MobileNumber,
            label: MobileNumber,
            value: mobilePhone?.value,
            name: 'mobilePhone',
            changeHandler: handleInputChange,
            error: mobilePhone?.error?.error,
            caption: mobilePhone?.error?.error && mobilePhone?.error?.errorMsg,
          }}
        />
        <Input
          type="text"
          placeholder={EmailAddress}
          label={EmailAddress}
          value={email?.value}
          name="email"
          changeHandler={handleInputChange}
          error={email?.error?.error}
          caption={email?.error?.error && email?.error?.errorMsg}
          htmlFor="email"
        />
        <AddressLookUp
          name="address"
          showPOBoxField={isPartyTypeBusiness}
          auto={{
            label: isPartyTypeBusiness === true ? WorkAddress : ResidentialAddress,
            placeholder: AutoAddressPlaceholder,
            toggleLabel: AutoAddressToggleLabel,
            value: address?.value,
            selectChangeHandler: handleAutomaticAddressChange,
            addressError: street?.error?.error,
            addressName: 'address',
            addressErrorMessage: AutoAddressRequiredError,
          }}
          manual={{
            streetPlaceholder: Street,
            streetValue: street?.value,
            streetError: street?.error?.error,
            streetRequiredErrorMessage: street?.error?.errorMsg,
            houseNoPlaceholder: HouseNo,
            houseNoValue: houseNo?.value,
            postOfficeNoPlaceholder: POBox,
            postOfficeNoName: 'postalNumber',
            postOfficeNoValue: postalNumber?.value,
            localityPlaceholder: Locality,
            localityLabelValue: locality?.value,
            localityError: locality?.error?.error,
            localityRequiredErrorMessage: locality?.error?.errorMsg,
            statePlaceholder: State,
            stateOptions: STATES,
            stateValue: state?.value,
            stateError: state?.error?.error,
            stateRequiredErrorMessage: state?.error?.errorMsg,
            countryPlaceholder: Country,
            countryValue: country?.value,
            countryError: country?.error?.error,
            countryRequiredErrorMessage: country?.error?.errorMsg,
            postCodePlaceholder: PostCode,
            postCodeValue: postCode?.value,
            postCodeError: postCode?.error?.error,
            postCodeRequiredErrorMessage: postCode?.error?.errorMsg,
            manualToggleLabel: ManualAddressToggle,
            inputEntryHandler: handleInputChange,
          }}
          toggleHandler={handleAddressToggle}
        />
        <CheckboxContainer>
          <Checkbox
            onChangeHandler={handleSecondaryAddressToggle}
            checked={!hasSecondaryAddress}
            text={PostalAddressToggle}
            name="postalAddressToggle"
            htmlFor="postalAddressToggle"
            value="yes"
          />
        </CheckboxContainer>

        {hasSecondaryAddress && (
          <AddressLookUp
            name="postalAddress"
            auto={{
              label: PostalAddress,
              placeholder: AutoAddressPlaceholder,
              toggleLabel: AutoAddressToggleLabel,
              value: postalAddress?.value,
              selectChangeHandler: handleAutomaticAddressChange,
              addressError: postalStreet?.error?.error,
              addressName: 'postalAddress',
              addressErrorMessage: AutoAddressRequiredError,
            }}
            manual={{
              streetPlaceholder: Street,
              streetValue: postalStreet?.value,
              streetError: postalStreet?.error?.error,
              streetRequiredErrorMessage: postalStreet?.error?.errorMsg,
              streetName: 'postalStreet',
              houseNoPlaceholder: HouseNo,
              houseNoValue: postalHouseNo?.value,
              houseNoName: 'postalHouseNo',
              postOfficeNoPlaceholder: POBox,
              postOfficeNoName: 'postalPostalNumber',
              postOfficeNoValue: postalPostalNumber?.value,
              localityPlaceholder: Locality,
              localityLabelValue: postalLocality?.value,
              localityError: postalLocality?.error?.error,
              localityRequiredErrorMessage: postalLocality?.error?.errorMsg,
              localityName: 'postalLocality',
              statePlaceholder: State,
              stateOptions: STATES,
              stateValue: postalState?.value,
              stateError: postalState?.error?.error,
              stateName: 'postalState',
              stateRequiredErrorMessage: postalState?.error?.errorMsg,
              countryPlaceholder: Country,
              countryValue: postalCountry?.value,
              countryError: postalCountry?.error?.error,
              countryRequiredErrorMessage: postalCountry?.error?.errorMsg,
              countryName: 'postalCountry',
              postCodePlaceholder: PostCode,
              postCodeValue: postalPostCode?.value,
              postCodeError: postalPostCode?.error?.error,
              postCodeRequiredErrorMessage: postalPostCode?.error?.errorMsg,
              postCodeName: 'postalPostCode',
              manualToggleLabel: ManualAddressToggle,
              postalType: 'postalPostalType',
              postalNumber: 'postalPostalNumber',
              inputEntryHandler: handleInputChange,
            }}
            toggleHandler={handleAddressToggle}
          />
        )}

        <Select
          label={PreferredMethodOfComms}
          placeholder="None"
          value={preferredMethodOfComms?.value}
          name={PREFERRED_METHOD_OF_COMMS}
          id={PREFERRED_METHOD_OF_COMMS}
          changeHandler={handleSelectChange}
          options={SCHEMA(false, FormLabels).preferredMethodOfComms?.options}
          error={preferredMethodOfComms?.error?.error}
          caption={preferredMethodOfComms?.error?.error && preferredMethodOfComms?.error?.errorMsg}
        />

        <Button size="small" type="button" disabled={isLoading} onClick={handleFormSubmit}>
          {Confirm}
        </Button>
        <Button variant="link" size="small" type="button" onClick={closeForm}>
          {Cancel}
        </Button>
        {isLoading && <Loader />}
      </Section>
    </Container>
  )
}

export default ContactDetailsForm
