import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Checkbox, Modal } from '@mlcl-digital/mlcl-design'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'

// types
import { CoverProps } from '../../../../../types/components/PremiumCalculator'

// utils
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../../utils/telemetry'

// styles
import styles from './options.styles'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'

const OptionsLabel = styled('legend')(styles.label)
const OptionsWrapper = styled('fieldset')(styles.optionsWrapper)
const Value = styled('p')(styles.value)
const OptionModalBtnContainer = styled('div')(styles.optionModalBtnContainer)

const Options = ({ fields, cover, policy }: CoverProps) => {
  const {
    benefitInstanceNo,
    type,
    featuresList = [],
    benefitAsOptionsList = [],
    subBenefitsAsOptionsList = [],
    linkedSGBOBenefits = [],
    benefitName,
    optimiserParentBenefitReference,
    isParentOptimiserBenefit,
  } = cover
  const { bancsPolicyNo, policyInstanceNo } = policy
  const [isChildOptionRemoveModal, setIsChildOptionRemoveModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState<{ featureName: string; value: boolean }>()
  const [noneCheckboxValue, setNoneCheckboxValue] = useState<boolean | null>(null)
  const dispatch = useDispatch()

  const triggerGAOnOptionChange = () => {
    const event = createEvent({
      GA: {
        category: 'PC-BenefitOptions',
        action: 'Benefit Options',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator benefit options',
        },
      },
    })
    event.end()
  }

  // option(feature from bancs) removal/adding back code
  const alterOption = useCallback(({ featureName, value }) => {
    if (value) {
      // resetting removal of option done earlier
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.cancelBenefitOption(
          {
            type,
            benefitInstanceNo,
            name: benefitName,
            optimiserParentBenefitReference,
          },
          bancsPolicyNo,
          featureName,
          policyInstanceNo
        )
      )
      linkedSGBOBenefits.length > 0 &&
        linkedSGBOBenefits.forEach(
          ({ type: cbType, benefitInstanceNo: cbInstanceNo, name: cbInstanceName }) => {
            dispatch(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              actionCreators.cancelBenefitOption(
                {
                  type: cbType,
                  benefitInstanceNo: cbInstanceNo,
                  name: cbInstanceName,
                  optimiserParentBenefitReference,
                },
                bancsPolicyNo,
                featureName,
                policyInstanceNo
              )
            )
          }
        )
    } else {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.removeBenefitOption(
          {
            type,
            benefitInstanceNo,
            name: benefitName,
            optimiserParentBenefitReference,
          },
          bancsPolicyNo,
          featureName,
          policyInstanceNo
        )
      )
      linkedSGBOBenefits.length > 0 &&
        linkedSGBOBenefits.forEach(
          ({ type: cbType, benefitInstanceNo: cbInstanceNo, name: cbInstanceName }) => {
            dispatch(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              actionCreators.removeBenefitOption(
                {
                  type: cbType,
                  benefitInstanceNo: cbInstanceNo,
                  name: cbInstanceName,
                  optimiserParentBenefitReference,
                },
                bancsPolicyNo,
                featureName,
                policyInstanceNo
              )
            )
          }
        )
    }
  }, [])
  const triggerOptionAlteration = useCallback(data => {
    alterOption(data)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetAlterationsQuoteError())
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.altsCalculateQuote((err: object) => {
        const apiEvent = createEvent({
          Splunk: {
            attributes: {
              'workflow.name': 'Premium calculator fetching new premium',
              error: !!err,
            },
          },
        })
        apiEvent.end()
      })
    )
  }, [])
  const handleOptionChange = useCallback((data: { featureName: string; value: boolean }) => {
    triggerGAOnOptionChange()
    if (linkedSGBOBenefits.length > 0 || isParentOptimiserBenefit) {
      // warning modal is shown before executing alteration
      setSelectedOption(data)
      setIsChildOptionRemoveModal(true)
    } else {
      triggerOptionAlteration(data)
    }
  }, [])

  // benefit in bancs shown as option to a benefit e.g. TISO
  // removal/adding back code
  const alterBenefitAsOption = useCallback(
    ({
      featureName,
      value,
      displayedFeatureName,
      benefitInstanceNo: featureBenefitInstanceNo,
    }: {
      featureName: string
      value: boolean
      displayedFeatureName: string
      benefitInstanceNo?: string | number
    }) => {
      if (value) {
        // resetting removal of benefit done earlier,
        // for benefits shown as options (TISO)
        dispatch(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          actionCreators.resetRemoveSumInsured(
            {
              type: featureName,
              benefitInstanceNo: featureBenefitInstanceNo,
            },
            bancsPolicyNo,
            policyInstanceNo
          )
        )
      } else {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          actionCreators.altsRemoveBenefit(
            {
              type: featureName,
              benefitInstanceNo: featureBenefitInstanceNo,
              name: displayedFeatureName,
            },
            bancsPolicyNo,
            policyInstanceNo
          )
        )
      }
    },
    []
  )
  const handleBenefitAsOptionChange = useCallback(
    (data: {
      featureName: string
      displayedFeatureName: string
      value: boolean
      benefitInstanceNo?: string | number
    }) => {
      triggerGAOnOptionChange()
      alterBenefitAsOption(data)
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.altsCalculateQuote((err: object) => {
          const apiEvent = createEvent({
            Splunk: {
              attributes: {
                'workflow.name': 'Premium calculator fetching new premium',
                error: !!err,
              },
            },
          })
          apiEvent.end()
        })
      )
    },
    []
  )

  // None option checkbox handling
  const triggerNoneOption = useCallback(value => {
    featuresList.forEach(feature => {
      if (!feature.disabled) {
        alterOption({ value: !value, ...feature })
      }
    })
    benefitAsOptionsList.forEach(feature => {
      if (!feature.disabled) {
        alterBenefitAsOption({ value: !value, ...feature })
      }
    })
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.altsCalculateQuote((err: object) => {
        const errorEvent = createEvent({
          Splunk: {
            attributes: {
              'workflow.name': 'Premium calculator fetching new premium',
              error: !!err,
            },
          },
        })
        errorEvent.end()
      })
    )
  }, [])
  const handleNoneOption = useCallback(({ value }: { value: boolean }) => {
    triggerGAOnOptionChange()
    if (linkedSGBOBenefits.length > 0 || isParentOptimiserBenefit) {
      // warning modal is shown before executing alteration
      setIsChildOptionRemoveModal(true)
      setNoneCheckboxValue(value)
    } else {
      triggerNoneOption(value)
    }
  }, [])

  // Warning modal for stating change will also impact child benefit
  // Modal is shown for parent benefit only if change will be executed in child benefit also
  // On click of yes, change is executed in benefit and child benefit
  const handleChildOptionModalClose = () => {
    setIsChildOptionRemoveModal(false)
    setNoneCheckboxValue(null)
  }
  const handleOptionModalYesClick = () => {
    handleChildOptionModalClose()
    if (noneCheckboxValue !== null) {
      triggerNoneOption(noneCheckboxValue)
    } else {
      triggerOptionAlteration(selectedOption)
    }
  }

  return (
    <OptionsWrapper>
      <OptionsLabel>{renderTextField(fields.Options?.fields?.Heading)}</OptionsLabel>
      {featuresList?.length || benefitAsOptionsList?.length || subBenefitsAsOptionsList?.length ? (
        <>
          {[...featuresList, ...benefitAsOptionsList].some(({ disabled }) => !disabled) && (
            <Checkbox
              htmlFor={`none-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              name={`none-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              text={renderTextField(fields.None)}
              checked={cover.isNoneOptionsSelected}
              disabled={policy?.isPolicyChangeDisabled}
              value="none"
              onChangeHandler={handleNoneOption}
            />
          )}
          {featuresList.map(feature => (
            <Checkbox
              key={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              htmlFor={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              name={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              text={renderTextField(feature.displayedFeatureName)}
              checked={feature.isSelected}
              disabled={feature.disabled || policy?.isPolicyChangeDisabled}
              onChangeHandler={({ value }: { value: boolean }) =>
                handleOptionChange({ value, ...feature })
              }
            />
          ))}
          {benefitAsOptionsList.map(feature => (
            <Checkbox
              key={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              htmlFor={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              name={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              text={renderTextField(feature.displayedFeatureName)}
              checked={feature.isSelected}
              disabled={feature.disabled || policy?.isPolicyChangeDisabled}
              onChangeHandler={({ value }: { value: boolean }) =>
                handleBenefitAsOptionChange({ value, ...feature })
              }
            />
          ))}
          {subBenefitsAsOptionsList.map(feature => (
            <Checkbox
              key={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              htmlFor={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              name={`${feature.featureName}-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
              text={renderTextField(feature.displayedFeatureName)}
              checked
              value={feature.featureName}
              disabled
            />
          ))}
        </>
      ) : (
        <Value>{renderTextField(fields.None)}</Value>
      )}
      <Modal
        isOpen={isChildOptionRemoveModal}
        onClose={handleChildOptionModalClose}
        title={fields.ChildOptionModalHeading}
        footer={
          <OptionModalBtnContainer>
            <Button onClick={handleOptionModalYesClick}>
              {renderTextField(fields.ChildOptionModalYesBtn)}
            </Button>
            <Button variant="link" onClick={handleChildOptionModalClose}>
              {renderTextField(fields.ChildOptionModalNoBtn)}
            </Button>
          </OptionModalBtnContainer>
        }
      >
        {renderTextField(fields.ChildOptionModalDescription)}
      </Modal>
    </OptionsWrapper>
  )
}

export default Options
