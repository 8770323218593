/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment from 'moment'
import get from 'lodash/get'
import { createSelector } from 'reselect'
import { Store } from '../types/store'
import { historyRecord } from '../types/ClientPolicies'
import { getPaymentCollectionMethodOnPaymentHistory } from '../utils/paymentMethods'
// @ts-ignore
// eslint-disable-next-line import/named
import { dollarAmountWithCommasAndTwoDecimal } from '../utils/formUtils'
// @ts-ignore
import {
  PAYMENT_HISTORY_DATE_FORMAT,
  POLICY_STATUS_NA,
  NAVIGATOR,
  FUND_PAYMENT_METHODS_IOOF,
  MLC_ACC,
} from '../constants/policies'

const getPaymentHistory = (state: Store) => state.policies
export const getMasterData = (state: Store) => (state.masterList && state.masterList.data) || {}

export const getPoliciesPaymentMeta = (policyId: string) =>
  createSelector([getPaymentHistory, getMasterData], (policies, masterData) => {
    const selectedPolicy = get(
      (policies.data || {}).find(policy => policy.bancsPolicyNo === policyId),
      'policy'
    )
    const { policyStatus = [] } = masterData
    const currentStatus = get(
      policyStatus.find(
        p => p.status.toUpperCase() === ((selectedPolicy || {}).status || '').toUpperCase()
      ),
      'value',
      POLICY_STATUS_NA
    )
    return {
      isPolicyOutOfForce: !(currentStatus.toUpperCase() === POLICY_STATUS_NA),
      isIOOForNavigator:
        (selectedPolicy || {}).premiumPaymentMethod === MLC_ACC &&
        [...FUND_PAYMENT_METHODS_IOOF, NAVIGATOR].includes(
          get(((selectedPolicy || {}).paymentDetails || {}).fundDetails, 'sourceFundingSystem', '')
        ),
      bPayPayInReference: (selectedPolicy || {}).bPayPayInReference,
      hasFetchError: policies.hasFetchError,
      details: (selectedPolicy || {}).paymentDetails,
      history: (((selectedPolicy || {}).paymentHistory || {}).history || []).map(
        (paymentRecord: historyRecord) =>
          paymentRecord &&
          ({
            policyId,
            paymentMethod:
              getPaymentCollectionMethodOnPaymentHistory(
                (selectedPolicy || {}).paymentDetails,
                paymentRecord
              ) || '-',
            date: moment(paymentRecord.paymentDate, PAYMENT_HISTORY_DATE_FORMAT).format(
              'DD MMM YYYY'
            ),
            amount: dollarAmountWithCommasAndTwoDecimal(paymentRecord.paymentAmount),
          } ||
            [])
      ),
    }
  })

export const getFileUploadOpen = (state: Store): boolean => state.fileUploadInfo.isModalOpen
