import React from 'react'
import styled from '@emotion/styled'

// styles
import styles from './flexRow.styles'

const Wrapper = styled.div(styles.wrapper)
const WrapperEven = styled.div(styles.wrapper, styles.wrapperEven)
const WrapperMainLeft = styled.div(styles.wrapper, styles.wrapperMainLeft)
const WrapperMainRight = styled.div(styles.wrapper, styles.wrapperMainRight)

type SectionWrapperProps = {
  variant?: 'even' | 'main-left' | 'main-right'
  children: React.ReactNode
}

const FlexRow = ({ variant, children }: SectionWrapperProps) => {
  const variantType = variant ?? 'even'
  let WrapperComponent = Wrapper
  switch (variantType) {
    case 'main-left':
      WrapperComponent = WrapperMainLeft
      break
    case 'main-right':
      WrapperComponent = WrapperMainRight
      break
    case 'even':
      WrapperComponent = WrapperEven
      break
    default:
      WrapperComponent = Wrapper
      break
  }
  return <WrapperComponent>{children}</WrapperComponent>
}

export default FlexRow
