import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Heading, Button, Modal } from '@mlcl-digital/mlcl-design'
import LabelValueComponent from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
// @ts-expect-error non-ts-code
import WithLoader from '../../../../../molecules/WithLoader'
// @ts-expect-error non-ts-code
import { actionCreators } from '../../../../../../actions'
import { getListOfActiveAuthorities } from '../../../../../../selectors/letterOfAuthority'
import { Store } from '../../../../../../types/store'
import {
  LOA_TYPE_INDIVIDUAL,
  LOA_TYPE_COMPANY,
  LOA_DURATION_INDEFINITE,
} from '../../../../../../constants/contactDetails'
import { FORM_BUTTON_LABELS, FORM_LABELS } from '../../../../../../constants/forms'

import { renderTextField } from '../../../../../../utils/sitecoreUtils'

import styles from './listOfActiveAuthorities.styles'
import { SitecoreField } from '../../../../../../types/sitecore'

import { createEvent } from '../../../../../../utils/telemetry'

const AuthoritySubheading = styled('p')(styles.subHeading)
const AuthorityCard = styled('div')(styles.card)
const AuthorityHeader = styled(Heading)(styles.heading)
const Container = styled(LabelValueComponent)(styles.container)

const FORM_ID = 'selectedAuthority'

type ListOfActiveAuthorities = {
  canRemoveAuthorities: boolean
  fields: {
    removeActiveAuthorityConfirmation: SitecoreField
  }
}

const ListOfActiveAuthorities = ({ canRemoveAuthorities, fields }: ListOfActiveAuthorities) => {
  const dispatch = useDispatch()

  const { formInit, formUpdate } = actionCreators
  const [removeModalOpen, toggleRemoveModal] = useState(false)
  const [selectedAuthority, setSelectedAuthority] = useState('')

  const authorities = useSelector(getListOfActiveAuthorities)
  const bancsCustomerNo = useSelector((state: Store) => state.okta.bancsCustomerNo)
  const isLoading = useSelector((state: Store) => state.letterOfAuthority.isLoading)
  useEffect(() => {
    const { fetchActiveAuthorities } = actionCreators
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(fetchActiveAuthorities(bancsCustomerNo))
    dispatch(formInit(FORM_ID, {}))
  }, [])

  const remove = async (sourceBancsCustomerNo: string) => {
    const { removeAuthority } = actionCreators
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await dispatch(removeAuthority(sourceBancsCustomerNo, bancsCustomerNo))
  }

  return (
    <>
      <WithLoader isLoading={isLoading}>
        {authorities.map(authority => (
          <AuthorityCard>
            <AuthorityHeader variant="h3" size="small" styleOverrides={{ marginBottom: 0 }}>
              {authority.name}
            </AuthorityHeader>
            <AuthoritySubheading>{authority.type}</AuthoritySubheading>
            <GridContainer>
              {authority.type === LOA_TYPE_INDIVIDUAL && (
                <>
                  <Container label={FORM_LABELS.dateOfBirth} value={authority.dateOfBirth} />
                  <Container label={FORM_LABELS.email} value={authority.email} />
                  <Container label={FORM_LABELS.mobilePhone} value={authority.phone} />
                </>
              )}
              {authority.type === LOA_TYPE_COMPANY && (
                <>
                  <Container label={FORM_LABELS.abn} value={authority.abn} />
                </>
              )}
              <Container
                label={
                  authority.type === LOA_TYPE_INDIVIDUAL
                    ? FORM_LABELS.residentialAddress
                    : FORM_LABELS.companyAddress
                }
                value={authority.address}
              />
              {authority.postalAddress && (
                <Container label={FORM_LABELS.postalAddress} value={authority.postalAddress} />
              )}

              <Container
                label="Duration of authority"
                value={`${authority.duration !== LOA_DURATION_INDEFINITE ? 'Expires ' : ''}${
                  authority.duration
                }`}
              />
            </GridContainer>
            <Button
              type="button"
              variant="dangerLink"
              disabled={!canRemoveAuthorities}
              onClick={() => {
                const event = createEvent({
                  GA: {
                    category: 'Remove authority',
                    action: ' select',
                  },
                  Splunk: {
                    attributes: {
                      'workflow.name': 'Remove authority',
                      authorityBancsCustomerNo: authority.bancsCustomerNo,
                    },
                  },
                })
                event.end()
                setSelectedAuthority(authority.bancsCustomerNo)
                dispatch(formInit(FORM_ID, {}))
                dispatch(
                  formUpdate(
                    FORM_ID,
                    Object.keys(authority.nameObject as { [key: string]: string }).reduce(
                      (acc, key) => ({
                        ...acc,
                        [key]: {
                          value: authority.nameObject[key],
                          error: false,
                        },
                      }),
                      {}
                    )
                  )
                )
                toggleRemoveModal(true)
              }}
            >
              {FORM_BUTTON_LABELS.removeAuthority}
            </Button>
          </AuthorityCard>
        ))}
        <Modal
          isOpen={removeModalOpen}
          onClose={() => toggleRemoveModal(false)}
          title="Remove authority"
          footer={
            <Button
              variant="danger"
              onClick={async () => {
                await remove(selectedAuthority)
                toggleRemoveModal(false)
              }}
            >
              {FORM_BUTTON_LABELS.remove}
            </Button>
          }
        >
          <p>{renderTextField(fields?.removeActiveAuthorityConfirmation)}</p>
        </Modal>
      </WithLoader>
    </>
  )
}

export default ListOfActiveAuthorities
