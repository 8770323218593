export const UNDERWRITING_CTA_TYPE = 'UW_REVIEW_CTA'

export const IP_WAIT_PERIOD_WEEKWISE = [
  { unit: 'Day', value: 14, week: 2 },
  { unit: 'Day', value: 30, week: 4 },
  { unit: 'Day', value: 90, week: 13 },
  { unit: 'Year', value: 1, week: 52 },
  { unit: 'Year', value: 2, week: 104 },
]

export const IP_COVER_STYLE_AGREED = 'Agreed Value'

export const IP_COVER_DIGITAL_KEY_AIP = 'AIP'
export const IP_COVER_DIGITAL_KEY_IP = 'IP'
export const BE_COVER_DIGITAL_KEY_BE = 'BE'

export const mapEmploymentStatus = {
  EMP: 'Employee',
  SLF_EMP: 'Self Employed',
  RD: 'Retired',
  ST: 'Student',
  UNEMP: 'Unemployed',
  HD: 'Home Duties',
  UNK: 'Unknown',
}

export const GENETIC_TEST_KEY = 'GENETIC_TEST'

export const DEFAULT_APP_OUTCOME = 'REFER_TO_ADMIN'

export const TERM_DECISON_MAP = {
  Accepted: 'accept',
  Reffered: 'refer',
}

export const CHILD_CI_DIGITAL_TYPE = 'child_ci'

export const TYPE_HIGH_LEVEL_REFERRED = 'Referred'
export const TYPE_HIGH_LEVEL_ACCEPTED = 'Accepted'
export const TYPE_HIGH_LEVEL_EVIDENCE_REQUIRED = 'Evidence required'
export const CALCULATED_URE_TERM = 'TERM'
export const CALCULATED_URE_REFERRED = 'REFERRED'
export const CALCULATED_URE_ACCEPTED = 'ACCEPTED'
export const CALCULATED_URE_EVIDENCE_REQUIRED = 'EVIDENCE_REQUIRED'
export const CALCULATED_URE_EVIDENCE_REQUIRED_WITH_TERMS = 'EVIDENCE_REQUIRED_WITH_TERMS'

// TODO: move this to Sitecore content
export const CALCULATED_URE_STATUS_LABELS = {
  [CALCULATED_URE_REFERRED]: 'Referred',
  [CALCULATED_URE_ACCEPTED]: 'Accepted',
  [CALCULATED_URE_EVIDENCE_REQUIRED]: 'Evidence required',
}

export const HIGH_LEVEL_DECISON_BUCKET = {
  STANDARD: TYPE_HIGH_LEVEL_ACCEPTED,
  NON_STANDARD: TYPE_HIGH_LEVEL_ACCEPTED,
  EVIDENCE_REQUIRED: TYPE_HIGH_LEVEL_REFERRED,
  POSTPONE: TYPE_HIGH_LEVEL_REFERRED,
  UNABLE_TO_OFFER: TYPE_HIGH_LEVEL_REFERRED,
  DECLINE: TYPE_HIGH_LEVEL_REFERRED,
  REFER: TYPE_HIGH_LEVEL_REFERRED,
}

export const FULL_URE_TOGGLE_CTA_OPTIONS = [
  { label: 'Proceed', value: 'true', isEnabled: true, order: '100', digitalValue: true },
  { label: 'Review decision', value: 'false', isEnabled: false, order: '101', digitalValue: false },
]

export const FULL_URE_TOGGLE_CTA_OPTIONS_OLD = [
  { label: 'Accept', value: 'true', isEnabled: true, order: '100', digitalValue: true },
  { label: 'Refer', value: 'false', isEnabled: false, order: '101', digitalValue: false },
]

export const DECISON_TYPE_NON_STANDARD = 'NON_STANDARD'
export const DECISON_TYPE_EVIDENCE_REQUIRED = 'EVIDENCE_REQUIRED'
export const DECISON_TYPE_STANDARD = 'STANDARD'
export const DECISON_TYPE_POSTPONE = 'POSTPONE'
export const DECISON_TYPE_UNABLE_TO_OFFER = 'UNABLE_TO_OFFER'
export const DECISON_TYPE_DECLINE = 'DECLINE'
export const DECISON_TYPE_REFER = 'REFER'

export const BENEFIT_TYPE_TISO = 'TISO'

export const INCORRECT_OTP_ERROR_CODE = 'E0000068'
export const OTP_ERROR_E0000068 = 'Incorrect OTP entered'

export const UNDERWRITING_REASON_SEPARATOR = '<br/>'

export const URE_VERSION_OCCUPATIONS_RATING = 'OccupationRatingAPIVersion'
export const URE_VERSION_OCCUPATIONS_SEARCH = 'SearchRatingAPIVersion'

export const BENEFIT_RETAIL_FLAG = 'retail'

export const URE_DEFAULT_INITIAL_ANSWERS = {
  ENQUIRY_TYPE: 'Retail',
  SOURCE: ['INITIAL'],
}
