import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

// styles

// components
import FontAwesomeIcon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import { Heading } from '@mlcl-digital/mlcl-design'
import styles from './financialAdviser.styles'

// types
import { NeedHelpFields } from '../../../../../../types/components/PremiumCalculator'

// utils
import { renderTextField } from '../../../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../../../utils/telemetry'

// selectors
import { getAdviserData } from '../../../../../../selectors/premiumCalculatorSubmit'

const FinancialAdviserContainer = styled('div')(styles.financialAdviserContainer)

type FinancialAdviserProps = {
  fields?: NeedHelpFields
}

const FinancialAdviser = ({ fields }: FinancialAdviserProps) => {
  const adviserData = useSelector(getAdviserData)
  const handleEmailClick = () => {
    const event = createEvent({
      GA: {
        category: 'CM-ROAdviserHelpEmailCTA',
        action: 'Financial Adviser Help Email CTA',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'CoverManager financial help email CTA',
        },
      },
    })
    event.end()
  }
  const handlePhoneClick = () => {
    const event = createEvent({
      GA: {
        category: 'CM-ROAdviserHelpPhoneCTA',
        action: 'Financial Adviser Help Phone CTA',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'CoverManager financial help phone CTA',
        },
      },
    })
    event.end()
  }
  const adviserEmail = adviserData.email ? (
    <a
      rel="noopener noreferrer"
      target="_blank"
      onClick={handleEmailClick}
      href={`mailto:${adviserData.email}`}
    >
      {adviserData.email}
    </a>
  ) : (
    '-'
  )
  const adviserPhone = adviserData.phone ? (
    <a onClick={handlePhoneClick} href={`tel:${adviserData.phone}`}>
      {adviserData.phone}
    </a>
  ) : (
    '-'
  )

  return (
    <FinancialAdviserContainer>
      <Heading variant="h2" size="large">
        {renderTextField(fields?.FinancialAdviserHeading)}
      </Heading>
      <dl>
        <dt>
          <FontAwesomeIcon iconName={['far', 'phone']} />
          <span>{renderTextField(fields?.PhoneNumber)}</span>
        </dt>
        <dd>{adviserPhone}</dd>
        <dt />
        <dd>{renderTextField(fields?.ContactTime)}</dd>
        <dt>
          <FontAwesomeIcon iconName={['far', 'envelope']} />
          <span>{renderTextField(fields?.Email)}</span>
        </dt>
        <dd>{adviserEmail}</dd>
      </dl>
    </FinancialAdviserContainer>
  )
}

export default FinancialAdviser
