// @flow
import moment from 'moment'
import get from 'lodash/get'
import { getSerializedFormState } from './formUtils'
// util
import { isIPCover, createBenefitIdentifier } from './quoteUtils'
import { isSGBOBenefit, checkIfFeatureEnabled } from './extendedQuoteUtils'
import {
  mapEmploymentStatus,
  DECISON_TYPE_STANDARD,
  DECISON_TYPE_REFER,
  BENEFIT_RETAIL_FLAG,
  URE_DEFAULT_INITIAL_ANSWERS,
} from '../constants/ure'
import {
  CHILD_CI,
  CI_EBO,
  EBO_FEATURE,
  ALTERNATIVE_INCOME_PROTECTION,
} from '../constants/application'
import {
  INCOME_PROTECTION_PLATINUM_ID,
  POLICY_BENEFIT_PLAT_SGBO,
  POLICY_BENEFIT_STD_SGBO,
} from '../constants/policies'

export const isEnquiyIdExpired = (createdOn: string, expireyDuration = 60) => {
  const currentDate = moment()
  return currentDate.diff(moment(createdOn), 'days') >= expireyDuration
}

const getMandatories = (memberMandatories, captureClientForm) => {
  if (memberMandatories.dateOfBirth) {
    const { dateOfBirth, isSmoker, gender, earning, employmentStatus } = memberMandatories

    return {
      dateOfBirth: moment(dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      isSmoker,
      gender,
      earning: earning.toString(),
      employmentStatus,
      occupationCode: get(memberMandatories, 'occupationalRating.Code'),
    }
  }
  const {
    fields: { dateOfBirth, isSmoker, gender, earning, employmentStatus, occupationRating },
  } = captureClientForm
  return {
    dateOfBirth: dateOfBirth.value,
    isSmoker: isSmoker.value,
    gender: gender.value,
    earning: earning.value.toString(),
    employmentStatus: get(employmentStatus, 'value.label'),
    occupationCode: get(
      memberMandatories,
      'occupationalRating.Code',
      get(occupationRating, 'value', '')
    ),
  }
}

export const mapEmploymentWithCode = (employment: string) =>
  mapEmploymentStatus[employment] || employment || 'Employee'

export const getPreAssessmentEnquiryPayload = (
  preAssessmentForm: Object,
  captureClientForm: Object,
  filledanswers,
  memberMandatories,
  details
) => {
  const hasChildData = typeof preAssessmentForm !== 'undefined'
  const allBenefits = hasChildData ? getSerializedFormState(preAssessmentForm.fields) : null
  const selectedBenefits = hasChildData
    ? Object.keys(allBenefits).filter(benefit => allBenefits[benefit])
    : []

  const { dateOfBirth, isSmoker, gender, earning, employmentStatus, occupationCode } =
    getMandatories(memberMandatories, captureClientForm)

  return {
    answers: {
      ...URE_DEFAULT_INITIAL_ANSWERS,
      ENQUIRY_TYPE: filledanswers ? filledanswers.ENQUIRY_TYPE : ['Pre-sale'],
      BIRTHDATE: filledanswers ? filledanswers.BIRTHDATE : [dateOfBirth],
      GENDER: filledanswers ? filledanswers.GENDER : [gender === 'Male' ? 'Male' : 'Female'],
      SMOKING: filledanswers
        ? filledanswers.SMOKING
        : [isSmoker === 'Yes' ? 'Smoker' : 'Non-smoker'],
      PRODUCT: selectedBenefits,
      AGENT_ID: filledanswers
        ? filledanswers.AGENT_ID
        : [get(details, 'agencyCode', 'SB054438').replace('SB', '')],
      AGENT_NAME: filledanswers
        ? filledanswers.AGENT_NAME
        : [get(details, 'agencyName', 'Jhone Doe')],
      EARNINGS: filledanswers ? filledanswers.EARNINGS : [earning || '120000'],
      EMPLOYMENTSTATUS: filledanswers
        ? filledanswers.EMPLOYMENTSTATUS
        : [mapEmploymentWithCode(employmentStatus)],
      OCCUPATION: filledanswers ? filledanswers.OCCUPATION : [`${occupationCode}`],
      ADI: filledanswers ? filledanswers.ADI : ['No'],
      HIV: filledanswers ? filledanswers.HIV : ['No'],
      TPD_DEF: filledanswers ? filledanswers.TPD_DEF : ['Any Occupation'],
      CI_EBO: filledanswers ? filledanswers.CI_EBO : ['Yes'],
      TOTAL_IP_4_COVER: filledanswers ? filledanswers.TOTAL_IP_4_COVER : ['0'],
      AIP_DEFERRED_PERIOD: filledanswers ? filledanswers.AIP_DEFERRED_PERIOD : ['4 weeks'],
      TOTAL_AIP_4_COVER: filledanswers ? filledanswers.TOTAL_AIP_4_COVER : ['0'],
      BE_DEFERRED_PERIOD: filledanswers ? filledanswers.BE_DEFERRED_PERIOD : ['4 weeks'],
      TOTAL_BE_4_COVER: filledanswers ? filledanswers.TOTAL_AIP_4_COVER : ['0'],
      EXIST_MLC_IP_KEEPING: filledanswers ? filledanswers.EXIST_MLC_IP_KEEPING : ['0'],
      EXIST_OTHER_IP_KEEPING: filledanswers ? filledanswers.EXIST_OTHER_IP_KEEPING : ['0'],

      EXIST_RELATIONSHIP: filledanswers ? filledanswers.EXIST_RELATIONSHIP : [],
      POSTCODE: filledanswers ? filledanswers.POSTCODE : [],
      PRESALE_INITIAL: filledanswers ? filledanswers.PRESALE_INITIAL : [],
      PURPOSE_OF_COVER: filledanswers ? filledanswers.PURPOSE_OF_COVER : [],
      SECOND_JOB: filledanswers ? filledanswers.SECOND_JOB : [],
      SECOND_JOB_WHICH: filledanswers ? filledanswers.SECOND_JOB_WHICH : [],
      UW_METHOD: filledanswers ? filledanswers.UW_METHOD : [],
    },
  }
}

export const getFullUrePayload = () => ({
  answers: URE_DEFAULT_INITIAL_ANSWERS,
})

const getUmeMasterData = umeBenefits => {
  if (umeBenefits) {
    const transformedUmeBenefit = [...umeBenefits]

    return (
      transformedUmeBenefit
        // @FIXME: need to determine how to handle AIP. not an issue
        // for now as you cannot select an AIP product
        // needs to be considered for alterations flow
        .filter(
          benefit =>
            benefit.types.includes(BENEFIT_RETAIL_FLAG) &&
            benefit.umeCode !== ALTERNATIVE_INCOME_PROTECTION
        )
        .map(benefit => {
          if (!Array.isArray(benefit.covers)) benefit.covers = [benefit.umeCode]
          else if (Array.isArray(benefit.covers) && !benefit.covers.length)
            benefit.covers = [...benefit.covers, benefit.umeCode]
          return benefit
        })
    )
  }
  return []
}

export const getActiveBenefitList = quote => {
  const coversList = []
  if (quote.policyStructure && quote.policyStructure.length) {
    quote.policyStructure.forEach(policy => {
      if (policy.covers && policy.covers.length) {
        policy.covers.forEach(cover => {
          coversList.push({
            type: cover.type,
            hasEBO: checkIfFeatureEnabled(cover, EBO_FEATURE),
            benefitInstanceNo: cover.benefitInstanceNo,
            productName: policy.productName,
          })
        })
      }
    })
  }
  return [...coversList]
}

export const getUREStatus = (enquiryId, digitalBenefits, isChildCI, fullUREresult, umeBenefits) => {
  const ureResult = [...fullUREresult.results].find(result => result.enquiryId === enquiryId)
  const ureResultsMappedtoCovers = digitalBenefits
    .filter(({ type }) => {
      if (isChildCI) {
        return type.toLowerCase() === CHILD_CI
      }
      return type.toLowerCase() !== CHILD_CI
    })
    .map(benefit => {
      const umeCodeMasterData = getUmeMasterData(umeBenefits)

      const umeCodeMasterDataItem = umeCodeMasterData.find(template =>
        template.covers.includes(benefit.type.toUpperCase())
      )

      if (!umeCodeMasterDataItem) {
        return false
      }
      const { umeCode } = umeCodeMasterDataItem
      let { status } = ureResult.umeBenefits.find(({ umeCode: code }) => {
        if (isChildCI) {
          return code === umeCode
        }
        return code.split('_')[0] === umeCode
      })
      if (benefit.hasEBO) {
        const eboResult = ureResult.umeBenefits.find(({ umeCode: code }) => code === CI_EBO)
        if (eboResult) {
          const {
            status: { underwritingDecision, underwritingDecisionExplanation },
          } = eboResult
          if (underwritingDecision !== DECISON_TYPE_STANDARD) {
            status = {
              underwritingDecision:
                status.underwritingDecision === DECISON_TYPE_REFER
                  ? status.underwritingDecision
                  : underwritingDecision,
              underwritingDecisionExplanation: status.underwritingDecisionExplanation
                ? `${status.underwritingDecisionExplanation}<br/>${underwritingDecisionExplanation}`
                : underwritingDecisionExplanation,
            }
          }
        }
      }
      return {
        ...status,
        benefitCode: benefit.type,
        benefitIdentifier: createBenefitIdentifier(
          benefit.productName,
          benefit.type,
          benefit.benefitInstanceNo
        ),
      }
    })
    .filter(Boolean)

  if (digitalBenefits.some(digitalBenefit => isSGBOBenefit(digitalBenefit.type))) {
    ureResultsMappedtoCovers
      .filter(status => isIPCover({ type: status.benefitCode }))
      .map(s =>
        ureResultsMappedtoCovers.push({
          ...s,
          benefitCode:
            s.benefitCode === INCOME_PROTECTION_PLATINUM_ID
              ? POLICY_BENEFIT_PLAT_SGBO
              : POLICY_BENEFIT_STD_SGBO,
        })
      )
  }
  return isChildCI ? [ureResultsMappedtoCovers[0]] : ureResultsMappedtoCovers
}

export const sortUreBenefitList = (ureBenefitList: Array, umeBenefits: Array): string => {
  if (!ureBenefitList) return ''
  const quoteBenefitNameList = umeBenefits.map(qb => qb.umeCode)
  return ureBenefitList
    .sort((a, b) => quoteBenefitNameList.indexOf(a) - quoteBenefitNameList.indexOf(b))
    .join(', ')
}
