import { createSelector } from 'reselect'
// @ts-expect-error file not in typescript
import { getCohortFromPolicyData } from '../utils/policyUtils'
import { getCustomerPolicies } from './common'
import { CustomerPolicy } from '../types/ClientPolicies'
import { Store } from '../types/store'
import { CohortPolicyData } from '../types/trusteeReplacementData'

// Types
const getConfig = (state: Store) => state.config

export const getCohortDataFromPolicies = createSelector(
  [getConfig, getCustomerPolicies],
  (config, policyStore: CustomerPolicy[]) =>
    <CohortPolicyData>getCohortFromPolicyData(policyStore, config)
)
