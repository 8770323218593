// @flow
import bsbLookup from './bsbLookup'
import customerBeneficiary from './customerBeneficiary'
import customerPersonalDetails from './customerPersonalDetails'
import fileUploadInfo from './fileUpload'
import application from './application'
import createQuoteReducer from './createQuote'
import sidebar from './sidebar'
import ure from './ure'
import abnLookup from './abnLookup'
import occupation from './occupation'
import preAssessment from './preAssessment'
import addressLookup from './addressLookup'
import captureCreditCard from './captureCreditCard'
import productRules from './productRules'
import enquiryIdExpiredModal from './enquiryIdExpiredModal'
import advisor from './advisor'
import myLink from './myLink'
import contactMLC from './contactMLC'
import correspondenceHistory from './correspondenceHistory'
import knowledgeBase from './knowledgeBase'
import nabPayment from './nabPayment'
import customerPolicyStatus from './customerPolicyStatus'
import customerPolicies from './customerPolicies'
import customerRelationships from './customerRelationships'
import customerPolicySummaryStatus from './customerPolicySummaryStatus'
import paymentDetailsActivePolicy from './paymentDetailsActivePolicy'
import paymentUpdateIsLoading from './paymentUpdateIsLoading'
import letterOfAuthority from './letterOfAuthority'
import trusteeReplacement from './trusteeReplacement'

export default {
  sidebar,
  bsbLookup,
  customerBeneficiary,
  customerPersonalDetails,
  fileUploadInfo,
  application,
  createQuote: createQuoteReducer,
  ure,
  occupation,
  preAssessment,
  addressLookup,
  captureCreditCard,
  productRules,
  advisor,
  enquiryIdExpiredModal,
  myLink,
  contactMLC,
  correspondenceHistory,
  knowledgeBase,
  abnLookup,
  nabPayment,
  customerPolicyStatus,
  customerPolicies,
  customerRelationships,
  customerPolicySummaryStatus,
  paymentDetailsActivePolicy,
  paymentUpdateIsLoading,
  letterOfAuthority,
  trusteeReplacement,
}
