import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
import styled from '@emotion/styled'
import Container from '@mlcl-digital/mlcl-design/lib/base/Container'
// @ts-expect-error file not in typescript
import FileUpload from '../../../molecules/FileUpload'

// Redux
import { getFileUploadOpen } from '../../../../selectors/clientPayment'
import { getFileUploadDetailsMeta } from '../../../../selectors/clientPolicies'

// utils
import { isPartyTypeOrg } from '../../../../utils/contactUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { getClassnameProp } from '../../../../utils/stylesUtils'
import { createEvent } from '../../../../utils/telemetry'

// types
import { PersonalDetailsProps } from '../../../../types/components/PersonalAndContactDetails'
import { Document as DocumentEntity } from '../../../../types/Document'

// components
import PartyAsPerson from './PartyAsPerson'
import PartyOfSMSF from './PartyOfSMSF'
import PartyAsCompany from './PartyAsCompany'

// styles
import styles from '../personalAndContactDetails.styles'

// action
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// constants
import { DOC_TYPE_DOC } from '../../../../constants/documentTypes'

const EditButton = styled(Button)(styles.editButton)
const Policy = styled('p')(styles.policy)

const renderPersonalDetails = (props: PersonalDetailsProps) => {
  const { data } = props
  const isPartyTypeBusiness = isPartyTypeOrg(data?.relatedParty?.partyType)
  const isSMSF = data.roleCode === 'IET'
  if (isSMSF) {
    return <PartyOfSMSF {...props} />
  }
  if (isPartyTypeBusiness) {
    return <PartyAsCompany {...props} />
  }
  return <PartyAsPerson {...props} />
}

const renderFileUpload = (props: PersonalDetailsProps, fileUploadMetadata: DocumentEntity) => {
  const { data, fields } = props
  const {
    FileUploadModalConfirm,
    FileUploadMaxFileCount,
    FileUploadMaxFileSize,
    FileUploadMaxFileSizeError,
    FileUploadWrongMimeType,
    FileUploadUploading,
    FileUploadSuccess,
    FileUploadFailure,
    FileUploadWorkItemFailure,
    FileUploadWorkItemSuccess,
    FileUploadFileRemove,
    FileUploadNoFileError,
    FileUploadWrongFileNameError,
  } = reduceAuthorableFields(fields)

  const analytics = {
    category: 'Client info tab',
    action: 'Confirm inside edit personal details modal',
  }

  const analyticsForDownload = {
    category: 'Customer selects edit personal details Download',
    action: 'AP - confirm personal detail updates',
  }

  const {
    modalHeading,
    modalSubHeading,
    documentName,
    documentPath,
    docSubTypeCode,
    workType,
  }: DocumentEntity = fileUploadMetadata

  return (
    <FileUpload
      formDownloadMeta={{
        documentName,
        documentPath,
        docSubTypeCode,
      }}
      modalMeta={{
        modalHeading,
        modalSubHeading,
        modalConfirmButton: FileUploadModalConfirm as string,
      }}
      dropzoneMeta={{
        maxFileNumber: FileUploadMaxFileCount as number,
        maxFileSize: FileUploadMaxFileSize as number,
      }}
      fileUploadMeta={{
        maxFileSizeError: FileUploadMaxFileSizeError as string,
        wrongFileType: FileUploadWrongMimeType as string,
        fileUploading: FileUploadUploading as string,
        fileUploadSuccess: FileUploadSuccess as string,
        fileUploadFailure: FileUploadFailure as string,
        createWorkItemError: FileUploadWorkItemFailure as string,
        createWorkItemSuccess: FileUploadWorkItemSuccess as string,
        fileRemoveButtonLabel: FileUploadFileRemove as string,
        noFileUploadedError: FileUploadNoFileError as string,
        wrongFileNameError: FileUploadWrongFileNameError as string,
      }}
      createWorkItemRequest={{
        workTypeCode: workType,
        bancsCustomerNo: data?.bancsCustomerNo,
      }}
      uploadDocumentRequest={{
        docSubTypeCode,
        docType: DOC_TYPE_DOC,
        workType,
      }}
      analytics={analytics}
      analyticsForDownload={analyticsForDownload}
    />
  )
}

const PersonalDetails = (props: PersonalDetailsProps) => {
  const { fields, policyNumberList, isInternalSuperOwner } = props
  const fileUploadOpen = useSelector(getFileUploadOpen)
  const fileUploadMetadata = useSelector(state =>
    getFileUploadDetailsMeta(state, {
      fields,
      type: 'personal',
      productId: '',
    })
  )
  const dispatch = useDispatch()

  const handleFileUploadModal = () => {
    const event = createEvent({
      GA: {
        category: 'Editing clients personal details - client info tab',
        action: 'Edit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Editing clients personal details - client info tab',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.toggleFileUploadModal(true))
  }
  const policies = policyNumberList?.join(', ')
  return (
    <Container {...getClassnameProp(props)}>
      {/* For NULIS, policy numbers will be displayed in above section with Trustee details.
        This component will be used to displayed Life Insured details in case of NULIS.
      */}
      {!isInternalSuperOwner && policies && (
        <Policy>
          {renderTextField(fields.Policy)} {policies}
        </Policy>
      )}
      {renderPersonalDetails(props)}
      <EditButton variant="link" onClick={() => handleFileUploadModal()}>
        {renderTextField(fields.Edit)}
      </EditButton>
      {fileUploadOpen && renderFileUpload(props, fileUploadMetadata)}
    </Container>
  )
}

export default PersonalDetails
