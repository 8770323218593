import { ModalMessage } from '../types/masterData'

export const getAlterationModalMsgObj = (
  alterationsModalMessages: ModalMessage[] = [],
  code: string
) => alterationsModalMessages && alterationsModalMessages.find(item => item.code === code)

export const getAlterationModalMsg = (
  alterationsModalMessages: ModalMessage[] = [],
  code: string
) => (getAlterationModalMsgObj(alterationsModalMessages, code) || {}).message
