import { createSelector } from 'reselect'
// selectors
import { getAlterationPolicies, getPolicyStructure } from './common.selectors'
import { getCustomerRelationshipsObject } from './common'
// utils
// @ts-expect-error file not in typescript
import { dollarAmountWithCommasAndTwoDecimal } from '../utils/formUtils'
import { getEmail, getWorkPhone } from '../utils/contactUtils'
// @ts-expect-error file not in typescript
import { fetchAgents } from '../utils/relationshipUtils'
// constants
import { REPORTING_PAYMENT_FREQUENCY_OPTIONS } from '../constants/forms'
import { POLICY_RELATIONSHIPS_AGENT } from '../constants/policies'
import { SPECIAL_AGENT_TYPE_COADVISED } from '../constants/adviser'
// types
import { ClientPolicy, Relationship } from '../types/ClientPolicies'
import { getRulesForAlterationPolicies } from './altsDeclaration.selectors'

export const getPremiumCalculatorSubmitData = createSelector(
  [getPolicyStructure],
  policyStructure => ({
    policies: policyStructure.map(policy => {
      const premiumFrequency =
        policy.alteration?.newPremiumPayingFrequency ?? policy.paymentFrequency
      const premiumFrequencyLabel = (
        REPORTING_PAYMENT_FREQUENCY_OPTIONS.find(
          frequency => frequency.value === premiumFrequency
        ) || {}
      ).label?.toLowerCase()
      return {
        policyNo: policy.policyNo,
        premium: dollarAmountWithCommasAndTwoDecimal(
          policy?.alteration?.newNetPremium || policy.totalPremiumAmount
        ),
        premiumFrequency: premiumFrequencyLabel,
      }
    }),
  })
)

export const getAdviserData = createSelector([getAlterationPolicies], alterationPolicies => {
  // Find the relationships in any of the policy
  const relationships = alterationPolicies[0]?.policy?.relationships
  // Derive the below from the SA relationship in policy
  const adviserContactInfo =
    relationships &&
    relationships.find(
      (relationship: Relationship) => relationship.roleCode === POLICY_RELATIONSHIPS_AGENT
    )?.relatedParty?.contactMethods
  const adviserContactNumber = getWorkPhone(adviserContactInfo?.phones || []) as {
    number?: string
    idc?: string
  }
  return {
    email: getEmail(adviserContactInfo?.emails) ?? '',
    phone: adviserContactNumber.number
      ? `+${adviserContactNumber.idc as string} ${adviserContactNumber.number}`
      : '',
  }
})

export const showFinancialAdviserSection = createSelector(
  [getRulesForAlterationPolicies, getAlterationPolicies, getCustomerRelationshipsObject],
  (rules, alterationPolicies, customerRelationships) =>
    rules?.businessData?.assesment?.customerInfo_MultipleAdvisers === 'N' &&
    alterationPolicies.length > 0 &&
    alterationPolicies.every((alterationPolicy: ClientPolicy) =>
      (fetchAgents(alterationPolicy?.policy?.relationships) || []).some(
        (agent: Relationship) =>
          customerRelationships[agent.bancsCustomerNo]?.specialAgentType ===
          SPECIAL_AGENT_TYPE_COADVISED
      )
    )
)
