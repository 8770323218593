import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import { Modal, Button } from '@mlcl-digital/mlcl-design'

// types
import { AltsPremiumCalculatorFields } from '../../../../../types/components/PremiumCalculator'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'

// utils
import { renderTextField } from '../../../../../utils/sitecoreUtils'

// selectors
import { getAlterations } from '../../../../../selectors/common.selectors'

const DownloadMaxLimitModal = ({ fields }: { fields: AltsPremiumCalculatorFields }) => {
  const dispatch = useDispatch()
  const { isMaxDownloadLimitModal } = useSelector(getAlterations)

  // modal close(X) button
  const handleClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.setIsMaxDownloadLimitModal(false))
  }

  return (
    <Modal
      isOpen={isMaxDownloadLimitModal}
      onClose={handleClose}
      title={fields.MaxLimitModalHeading}
      footer={
        <Button variant="linkUnderlineOnHover" onClick={handleClose}>
          {renderTextField(fields.Close)}
        </Button>
      }
    >
      {renderTextField(fields.DownloadLimitModalDescription)}
    </Modal>
  )
}

export default DownloadMaxLimitModal
