import { Variables } from '@mlcl-digital/mlcl-design'
import type { CSSObject } from '@emotion/styled'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { space, colours, fontSize, fontWeights, mediaQueries } = Variables as VariablesType

const styles: Record<string, CSSObject> = {
  policyList: {
    listStyle: 'none',
    border: `1px solid ${colours.tertiaryFour}`,
    background: colours.tertiarySix,
    padding: space(3),
    margin: `${space(2.5, true)} 0 ${space(2, true)}`,
    li: {
      flexDirection: 'column',
      marginBottom: space(2.5),
      display: 'flex',
      '> p': {
        fontSize: fontSize.sm,
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
      [mediaQueries.sm]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        '> p': {
          flexBasis: '33%',
          paddingRight: space(2),
        },
      },
    },
  },
  policyPeople: {
    display: 'flex',
    flexDirection: 'column',
    'span:first-of-type': {
      display: 'block',
      fontWeight: fontWeights.semiBold,
    },
    [mediaQueries.sm]: {
      flexBasis: '66%',
    },
    [mediaQueries.md]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      p: {
        flex: '1 1 0px',
        paddingRight: space(2),
      },
    },
  },
}

export default styles
