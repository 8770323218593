import type { CSSObject } from '@emotion/styled'
// @ts-expect-error not a TS Component (yet)
import { buttonPadding, mediaQueries } from '../../../styles'

const styles: Record<string, CSSObject> = {
  button: {
    marginTop: buttonPadding.xs.vertical,
    marginBottom: buttonPadding.xs.vertical,
    [mediaQueries.sm]: {
      marginTop: buttonPadding.sm.vertical,
      marginBottom: buttonPadding.sm.vertical,
    },
    [mediaQueries.lg]: {
      marginTop: buttonPadding.lg.vertical,
      marginBottom: buttonPadding.lg.vertical,
    },
  },
}

export default styles
