import {
  useState,
  useEffect,
  useRef,
  RefCallback,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react'

type MinimumNode = Pick<Node, 'contains'>
const listenerOptions: AddEventListenerOptions = {
  capture: true, // Run this listener before the one in the EventTarget
  passive: true, // Don't delay other event listeners due to this listener
}

/**
 * Returned values from the `useComponentVisible` hook
 */
type UseComponentVisibleValues<T> = {
  /**
   * React RefCallback used to set the DOM node
   * which this hook will react with.
   *
   * ```
   * const { ref } = useComponentVisible()
   * return <div ref={ref} />
   * ```
   */
  ref: RefCallback<T>

  /**
   * React RefObject used to set the DOM node
   * which this hook will react with.
   *
   * Like `ref` but restricted to the type parameter
   * of the hook itself.
   *
   * ```
   * const { componentRef } = useComponentVisible<HTMLDivElement>()
   * return <div ref={componentRef} />
   * ```
   */
  componentRef: MutableRefObject<T | null | undefined>

  /**
   * Boolean stateful value which the hook updates from keyboard
   * and mouse events.
   */
  isComponentVisible: boolean

  /**
   * Stateful function to update `isComponentVisible` from outside
   * the hook, for example, to set the value to `true` based on
   * a user event
   */
  setIsComponentVisible: Dispatch<SetStateAction<boolean>>
}

/**
 * A complex react hook which can modify the return boolean variable `isComponentVisible`
 * from mouse and keyboard events in the DOM.
 *
 * The visibility can be toggled programatically using the returned `setIsComponentVisible`
 * function.
 *
 * The returned variable `isComponentVisible` is set to the `initialVisibility` value and
 * a `ref` object is also returned which can be attached to the DOM to ensure visibility
 * is not changed while focus is still within the referenced DOM node.
 *
 * This hook adds a keyboard listener to hide the node if the `ESCAPE` key is pressed;
 * and a mouse listener which will hide the node if the event target is not contained within
 * the referenced node.
 *
 * @example
 * Functional component which uses a button to show the `<div />` which then listens
 * to keyboard/mouse events to hide itself.
 * ```
 * function MyComponent({ children }) => {
 *   const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible();
 *   return (
 *     { isComponentVisible && (
 *       <div ref={ref}>
 *         { children }
 *       </div>
 *     )}
 *     <button onClick={() => setIsComponentVisible(true)}>
 *       Open
 *     </button>
 *   );
 * }
 * ```
 * @param initialVisibility the intial value of the `isComponentVisible`
 *                          stateful variable (default `false`)
 * @returns a `ref` object to supply to a DOM node and the stateful variables to control visibility
 */
export default function useComponentVisible<T extends MinimumNode>(
  initialVisibility?: boolean
): UseComponentVisibleValues<T> {
  const [isComponentVisible, setIsComponentVisible] = useState(initialVisibility ?? false)
  const componentRef = useRef<T | null>()
  const ref: RefCallback<T> = (el: T | null) => {
    componentRef.current = el // Using callback allows polymorphism
  }

  const handleKeyboardEvents = (event: KeyboardEvent) => {
    //  In Firefox 36 and earlier, the Esc key returns "Esc" instead of "Escape".
    if (event.key === 'Escape' || event.key === 'Esc') {
      setIsComponentVisible(false)
    }
  }

  const handleDOMClicks = (event: MouseEvent) => {
    if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    if (!isComponentVisible) {
      return undefined
    }
    // Add event listeners if the component is visible
    document.addEventListener('keydown', handleKeyboardEvents, listenerOptions)
    document.addEventListener('click', handleDOMClicks, listenerOptions)
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvents, listenerOptions)
      document.removeEventListener('click', handleDOMClicks, listenerOptions)
    }
  }, [isComponentVisible])

  return { ref, componentRef, isComponentVisible, setIsComponentVisible }
}
