// v2 is initially created for Inflation proofing uplift in CP
// v2 might also be used for the uplift in AP in future, code changes will be required
import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Heading, Button, Tooltip, Chip, Notification } from '@mlcl-digital/mlcl-design'
import Table from '@mlcl-digital/mlcl-design/lib/base/Table'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import ProgressBar from '@mlcl-digital/mlcl-design/lib/base/ProgressBar'

// components
// @ts-expect-error file not in typescript
import CustomerPortalLifeCycle from '../../molecules/CustomerPortalLifeCycle'
// @ts-expect-error file not in typescript
import CustomerPolicyAnniversary from '../CustomerPolicyAnniversary'

// types
import { Fields, Props } from '../../../types/components/AltsLandingPage'

// selectors
import {
  getAlterationType,
  getIsAllAltsRules,
  // @ts-expect-error File not in typescript
} from '../../../selectors/alterations'

// utils
import { createEvent } from '../../../utils/telemetry'
import { renderTextField } from '../../../utils/sitecoreUtils'
// @ts-expect-error File not in typescript
import history from '../../../utils/browserHistory'

// styles
import styles from './styles'

// actions
import {
  setAlterationTypeForAlterationsRoute,
  deselectAllPolicies,
  // @ts-expect-error File not in typescript
} from '../../../actions/creators/alterations'
// @ts-expect-error File not in typescript
import { createAltsQuote } from '../../../actions/creators/createQuote'
import { getAlterationPoliciesData } from '../../../selectors/alterationPolicies'

const PageContainer = styled('div')(styles.pageContainer)
const Description = styled('p')(styles.description)
const InflationProofingDetails = styled('div')(styles.inflationProofingDetails)
const TooltipContainer = styled('div')(styles.tooltipContainer)
const StyledNotification = styled(Notification)(styles.notification)
const PoliciesContainer = styled('div')(styles.policiesContainer)

const columns = (
  {
    PolicyNumber,
    Anniversary,
    LifeInsured,
    DaysRemaining,
    ReasonOptOut,
    DaysRemainingTooltip,
  }: Fields,
  isEligible: boolean
) => [
  {
    Header: PolicyNumber?.value,
    accessor: 'policyNumber',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'productType',
    disableSortBy: true,
    Cell: ({ value }: { value: string }) => (
      <Chip variant={value === 'Inside Super' ? 'default' : 'important'}>{value}</Chip>
    ),
  },
  {
    Header: Anniversary?.value,
    accessor: 'anniversary',
    disableSortBy: true,
  },
  {
    Header: LifeInsured?.value,
    accessor: 'lifeInsured',
    disableSortBy: true,
  },
  ...(isEligible
    ? [
        {
          Header: (
            <div>
              <span>{DaysRemaining?.value}</span>
              <TooltipContainer>
                <span data-tip data-for="days-remaining">
                  <Icon iconName={['far', 'circle-info']} />
                </span>
                <Tooltip id="days-remaining">{renderTextField(DaysRemainingTooltip, true)}</Tooltip>
              </TooltipContainer>
            </div>
          ),
          accessor: 'daysRemaining',
          disableSortBy: true,
          Cell: ({
            value: daysRemaining,
          }: {
            value: { variant: 'danger' | 'default' | 'warning'; percentage: number; label: number }
          }) => <ProgressBar {...daysRemaining} />,
        },
      ]
    : []),
  ...(!isEligible
    ? [
        {
          Header: ReasonOptOut?.value,
          accessor: 'reasonOptOut',
          disableSortBy: true,
        },
      ]
    : []),
]

const AltsLandingPage = ({ fields, params }: Props) => {
  const dispatch = useDispatch()
  const alterationTypeForAnalytics: string = useSelector(getAlterationType())
  const alterationType = params.AlterationType
  const eligiblePolicies = useSelector(getAlterationPoliciesData(alterationType, true, fields))
  const inEligiblePolicies = useSelector(getAlterationPoliciesData(alterationType, false, fields))
  const isAllAltsRules = useSelector(getIsAllAltsRules)

  const COLUMNS_ELIGIBLE_POLICIES = columns(fields, true)
  const COLUMNS_INELIGIBLE_POLICIES = columns(fields, false)

  useEffect(() => {
    dispatch(deselectAllPolicies())

    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: `${alterationTypeForAnalytics} landing page`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationTypeForAnalytics} - landing page`,
        },
      },
    })
    tagEvent.end()
  }, [])

  const handleClick = useCallback(() => {
    dispatch(
      createAltsQuote({
        alterationType,
      })
    )

    // setting the flag for next page in the flow
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(setAlterationTypeForAlterationsRoute(alterationType))

    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: `Enter ${alterationTypeForAnalytics} alteration`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Enter ${alterationTypeForAnalytics} alteration`,
        },
      },
    })
    tagEvent.end()

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.push(fields.StartApplicationButton?.value?.href)
  }, [alterationTypeForAnalytics])

  return (
    <PageContainer>
      {/* CustomerPortalLifeCycle and CustomerPolicyAnniversary cannot be used in AP flow. */}
      <CustomerPortalLifeCycle>
        <CustomerPolicyAnniversary />
        <InflationProofingDetails>
          <Heading variant="h2" size="LG">
            {renderTextField(fields.Heading)}
          </Heading>
          <Description>{renderTextField(fields.Description, true)}</Description>
          <Button
            variant="primary"
            disabled={eligiblePolicies.length === 0}
            onClick={handleClick}
            isLoading={!isAllAltsRules}
          >
            {fields.StartApplicationButton?.value?.text}
          </Button>
          {isAllAltsRules && eligiblePolicies.length === 0 && (
            <StyledNotification variant="info">
              <Icon iconName={['far', 'circle-info']} />
              <span>{renderTextField(fields.OptOutUnavailable)}</span>
            </StyledNotification>
          )}
        </InflationProofingDetails>
        {eligiblePolicies.length > 0 && (
          <PoliciesContainer>
            <Heading variant="h3" size="LG">
              {renderTextField(fields.PoliciesEligibleHeading)}
            </Heading>
            <p>{renderTextField(fields.ScrollDescription)}</p>
            <Table
              pageSize={20}
              data={eligiblePolicies}
              columns={COLUMNS_ELIGIBLE_POLICIES}
              styleOverrides={styles.styleOverrides}
            />
          </PoliciesContainer>
        )}
        {inEligiblePolicies.length > 0 && (
          <PoliciesContainer>
            <Heading variant="h3" size="LG">
              {renderTextField(fields.PoliciesIneligibleHeading)}
            </Heading>
            <p>{renderTextField(fields.ScrollDescription)}</p>
            <Table
              pageSize={20}
              data={inEligiblePolicies}
              styleOverrides={styles.styleOverrides}
              columns={COLUMNS_INELIGIBLE_POLICIES}
            />
          </PoliciesContainer>
        )}
      </CustomerPortalLifeCycle>
    </PageContainer>
  )
}

export default AltsLandingPage
