import React from 'react'
import { ThemeContext, ThemeConsumer, ThemeProvider } from '@mlcl-digital/mlcl-design'
// @ts-expect-error legacy provider
import ThemeProviderLifeStyle from '@mlcl-digital/mlcl-design/lib/base/ThemeProvider'

type HasThemeProperty<T extends object> = { theme: T }
function getThemeProp<T extends object>({
  theme,
}: Partial<HasThemeProperty<T>>): HasThemeProperty<T> | null {
  return theme && typeof theme === 'object' && Object.keys(theme).length ? { theme } : null
}

export default ThemeContext

function FullThemeProvider({ theme, children }: { theme: string; children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      {/* This provider shouldn't be needed, what is it even doing? */}
      <ThemeProviderLifeStyle theme={theme}>{children}</ThemeProviderLifeStyle>
    </ThemeProvider>
  )
}

export { ThemeConsumer as themeConsumer, FullThemeProvider as ThemeProvider, getThemeProp }
