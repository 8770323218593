// @flow
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import styled from '@emotion/styled'
import { object, shape, string } from 'prop-types'
import get from 'lodash/get'
import { PageWrap } from '@mlcl-digital/mlcl-design'
import LinkComponent from '@mlcl-digital/mlcl-design/lib/base/Link'
import { createEvent } from '../../../utils/telemetry'

// components.
import Header from '../../molecules/PageHeader'
import CustomerPolicyAnniversary from '../CustomerPolicyAnniversary'
import WithLoader from '../../molecules/WithLoader'

// actions
import { actionCreators } from '../../../actions'

// constants
import { ALTERATION_TYPES } from '../../../constants/alterations'

// utils
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'

// selectors
import { getIsSubSectionCpHeader, getIsAllAltsRules } from '../../../selectors/alterations'
import { checkIfAllRelationshipsHaveRelatedParty } from '../../../selectors/common'

// styles.
import styles from './cpHeader.styles'

// styled components.
const DocumentsSectionWrap = styled('div')(styles.documentsSectionWrap)

const CPHeader = ({ fields, params }) => {
  const { content, heading, background, CTA, ctaID } = fields
  const { Subheading } = reduceAuthorableFields(fields)
  const { BgColor } = params
  const dispatch = useDispatch()
  const isSubSectionCpHeader = useSelector(getIsSubSectionCpHeader(Subheading, ctaID))
  const isAllAltsRules = useSelector(getIsAllAltsRules)
  const allRelationshipsHaveRelatedParty = useSelector(checkIfAllRelationshipsHaveRelatedParty)

  const linkTarget = get(CTA, 'value.target')

  const linkClick = useCallback(() => {
    if (get(ctaID, 'value') === 'InflationProofing') {
      dispatch(actionCreators.setAlterationTypeSelectedByUser(ALTERATION_TYPES.REJECT_CPI))
      const tagEvent = createEvent({
        GA: {
          category: 'Customer Policy Anniversary',
          action: 'CP reject CPI - reject CPI',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Customer Policy Anniversary - Reject CPI',
          },
        },
      })
      tagEvent.end()
    }
  }, [])

  return (
    <>
      {get(ctaID, 'value') === 'InflationProofing' && <CustomerPolicyAnniversary />}
      <Header
        theme={BgColor?.toLowerCase()}
        heading={heading}
        subHeading={content}
        bigImage
        imageSrc={get(background, 'value.src', '')}
        imageAlt={get(background, 'value.alt', '')}
      />
      {isSubSectionCpHeader && allRelationshipsHaveRelatedParty && (
        <PageWrap>
          <WithLoader isLoading={get(ctaID, 'value') === 'InflationProofing' && !isAllAltsRules}>
            <DocumentsSectionWrap data-testid="sub-section">
              <p>
                <Text field={Subheading} />
              </p>
              <LinkComponent
                size="small"
                variant="primary"
                to={get(CTA, 'value.href', '')}
                {...(linkTarget && { target: linkTarget })}
                onClick={linkClick}
                testid="customer-policy-aniv-link"
              >
                {renderTextField(get(CTA, 'value.text', ''))}
              </LinkComponent>
            </DocumentsSectionWrap>
          </WithLoader>
        </PageWrap>
      )}
    </>
  )
}

CPHeader.propTypes = {
  fields: shape({
    Subheading: object,
    content: object,
    heading: object,
    background: object,
    CTA: object,
  }).isRequired,
  params: shape({
    BgColor: string,
  }).isRequired,
}

export default CPHeader
