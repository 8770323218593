// @flow
import * as bsbLookupActions from './creators/bsbLookup'
import * as customerBeneficiary from './creators/customerBeneficiary'
import * as customerPersonalDetails from './creators/customerPersonalDetails'
import * as fileUpload from './creators/fileUpload'
import * as occupation from './creators/occupation'
import * as application from './creators/application'
import * as createQuote from './creators/createQuote'
import * as advisor from './creators/advisor'
import * as productRules from './creators/productRules'
import * as ure from './creators/ure'
import * as myLink from './creators/myLink'
import * as captureCreditCardActions from './creators/captureCreditCard'
import * as sidebar from './creators/sidebar'
import * as contactMLC from './creators/contactMLC'
import * as correspondeHistory from './creators/correnspondenceHistory'
import * as knowledgeBaseAction from './creators/knowledgeBase'
import * as preAssessmentActions from './creators/preAssessment'
import * as enquiryIdExpiredModalActions from './creators/enquiryIdExpiredModal'
import * as abnLookup from './creators/abnLookup'
import * as paymentActions from './creators/nabPayment'
import { setPolicyForPaymentDetailModal } from './creators/paymentDetailsActivePolicy'
import * as customerPolicyActions from './creators/customerPolicies'
import * as loaActions from './creators/letterOfAuthority'
import * as trusteeReplacement from './creators/trusteeReplacement'

export default {
  ...bsbLookupActions,
  ...customerBeneficiary,
  ...customerPersonalDetails,
  ...fileUpload,
  ...occupation,
  ...application,
  ...createQuote,
  ...advisor,
  ...productRules,
  ...ure,
  ...myLink,
  ...captureCreditCardActions,
  ...sidebar,
  ...contactMLC,
  ...correspondeHistory,
  ...knowledgeBaseAction,
  ...preAssessmentActions,
  ...enquiryIdExpiredModalActions,
  ...abnLookup,
  ...paymentActions,
  ...customerPolicyActions,
  ...loaActions,
  ...trusteeReplacement,
  setPolicyForPaymentDetailModal,
}
