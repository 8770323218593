// destructuring doesn't work with dotenv https://github.com/mrsteele/dotenv-webpack/issues/142
/* eslint-disable prefer-destructuring */
// const OCCUPATIONS_RATING_TAG = process.env.OCCUPATIONS_RATING_TAG
// const OCCUPATIONS_SEARCH_TAG = process.env.OCCUPATIONS_SEARCH_TAG
// const OCCUPATIONS_OCCUPATIONS_TAG = process.env.OCCUPATIONS_OCCUPATIONS_TAG
/* eslint-enable prefer-destructuring */

// TODO: update the headers with correct data.
export const headerOccupation = {
  creationTime: '2017-07-09T12:00:23.000Z',
  transactionId: '1234567890',
  timestamp: '2017-07-09T12:00:23.000Z',
  trackingId: '8934923479238490',
}

export const SC_BRANCH_TAG = 'UREBranchTag'
export const DEFAULT_TAG = 'MLC'
export const AMPERSAND_CHAR_REPLACEMENT = 'UIXOPOICJVG'
