// @flow
import { createSelector } from 'reselect'

import { getMasterData } from './common.selectors'
import { getAlterationModalMsg, getAlterationModalMsgObj } from '../utils/masterDataUtils'

export const getAlterationsModalMessages = createSelector(
  [getMasterData],
  masterData => masterData.alterationsModalMessages
)

export const getInvalidAltsQuoteModalContent = createSelector(
  [getMasterData],
  masterData =>
    getAlterationModalMsgObj(masterData.invalidAltsQuoteModalMessages, 'decreaseCover_Reentry') ||
    {}
)

export const getAlterationModalMessagesBasedOnCode = createSelector(
  [getAlterationsModalMessages, (state, code: string) => code],
  (alterationsModalMessages, code) => getAlterationModalMsg(alterationsModalMessages, code)
)
