export const PRODUCT_ID_FOR_TRP = [
  'LC_IP_PF_EXCELL_S_MLC',
  'LC_LCS_S_MLC',
  'LC_MLCI_S_MLC',
  'LC_PF_LIFE_S_V2_MLC',
  'LC_PF_LIFE_S_V3_MLC',
  'LC_PF_SRM_S_V3_MLC',
]
export const POLICY_STATUS_FOR_TRP = ['IP Inforce', 'Inforce', 'Revivable Lapse']
export const COHORT_TYPE_ME = 'Intrafund'
export const COHORT_TYPE_ER = 'Erollover'
export const COHORT_TYPE_OTHER = 'OtherPaymentMethod'
export const PAYMENT_METHOD_ME = 'ME'
export const PAYMENT_METHOD_ER = 'ER'
