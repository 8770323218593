import { createSelector } from 'reselect'
import get from 'lodash/get'
import {
  getInforceCustomerPolicies,
  getMasterList,
  getCustomerRelationships,
  getOutOfForceCustomerPolicies,
  getCustomerPolicies,
} from './common'
import {
  checkIfValidBenefitStatusCode,
  addOptimiserSuffixToBenefitName,
  sortLifeAssuredByPolicyOwner,
  getFullNameOfLifeAssuredWithInforceBenefitState,
  selectAllLifeAssuredForPolicy,
  getPolicyTypeLabel,
  // @ts-expect-error file not in typescript
} from '../utils/policyUtils'
// @ts-expect-error file not in typescript
import { dollarAmountWithCommasAndTwoDecimal } from '../utils/formUtils'
import { getPaymentCollectionMethod } from '../utils/paymentMethods'
import { getCollectionFrequency } from '../utils/paymentUtils'
// @ts-expect-error file not in typescript
import { getBancsCustomerNumber } from '../utils/cookieUtils'
// @ts-expect-error file not in typescript
import { orderIPBenefits } from '../utils/extendedQuoteUtils'
import { POLICY_STATUS_NA, INCOME_PROTECTION_RELATED_POLICY_BENEFITS } from '../constants/policies'
import {
  EXP_CUSTOMER_STATUS_UNLOADED,
  EXP_CUSTOMER_STATUS_LOADING,
  EXP_CUSTOMER_STATUS_FAILED,
} from '../constants/apis'
import { Store } from '../types/store'
import { benefit as benefitType, CustomerPolicy } from '../types/ClientPolicies'
import { CodeValueObject, PolicyStatus } from '../types/masterData'
import { CustomerRelationship } from '../types/customerRelationship'

export const getPolicyStatuses = (state: Store) =>
  Object.values(state.customerPolicyStatus).map(status => ({
    policyNo: status.policyNo,
    loaded: status.statuses.self.status,
  }))

const buildPolicyCardProp = (
  policy: CustomerPolicy,
  {
    benefitStatus,
    policyStatus,
    benefitList,
  }: {
    benefitStatus: CodeValueObject[]
    policyStatus: PolicyStatus[]
    benefitList: CodeValueObject[]
  },
  relationships: CustomerRelationship[],
  status: { loaded: string } | undefined,
  includeRevivableLapseBenefits = false
) => {
  const {
    premiumPaymentMethod,
    paymentInstrument,
    productClass,
    policyPremium,
    benefits = [],
    policyPremiumFrequency,
    policyId,
    policyNo,
    policyName,
  } = policy

  const premiumPaymentMethodValue = premiumPaymentMethod

  const finalBenefits = orderIPBenefits(false, benefits, benefitList) as benefitType[]

  return {
    bancsPolicyNo: policyNo,
    collectionFrequency: `${getCollectionFrequency(policyPremiumFrequency)} Premium`,
    subHeadingText: policyId,
    mainHeadingText: policyName,
    policyBenefitAmounts: finalBenefits
      .filter(benefit =>
        checkIfValidBenefitStatusCode(benefit.benefitStatusCode, [
          ...benefitStatus,
          ...(includeRevivableLapseBenefits ? [{ code: 'RLSP' }] : []),
        ])
      )
      .map((benefit, i) => ({
        benefitAmount: `${dollarAmountWithCommasAndTwoDecimal(
          benefit.sumAssured || benefit.coverAmount
        )} ${INCOME_PROTECTION_RELATED_POLICY_BENEFITS.includes(benefit.type) ? 'p/m' : ''}`.trim(),
        benefitName: addOptimiserSuffixToBenefitName(benefit, false) as string,
        index: i,
        ...(benefit.benefitInstanceNo && { instanceNo: benefit.benefitInstanceNo }),
      })),
    paymentMethod: getPaymentCollectionMethod(premiumPaymentMethodValue, paymentInstrument) || '-',
    policyPremiumValue: dollarAmountWithCommasAndTwoDecimal(policyPremium),
    policyType: getPolicyTypeLabel(productClass),
    policyPersonnel: (
      selectAllLifeAssuredForPolicy(
        relationships,
        policyNo,
        finalBenefits
      ) as CustomerRelationship[]
    )
      .sort((lifeAssured: CustomerRelationship) =>
        sortLifeAssuredByPolicyOwner(lifeAssured, getBancsCustomerNumber())
      )
      .map((lifeAssured: CustomerRelationship) =>
        getFullNameOfLifeAssuredWithInforceBenefitState(lifeAssured, finalBenefits, policyStatus)
      ),
    isLoading: Boolean(
      status?.loaded === EXP_CUSTOMER_STATUS_UNLOADED ||
        status?.loaded === EXP_CUSTOMER_STATUS_LOADING
    ),
    hasFailed: Boolean(status?.loaded === EXP_CUSTOMER_STATUS_FAILED),
  }
}

export const getCustomerPoliciesCardData = createSelector(
  [getCustomerPolicies, getMasterList, getCustomerRelationships, getPolicyStatuses],
  (policies, { benefitStatus, policyStatus, benefitList }, relationships, statuses) =>
    policies.map(policy => {
      const status = statuses.find(stat => stat.policyNo === policy.policyNo)
      return {
        ...buildPolicyCardProp(
          policy,
          { policyStatus, benefitStatus, benefitList },
          relationships,
          status,
          true
        ),
        bancsPolicyNo: policy.policyNo,
        status: policy.status,
        paymentMethod: policy.premiumPaymentMethod,
        paymentInstrument: policy.paymentInstrument,
        policyPremiumFrequency: policy.policyPremiumFrequency,
        outstandingBalance: policy.outstandingBalance,
        nextPremiumDueDate:
          policy.benefits && policy.benefits[0] && policy.benefits[0].nextPremiumDueDate,
        policyBenefitStatuses:
          policy.benefits &&
          policy.benefits.map(benefit => ({
            status: benefit.benefitStatus,
            statusDate: benefit.benefitStatusDate,
          })),
        nextAnniversaryDate: policy.nextAnniversaryDate,
        futurePremium: policy.futurePremium,
      }
    })
)

export const getInforceCustomerPoliciesCardData = createSelector(
  [getInforceCustomerPolicies, getMasterList, getCustomerRelationships, getPolicyStatuses],
  (policies, { benefitStatus, policyStatus, benefitList }, relationships, statuses) =>
    policies.map(policy => {
      const status = statuses.find(stat => stat.policyNo === policy.policyNo)

      return {
        ...buildPolicyCardProp(
          policy,
          { policyStatus, benefitStatus, benefitList },
          relationships,
          status
        ),
        status: policy.status,
      }
    })
)

export const getOutOfForceCustomerPoliciesCardData = createSelector(
  [getOutOfForceCustomerPolicies, getMasterList],
  (policies, { policyStatus }) =>
    policies.map(({ status, policyId, policyName }) => ({
      currentStatus: get(
        policyStatus.find(p => p.status.toUpperCase() === status.toUpperCase()),
        'value',
        POLICY_STATUS_NA
      ),
      policyName,
      policyId,
    }))
)

export const getAllPolicyOwnersBancsCustomerNos = createSelector(
  [getCustomerPolicies],
  policies => [...new Set(policies.flatMap(policy => policy.policyOwners))]
)

export const checkIfEveryPolicyFailed = createSelector(
  [getPolicyStatuses],
  statuses =>
    statuses.length > 0 && statuses.every(status => status.loaded === EXP_CUSTOMER_STATUS_FAILED)
)
