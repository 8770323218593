import React from 'react'
import { shape, string, object, objectOf } from 'prop-types'

// components
import { PageWrap, Heading, Image as PageBannerImage } from '@mlcl-digital/mlcl-design'
import Link from '@mlcl-digital/mlcl-design/lib/base/Link'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import FlexRow from '../../molecules/FlexRow'
// @ts-expect-error not a TS Component (yet)
import CustomerPortalLifeCycle from '../../molecules/CustomerPortalLifeCycle'
// @ts-expect-error not a TS Component (yet)
import CustomerPolicyAnniversary from '../CustomerPolicyAnniversary'

// utils.
import type { SitecoreField, SitecoreImage, SitecoreLink } from '../../../types/sitecore'
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'

// selectors
import { getIsPremiumCalculatorEligible } from '../../../selectors/premiumCalculator'

// styles
import styles from './pageBanner.styles'

const PageBannerLink = styled(Link)(styles.button)

const IMAGE_ALIGNMENT_LEFT = 'left'
const IMAGE_ALIGNMENT_RIGHT = 'right'
const COVER_MANAGER_BANNER_NAME = 'CoverManager'

export type PageBannerProps = {
  readonly params: {
    readonly Alignment?: string
    readonly Name?: string
  }
  readonly fields: {
    readonly Heading: SitecoreField
    readonly Content: SitecoreField
    readonly Button?: SitecoreLink
    readonly Image?: SitecoreImage
  }
}

export const PageBanner = ({ fields, params }: PageBannerProps) => {
  const { Button, Image } = reduceAuthorableFields(fields)
  const isPremiumCalculatorEligible = useSelector(getIsPremiumCalculatorEligible)

  const imageDiv = Image?.src && (
    <div>
      <PageBannerImage src={Image.src} alt={Image.alt} />
    </div>
  )
  const bannerName = params?.Name ?? COVER_MANAGER_BANNER_NAME
  const isImageAlignment = (expected: string) => expected === params?.Alignment?.toLowerCase()

  const renderBannerLink = () => {
    switch (bannerName) {
      case COVER_MANAGER_BANNER_NAME: {
        return (
          <div>
            <PageBannerLink
              disabled={!isPremiumCalculatorEligible}
              testid="pagebanner-link"
              variant="primary"
              to={Button?.href ?? '/'}
            >
              {renderTextField(Button?.text)}
            </PageBannerLink>
          </div>
        )
      }
      default: {
        return (
          <div>
            <PageBannerLink testid="pagebanner-link" variant="primary" to={Button?.href ?? '/'}>
              {renderTextField(Button?.text)}
            </PageBannerLink>
          </div>
        )
      }
    }
  }

  const renderBanner = () => (
    <PageWrap>
      <FlexRow>
        {isImageAlignment(IMAGE_ALIGNMENT_LEFT) && imageDiv}
        <div>
          <Heading variant="h3" size="MD">
            {renderTextField(fields.Heading)}
          </Heading>
          {renderTextField(fields.Content, true /* rich_text */)}
          {renderBannerLink()}
        </div>
        {isImageAlignment(IMAGE_ALIGNMENT_RIGHT) && imageDiv}
      </FlexRow>
    </PageWrap>
  )

  return (
    <>
      {bannerName === COVER_MANAGER_BANNER_NAME ? (
        <CustomerPortalLifeCycle>
          <CustomerPolicyAnniversary />
          {renderBanner()}
        </CustomerPortalLifeCycle>
      ) : (
        renderBanner()
      )}
    </>
  )
}

PageBanner.propTypes = {
  params: shape({
    Alignment: string,
    Name: string,
  }).isRequired,
  fields: objectOf(object).isRequired,
}

export default PageBanner
