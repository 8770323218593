// @flow
import get from 'lodash/get'
import moment from 'moment'
import {
  CREATE_ENQUIRY_ID_SUCCESS,
  CREATE_CHILD_ENQUIRY_ID_SUCCESS,
  CREATE_CHILD_ENQUIRY_ID_INIT,
  CREATE_ENQUIRY_ID_INIT,
  CREATE_QUOTE_REMOVE_BENEFIT,
  CREATE_ENQUIRY_ID_FAILURE,
  CREATE_ENQUIRY_ID_ERROR,
  CREATE_CHILD_ENQUIRY_ID_ERROR,
  CREATE_CHILD_ENQUIRY_ID_FAILURE,
  RESET_STATE_FOR_NEW_QUOTE,
} from '../actions/types/createQuote'
import {
  SAVE_ANSWERS_URE,
  UPDATE_DATA_WITH_NEW_DETAILS_URE,
  GET_RESULTS_OF_URE_SUCCESS,
  INITIALIZE_DATA_URE_SUCCESS,
  SAVE_URL_FOR_URE_RESULT,
  UPDATE_URE_WITH_ALL_ACTIVE_ENQUIRY_ID,
  GET_PAYLOAD_OF_FULL_URE_SUCCESS,
  UPDATE_ANSWERS_OF_FULL_URE_SUCCESS,
  SET_ACTIVE_ENQUIRY_ID_OF_URE,
  UPDATE_STATUS_OF_ENQUIRY,
  FETCH_FULL_URE_DATA_SUCCESS,
  FETCH_FULL_URE_DATA_INIT,
  RESET_URE_DATA,
  UPDATE_ANSWERS_OF_FULL_URE_ERROR,
  UPDATE_ANSWERS_OF_FULL_URE_FAILURE,
  OPEN_URE_ERROR_MODAL_POPUP,
  CLOSE_URE_ERROR_MODAL_POPUP,
  INITIALIZE_DATA_URE_INIT,
  INITIALIZE_DATA_URE_ERROR,
  INITIALIZE_DATA_URE_FAILURE,
  FETCH_FULL_URE_DATA_FAILURE,
  FETCH_FULL_URE_DATA_ERROR,
  UPDATE_UNDER_WRITING_METHOD,
  GET_PAYLOAD_OF_FULL_URE_ERROR,
  GET_PAYLOAD_OF_FULL_URE_FAILURE,
  RESET_FULL_URE_RESULT_SUCCESS_DATA,
  CONFIRM_SMOKER_TERMS,
  RESET_CONFIRM_SMOKER_TERMS,
  GET_DETAILS_FOR_ENQUIRY_ID_INIT,
  GET_DETAILS_FOR_ENQUIRY_ID_SUCCESS,
  GET_DETAILS_FOR_ENQUIRY_ID_ERROR,
  GET_DETAILS_FOR_ENQUIRY_ID_FAILURE,
  GET_RESULTS_OF_URE_INIT,
  GET_RESULTS_OF_URE_FAILURE,
  GET_RESULTS_OF_URE_ERROR,
} from '../actions/types/ure'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'
import { INIT_STATE } from '../actions/types/navigation'

export const initialState = {
  initialData: {},
  activeEnquiryId: '',
  answers: {},
  callBackUrl: '',
  results: {},
  activeEnquiryIds: [],
  fullUREresult: {},
  isLoading: false,
  errorModal: {
    isOpen: false,
  },
  agreedOnSmokerTerms: false,
  underwritingMethod: '',
  isRefetchURE: false,
}

const ure = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case CREATE_CHILD_ENQUIRY_ID_INIT:
    case FETCH_FULL_URE_DATA_INIT:
    case GET_DETAILS_FOR_ENQUIRY_ID_INIT:
    case GET_RESULTS_OF_URE_INIT:
    case CREATE_ENQUIRY_ID_INIT: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case CREATE_CHILD_ENQUIRY_ID_SUCCESS: {
      const {
        dataReceived: { enquiryId },
      } = action.payload
      return {
        ...state,
        answers: {
          ...state.answers,
          [enquiryId]: action.payload.dataReceived,
        },
        isLoading: false,
      }
    }
    case CREATE_QUOTE_REMOVE_BENEFIT: {
      const { activeEnquiryIds, activeEnquiryId, answers } = state
      const {
        payload: {
          benefit: { benefitInstanceNo, policyInstanceNo },
        },
      } = action
      let newActiveEnquiryId = activeEnquiryId

      const indexOfDeletedCover = activeEnquiryIds.findIndex(
        enquiry =>
          enquiry.benefitInstanceNo === benefitInstanceNo &&
          enquiry.policyInstanceNo === policyInstanceNo
      )

      if (indexOfDeletedCover !== -1) {
        if (activeEnquiryIds[indexOfDeletedCover].enquiryId === activeEnquiryId) {
          newActiveEnquiryId = activeEnquiryIds[0].enquiryId
        }
        delete answers[activeEnquiryIds[indexOfDeletedCover].enquiryId]
        activeEnquiryIds.splice(indexOfDeletedCover, 1)
      }

      return {
        ...state,
        activeEnquiryId: newActiveEnquiryId,
        activeEnquiryIds,
        answers,
      }
    }
    case CREATE_ENQUIRY_ID_SUCCESS: {
      const {
        dataReceived: { enquiryId },
      } = action.payload
      return {
        ...state,
        isLoading: false,
        activeEnquiryId: enquiryId,
        answers: {
          ...state.answers,
          [enquiryId]: action.payload.dataReceived,
        },
      }
    }
    case SAVE_ANSWERS_URE: {
      const {
        activeEnquiryId,
        answers: {
          allAnswers: { PRODUCT },
        },
      } = action.payload
      return {
        ...state,
        ...(!PRODUCT.includes('Child Critical Illness')
          ? {
              activeEnquiryId,
            }
          : {}),
        answers: {
          ...state.answers,
          [activeEnquiryId]: action.payload.answers,
        },
      }
    }
    case INITIALIZE_DATA_URE_INIT: {
      return {
        ...state,
        isUreTemplateFetching: true,
      }
    }
    case INITIALIZE_DATA_URE_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        initialData: data,
        isUreTemplateFetching: false,
      }
    }
    case UPDATE_DATA_WITH_NEW_DETAILS_URE: {
      const { updatedAnswers } = action.payload
      return {
        ...state,
        answers: updatedAnswers,
      }
    }
    case GET_RESULTS_OF_URE_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        results: data,
      }
    }
    case SAVE_URL_FOR_URE_RESULT: {
      const { path } = action.payload
      return {
        ...state,
        callBackUrl: path,
      }
    }
    case UPDATE_URE_WITH_ALL_ACTIVE_ENQUIRY_ID: {
      const { activeEnquiryIds } = action.payload
      return {
        ...state,
        activeEnquiryIds,
      }
    }
    case SET_ACTIVE_ENQUIRY_ID_OF_URE: {
      const { enquiryId } = action.payload
      return {
        ...state,
        activeEnquiryId: enquiryId,
      }
    }
    case UPDATE_STATUS_OF_ENQUIRY: {
      const { activeEnquiryIds } = state
      const { enquiryId } = action.payload
      const indexOfPayload = activeEnquiryIds.findIndex(enquiry => enquiry.enquiryId === enquiryId)
      const copyOfActiveEnquiryIds = [...activeEnquiryIds]
      if (indexOfPayload !== -1) {
        copyOfActiveEnquiryIds[indexOfPayload] = {
          ...activeEnquiryIds[indexOfPayload],
          isEnquired: true,
        }
      }
      return {
        ...state,
        activeEnquiryIds: copyOfActiveEnquiryIds,
      }
    }
    case GET_PAYLOAD_OF_FULL_URE_SUCCESS: {
      const { activeEnquiryIds } = state
      const { data: { activeQuote, ...rest } = {}, metaData } = action.payload
      const indexOfPayload = activeEnquiryIds.findIndex(
        enquiry => enquiry.enquiryId === metaData.enquiryId
      )
      const copyOfActiveEnquiryIds = [...activeEnquiryIds]
      if (indexOfPayload !== -1) {
        copyOfActiveEnquiryIds[indexOfPayload] = {
          ...activeEnquiryIds[indexOfPayload],
          payload: rest,
        }
        return {
          ...state,
          activeEnquiryIds: copyOfActiveEnquiryIds,
          activeEnquiryId: metaData.enquiryId,
        }
      }
      return {
        ...state,
        activeEnquiryId: metaData.enquiryId,
      }
    }
    case UPDATE_ANSWERS_OF_FULL_URE_ERROR:
    case UPDATE_ANSWERS_OF_FULL_URE_FAILURE: {
      const { activeEnquiryIds, activeEnquiryId } = state
      const indexOfPayload = activeEnquiryIds.findIndex(
        enquiry => enquiry.enquiryId === activeEnquiryId
      )
      const copyOfActiveEnquiryIds = [...activeEnquiryIds]
      if (indexOfPayload !== -1) {
        copyOfActiveEnquiryIds[indexOfPayload] = {
          ...activeEnquiryIds[indexOfPayload],
          payload: {},
        }
      }
      return {
        ...state,
        activeEnquiryIds: copyOfActiveEnquiryIds,
        errorModal: {
          isOpen: true,
        },
      }
    }
    case UPDATE_ANSWERS_OF_FULL_URE_SUCCESS: {
      const { activeEnquiryIds } = state
      const { metaData } = action.payload
      const indexOfPayload = activeEnquiryIds.findIndex(
        enquiry => enquiry.enquiryId === metaData.enquiryId
      )
      const answersOfURE = { ...action.payload }
      delete answersOfURE.metaData
      const copyOfActiveEnquiryIds = [...activeEnquiryIds]
      if (indexOfPayload !== -1) {
        copyOfActiveEnquiryIds[indexOfPayload] = {
          ...activeEnquiryIds[indexOfPayload],
          updatedOn: new Date().getTime(),
        }
        return {
          ...state,
          answers: {
            ...state.answers,
            [metaData.enquiryId]: answersOfURE,
          },
          activeEnquiryIds: copyOfActiveEnquiryIds,
          activeEnquiryId: metaData.enquiryId,
        }
      }
      return {
        ...state,
        activeEnquiryId: metaData.enquiryId,
      }
    }
    case RESET_STATE_FOR_NEW_QUOTE:
    case INIT_STATE: {
      return {
        ...state,
        initialData: {},
        activeEnquiryId: '',
        activeEnquiryIds: [],
        fullUREresult: {},
        answers: {},
        results: {},
      }
    }
    case FETCH_FULL_URE_DATA_SUCCESS: {
      const { data, metaData } = action.payload
      // setting isRefetchURE flag to true if refetching full ure api
      const time = moment().startOf('second').toISOString()
      return {
        ...state,
        fullUREresult: { ...data, timeReceived: time },
        isLoading: false,
        isRefetchURE: get(metaData, 'isRefetchURE') || false,
      }
    }

    case RESET_URE_DATA: {
      return {
        ...state,
        initialData: {},
        activeEnquiryId: '',
        activeEnquiryIds: [],
        fullUREresult: {},
        answers: {},
        results: {},
      }
    }

    case RESET_FULL_URE_RESULT_SUCCESS_DATA: {
      return {
        ...state,
        fullUREresult: {},
      }
    }

    case UPDATE_UNDER_WRITING_METHOD: {
      const { underwritingMethod } = action.payload
      return {
        ...state,
        underwritingMethod,
      }
    }

    case OPEN_URE_ERROR_MODAL_POPUP: {
      return {
        ...state,
        errorModal: {
          isOpen: true,
        },
      }
    }

    case CLOSE_URE_ERROR_MODAL_POPUP: {
      return {
        ...state,
        errorModal: {
          isOpen: false,
        },
        isLoading: false,
      }
    }
    case GET_PAYLOAD_OF_FULL_URE_FAILURE:
    case GET_PAYLOAD_OF_FULL_URE_ERROR:
    case FETCH_FULL_URE_DATA_FAILURE:
    case FETCH_FULL_URE_DATA_ERROR:
    case CREATE_ENQUIRY_ID_FAILURE:
    case CREATE_ENQUIRY_ID_ERROR:
    case CREATE_CHILD_ENQUIRY_ID_ERROR:
    case CREATE_CHILD_ENQUIRY_ID_FAILURE: {
      return {
        ...state,
        errorModal: {
          isOpen: true,
        },
      }
    }

    case INITIALIZE_DATA_URE_ERROR:
    case INITIALIZE_DATA_URE_FAILURE: {
      return {
        ...state,
        errorModal: {
          isOpen: true,
        },
        isUreTemplateFetching: false,
      }
    }

    case CONFIRM_SMOKER_TERMS: {
      return {
        ...state,
        agreedOnSmokerTerms: true,
      }
    }

    case RESET_CONFIRM_SMOKER_TERMS: {
      return {
        ...state,
        agreedOnSmokerTerms: false,
      }
    }

    case GET_DETAILS_FOR_ENQUIRY_ID_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isLoading: false,
        answers: {
          ...state.answers,
          [payload.enquiryId]: payload,
        },
      }
    }

    case GET_DETAILS_FOR_ENQUIRY_ID_ERROR:
    case GET_DETAILS_FOR_ENQUIRY_ID_FAILURE:
    case GET_RESULTS_OF_URE_ERROR:
    case GET_RESULTS_OF_URE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default ure
