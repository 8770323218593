import moment from 'moment'

// utils
// @ts-expect-error file not in typescript
import { getBenefeciaryName, addOptimiserSuffixToBenefitName } from './policyUtils'
// @ts-expect-error file not in typescript
import { formatCurrency } from './quoteUtils'

// types
import { benefit, ClientPolicy, Relationship, RevisedBenefitDetails } from '../types/ClientPolicies'
import { Rules } from '../types/alterations'

// constants
import {
  INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  POLICY_RELATIONSHIPS_LIFEASSURED,
} from '../constants/policies'
import { BENEFIT_STATE_INFORCE } from '../constants/benefit'

// Picking variant based on the deadline
export const pickVariantByRange = (percentage: number) => {
  if (percentage >= 1 && percentage <= 5) return 'danger'
  if (percentage > 6 && percentage <= 20) return 'warning'
  return 'default'
}

// Life Insured names from relationships, sorting with primary insured on top
// Appending primary in case of multiple life assured
export const getAllLifeInsuredName = (relationships: Relationship[]) => {
  const allLifeInsured = relationships
    .filter(({ roleCode }) => roleCode === POLICY_RELATIONSHIPS_LIFEASSURED)
    .sort(
      (a, b) =>
        (a.isPrimaryLifeAssured === 'Y' ? -1 : 1) - (b.isPrimaryLifeAssured === 'Y' ? -1 : 1)
    )
  const lifeInsuredNames = allLifeInsured
    .map(({ relatedParty, isPrimaryLifeAssured }) =>
      allLifeInsured.length > 1 && isPrimaryLifeAssured === 'Y'
        ? `${getBenefeciaryName(relatedParty) as string} (Primary)`
        : (getBenefeciaryName(relatedParty) as string)
    )
    .join(', ')
  return lifeInsuredNames
}

// Calculating esclatation deadline for Reject CPI
export const getEscalationDeadlineDaysForPolicy = (rules: Rules, bancsPolicyNo: string) => {
  const policiesListInRules = rules?.businessData?.policies || []
  const matchingPolicyinAlterationRules = policiesListInRules.find(
    policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
  )
  const escalationRejectionDeadline =
    matchingPolicyinAlterationRules?.assesment?.rejectCPI_EscalationRejectionDeadline
  const formattedEscalationRejectionDeadline = escalationRejectionDeadline
    ? moment(escalationRejectionDeadline, 'DD/MM/YYYY')
    : ''
  const today = moment(rules?.businessData?.assesment.systemInfo_TodaysDate, 'DD/MM/YYYY')
  const escalationDeadLine = formattedEscalationRejectionDeadline
    ? formattedEscalationRejectionDeadline.diff(today, 'days') + 1
    : 0
  return escalationDeadLine
}

export const getBenefitDetailsForPolicyCard = (
  benefits: benefit[],
  revisedBenefitDetails: RevisedBenefitDetails[] = []
) => {
  const policyBenefits = revisedBenefitDetails.reduce(
    (acc: Array<{ benefitName: string; benefitAmount: string }>, item) => {
      const selectedBenefit = benefits.find(
        benefitItem =>
          benefitItem.type === item.benefitCode &&
          benefitItem.benefitInstanceNo === item.benefitInstance
      )
      if (selectedBenefit && selectedBenefit.benefitStatus === BENEFIT_STATE_INFORCE) {
        return [
          ...acc,
          {
            benefitName: addOptimiserSuffixToBenefitName(
              {
                ...item,
                name: selectedBenefit.name,
                optimiserParentBenefitReference: selectedBenefit.optimiserParentBenefitReference,
              },
              false
            ) as string,
            benefitAmount: `${formatCurrency(item.newSumassured, '$', 0)} ${
              INCOME_PROTECTION_RELATED_POLICY_BENEFITS.includes(item.benefitCode) ? 'p/m' : ''
            }`.trim(),
          },
        ]
      }
      return acc
    },
    []
  )
  return policyBenefits
}

type HasPolicyNumber = Readonly<Pick<ClientPolicy, 'bancsPolicyNo'>>

/**
 * Returns a sort function which sorts policies with a `bancsPolicyNo`
 * attribute in the supplied `submittedPoliciesList` list to the start
 * of the list and all others to the end.
 *
 * When no list, or an empty list is supplied, the returned function is
 * the identity sort function.
 *
 * @param submittedPoliciesList the list of submitted `bancsPolicyNo` values
 * @returns a sort function for the `ClientPolicy` data
 */
export const sortPoliciesByPendingStatus: (
  submitted: string[]
) => (a: HasPolicyNumber, b: HasPolicyNumber) => number = (submittedPoliciesList: string[] = []) =>
  !submittedPoliciesList || submittedPoliciesList.length === 0
    ? () => 0 // Don't sort with no policies in the list
    : (a: HasPolicyNumber, b: HasPolicyNumber) => {
        const aInSubmittedList = submittedPoliciesList.includes(a.bancsPolicyNo)
        const bInSubmittedList = submittedPoliciesList.includes(b.bancsPolicyNo)

        if (aInSubmittedList && !bInSubmittedList) return -1
        if (!aInSubmittedList && bInSubmittedList) return 1

        return 0
      }

type HasDaysRemaining = {
  readonly anniversary: moment.Moment
  readonly daysRemaining?: {
    readonly label: number
  }
}

/**
 * Sorts the RejectCPI policy data by the anniversary date, in order from earliest to latest.
 *
 * @param lhs left-hand parameter of the sort
 * @param rhs right-hand parameter of the sort
 * @returns literal `0` if the values are equal, `< 0` if `lhs` is "smaller" and `> 0` otherwise
 */
export function sortByAnniversary(lhs: HasDaysRemaining, rhs: HasDaysRemaining): number {
  if (lhs.anniversary === rhs.anniversary)
    // Both undefined or equal
    return 0
  if (!lhs.anniversary.isValid())
    // LHS not valid
    return -1
  if (!rhs.anniversary.isValid())
    // RHS not valid
    return 1
  return lhs.anniversary.valueOf() - rhs.anniversary.valueOf()
}

/**
 * Sorts the RejectCPI policy data by the number of days remaining to alter the
 * policy, in order from least days to most days.
 *
 * If the number of days remaining is identical then this function will fallback
 * to using the {@link sortByAnniversary} function.
 *
 * @param lhs left-hand parameter of the sort
 * @param rhs right-hand parameter of the sort
 * @returns literal `0` if the values are equal, `< 0` if `lhs` is "smaller" and `> 0` otherwise
 */
export function sortByDaysRemaining(lhs: HasDaysRemaining, rhs: HasDaysRemaining): number {
  const lhsDays = lhs.daysRemaining?.label
  const rhsDays = rhs.daysRemaining?.label
  if (lhsDays === rhsDays)
    // Both undefined or equal
    return sortByAnniversary(lhs, rhs)
  if (typeof lhsDays === 'undefined')
    // LHS not eligible
    return 1
  if (typeof rhsDays === 'undefined')
    // RHS not eligible
    return -1
  return lhsDays - rhsDays
}
