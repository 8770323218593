import {
  SET_TRUSTEE_UPDATE_NOTIFICATION,
  SHOW_CALL_TO_ACTION_POP_UP,
} from '../actions/types/trusteeReplacement'

export const initialState = {
  isNotification: false,
}

const trusteeReplacementReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: unknown }
) => {
  switch (type) {
    case SET_TRUSTEE_UPDATE_NOTIFICATION: {
      return {
        ...state,
        isNotification: payload as boolean,
      }
    }
    case SHOW_CALL_TO_ACTION_POP_UP: {
      return {
        ...state,
        showPaymentPopup: payload as boolean,
      }
    }
    default: {
      return state
    }
  }
}

export default trusteeReplacementReducer
