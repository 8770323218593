import React, { useCallback, useEffect } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'

import { Heading, PageWrap } from '@mlcl-digital/mlcl-design'
import List from '@mlcl-digital/mlcl-design/lib/base/List'
import Link from '@mlcl-digital/mlcl-design/lib/base/Link'
import { createEvent } from '../../../utils/telemetry'

import { renderTextField } from '../../../utils/sitecoreUtils'

// selectors
// @ts-expect-error file not in typescript
import { getAlterationType } from '../../../selectors/alterations'
import { isEligiblePoliciesRemainingForSubmission } from '../../../selectors/alterationPolicies'
import { getCreateQuote } from '../../../selectors/common.selectors'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../actions'

// constatns
import { DASHBOARD_ROUTE, INFLATION_PROOFING_LANDING_PAGE } from '../../../constants/routes'
import { NAVIGATION_MAIN, FOOTER_MAIN } from '../../../constants/navigation'

// styles
import styles from './altsSubmissionV2.styles'

// types
import { AltsSubmissionV2Props } from '../../../types/components/AltsSubmission'

// component
import SelectedPolicies from '../Alteration/AltsDeclarationV2/components/SelectedPolicies'

const Container = styled('div')(styles.container)
const HeadingWrap = styled('div')(styles.headingWrap)
const LinkContainer = styled('div')(styles.linkContainer)
const PoliciesContainer = styled('div')(styles.policiesContainer)

const AltsSubmissionV2 = ({ fields }: AltsSubmissionV2Props) => {
  const alterationTypeForAnalytics: string = useSelector(getAlterationType())
  const createQuote = useSelector(getCreateQuote)
  const isReviewOtherPoliciesApplicable: boolean = useSelector(
    isEligiblePoliciesRemainingForSubmission(createQuote.alterationType)
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actionCreators.changeNavigationType(NAVIGATION_MAIN))
    dispatch(actionCreators.changeFooterType(FOOTER_MAIN))
    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: 'Submitted app page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationTypeForAnalytics} alteration Submitted app page load`,
        },
      },
    })
    tagEvent.end()
  }, [])

  const onClick = useCallback(() => {
    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: 'Submitted app page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationTypeForAnalytics} Back to dashboard`,
        },
      },
    })
    tagEvent.end()
  }, [])

  return (
    <PageWrap>
      <Container>
        <HeadingWrap>
          <Heading variant="h2">
            <Text field={fields.QuoteDetailsHeading} />
          </Heading>
          <p>
            <Text field={fields.QuoteDetailsDesc} />
          </p>
        </HeadingWrap>
        <PoliciesContainer>
          <SelectedPolicies fields={fields} />
        </PoliciesContainer>
        <List
          variant="number"
          heading={fields.ExpectNextHeading?.value}
          headingProps={{ variant: 'h3' }}
          listItems={fields.ExpectNextSteps?.map(listItem =>
            renderTextField(listItem?.fields?.Description?.value, true)
          )}
        />
        <LinkContainer>
          <Link variant="secondary" to={DASHBOARD_ROUTE} onClick={onClick}>
            {renderTextField(fields.BackCTA)}
          </Link>
          {isReviewOtherPoliciesApplicable && (
            <Link variant="primary" to={INFLATION_PROOFING_LANDING_PAGE}>
              {renderTextField(fields.ReviewOtherPoliciesCTA)}
            </Link>
          )}
        </LinkContainer>
      </Container>
    </PageWrap>
  )
}

export default AltsSubmissionV2
