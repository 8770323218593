import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'

// components
import { PageWrap, Heading, Button } from '@mlcl-digital/mlcl-design'
import FontAwesomeIcon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import Link from '@mlcl-digital/mlcl-design/lib/base/Link'
import SectionWrapper from '../SectionWrapper'
import SidePanel from '../SidePanel'
// @ts-expect-error file not in typescript
import ScrollToTop from '../../../atoms/ScrollToTop'
// @ts-expect-error file not in typescript
import CustomerPortalLifeCycle from '../../../molecules/CustomerPortalLifeCycle'
// @ts-expect-error file not in typescript
import CustomerPolicyAnniversary from '../../CustomerPolicyAnniversary'

// types
import {
  LandingPageFieldsType,
  NeedHelpFields,
} from '../../../../types/components/PremiumCalculator'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// constants
import { DASHBOARD_ROUTE, PREMIUM_CALCULATOR_ROUTE } from '../../../../constants/routes'
import { ALTERATION_TYPES } from '../../../../constants/alterations'

// selectors
import {
  getPremiumCalculatorValidationMessage,
  getPremiumCalculatorStartedStatus,
  getIsPremiumCalculatorEligible,
} from '../../../../selectors/premiumCalculator'
import {
  getIsFetchingPolicies,
  getWithInPeriodEffectiveDate,
  getBancsCustomerNoOfLAFromAlterations,
  getIsAllAltsRules,
  // @ts-expect-error file not in typescript
} from '../../../../selectors/alterations'

// utils
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../utils/telemetry'
// @ts-expect-error file not in typescript
import history from '../../../../utils/browserHistory'

// styles
import styles from './landingPage.styles'
import { Store } from '../../../../types/store'

const ErrorMessage = styled('div')(styles.errorMessage)
const ButtonContainer = styled('div')(styles.buttonContainer)
const Disclaimer = styled('div')(styles.disclaimer)
const SidePanelContent = styled('div')(styles.sidePanelContent)
const QuoteChangesContainer = styled('div')(styles.quoteChangesContainer)

type LandingPageProps = {
  fields: LandingPageFieldsType & NeedHelpFields
}

const LandingPage = ({ fields }: LandingPageProps) => {
  const dispatch = useDispatch()

  const validationMessage = useSelector(getPremiumCalculatorValidationMessage)
  const enableGetStarted = useSelector(getPremiumCalculatorStartedStatus)
  const bancsCustomerNoOfLA = useSelector(getBancsCustomerNoOfLAFromAlterations) as string
  const withInPeriodEffectiveDate = useSelector(getWithInPeriodEffectiveDate)
  const isLoadingPoliciesDetail = useSelector(getIsFetchingPolicies)
  const isAllAltsRules = useSelector(getIsAllAltsRules)
  const isPremiumCalculatorEligible = useSelector(getIsPremiumCalculatorEligible)
  const alterationPolicies = useSelector((state: Store) => state?.alterations.policies)

  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'PC-LandingPageload',
        action: 'Landing page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator landing page load',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetTimelineData())
    if (withInPeriodEffectiveDate) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(
        actionCreators.getAlterationPolicies(
          bancsCustomerNoOfLA,
          withInPeriodEffectiveDate,
          (err: object) => {
            const apiEvent = createEvent({
              Splunk: {
                attributes: {
                  'workflow.name':
                    'Premium calculator fetching future dated premiums for anniversary',
                  error: !!err,
                },
              },
            })
            apiEvent.end()
          }
        )
      )
    }
  }, [])

  useEffect(() => {
    // For deeplinking, for any advised customer redirect to dashboard
    if (!isPremiumCalculatorEligible && alterationPolicies?.length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      history.push(DASHBOARD_ROUTE)
    }
  }, [alterationPolicies?.length])

  const handleGetStarted = () => {
    const event = createEvent({
      GA: {
        category: 'PC-LandingGetstarted',
        action: 'Get started',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator get started',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetAlterationsQuoteError())
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetSumInsuredErrors())

    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.createAltsQuote({
        alterationType: ALTERATION_TYPES.DECREASE_RISK,
      })
    )

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.push(PREMIUM_CALCULATOR_ROUTE)
  }

  const handleBackClick = () => {
    const action = 'Back - landing page'
    const event = createEvent({
      GA: {
        category: 'PC-LandingBack',
        action,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Premium calculator ${action}`,
        },
      },
    })
    event.end()
  }

  return (
    <ScrollToTop>
      <CustomerPortalLifeCycle>
        <CustomerPolicyAnniversary />
        <PageWrap>
          {validationMessage && (
            <ErrorMessage>
              <div>
                <FontAwesomeIcon iconName={['far', 'triangle-exclamation']} />
                <span>{validationMessage}</span>
              </div>
            </ErrorMessage>
          )}
          <SectionWrapper>
            <div>
              <Heading variant="h2" size="large">
                {renderTextField(fields.Heading)}
              </Heading>
              <QuoteChangesContainer>
                <Heading variant="h4" size="SMD">
                  {renderTextField(fields.Steps)}
                </Heading>
                <ul>
                  {fields?.PremiumCalculatingSteps?.map(listItem => (
                    <li>
                      <div>
                        <FontAwesomeIcon
                          iconName={['far', listItem?.fields?.Icon?.value as 'check']}
                        />
                      </div>
                      <Heading variant="h4">{listItem?.fields?.Heading?.value}</Heading>
                    </li>
                  ))}
                </ul>
              </QuoteChangesContainer>
              <ButtonContainer>
                <Link variant="secondary" onClick={() => handleBackClick()} to={DASHBOARD_ROUTE}>
                  {renderTextField(fields.BackButton)}
                </Link>
                <Button
                  isLoading={isLoadingPoliciesDetail || !isAllAltsRules}
                  disabled={!enableGetStarted}
                  variant={enableGetStarted ? 'primary' : 'secondary'}
                  onClick={() => handleGetStarted()}
                >
                  {renderTextField(fields.GetStartedButton)}
                </Button>
              </ButtonContainer>
              <Disclaimer>
                <div>{renderTextField(fields.Disclaimer)}</div>
                {renderTextField(fields.DisclaimerContent, true)}
              </Disclaimer>
            </div>
            <SidePanel isLandingPage fields={fields} heading={fields?.SideHeading?.value}>
              <SidePanelContent>
                {fields.ChangingYourCoverContentSteps.map(listItem => (
                  <>
                    <Heading variant="h4">{renderTextField(listItem.fields.Heading)}</Heading>
                    <p>{renderTextField(listItem.fields.Description)}</p>
                  </>
                ))}
              </SidePanelContent>
            </SidePanel>
          </SectionWrapper>
        </PageWrap>
      </CustomerPortalLifeCycle>
    </ScrollToTop>
  )
}

export default LandingPage
