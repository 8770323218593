import React from 'react'
import styled from '@emotion/styled'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'

// utils
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { getEmail, getFullName, getPhoneNumberWithCode } from '../../../../utils/contactUtils'

// types
// eslint-disable-next-line max-len
import { DirectorProps } from '../../../../types/components/PersonalAndContactDetails'

// styles
import styles from '../personalAndContactDetails.styles'

const EmailAddress = styled(LabelValuePair)(styles.emailAddress)

const Director = ({ fields, data }: DirectorProps) => {
  const fullName = getFullName(data?.relatedParty)
  const phoneNumber = getPhoneNumberWithCode(data?.relatedParty?.contactMethods?.phones, null)
  const emailAddress = getEmail(data?.relatedParty?.contactMethods?.emails || [])
  return (
    <>
      <GridContainer>
        <LabelValuePair label={renderTextField(fields.FullName)} value={fullName} />
        <LabelValuePair label={renderTextField(fields.PhoneNumber)} value={`+${phoneNumber}`} />
        <EmailAddress label={renderTextField(fields.EmailAddress)} value={emailAddress} />
      </GridContainer>
    </>
  )
}

export default Director
