import React, { useEffect } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Heading, PageWrap } from '@mlcl-digital/mlcl-design'
import Link from '@mlcl-digital/mlcl-design/lib/base/Link'
import { createEvent } from '../../../utils/telemetry'
import { AltsDecreasePolicyCard } from '../../molecules/AltsDecreasePolicyCard'

import { renderTextField } from '../../../utils/sitecoreUtils'

import { getCreateQuote } from '../../../selectors/common.selectors'
import { getAlterationType, getDecreaseCoverPolicyDetails } from '../../../selectors/alterations'
import {
  makePolicyOwner,
  makePolicyList,
  makeLifeInsured,
  makeAltsTypeLabel,
  makeIsApplicationComplete,
} from '../../../selectors/altsSubmission.selectors'

// actions
import { actionCreators } from '../../../actions'

// constatns
import { DASHBOARD_ROUTE } from '../../../constants/routes'
import { NAVIGATION_MAIN, FOOTER_MAIN } from '../../../constants/navigation'
import { ALTERATION, ALTERATION_TYPES } from '../../../constants/alterations'

import styles from './altsSubmission.styles'

const PageWrapper = styled(PageWrap)(styles.pageWrapper)
const HeadingWrap = styled(PageWrapper)(styles.headingWrap)
const Wrap = styled('div')(styles.wrap)
const ApplicationDetails = styled('div')(styles.applicationDetails)
const PersonalStatement = styled('div')(styles.personalStatement)
const WhatHappensNext = styled('div')(styles.whatHappensNext)
const CardWrap = styled(WhatHappensNext)(styles.whatHappensNext)
const ApplicationTypeHeader = styled('div')(styles.applicationTypeHeader)
const PersonalStatementHeader = styled('div')(styles.personalStatementHeader)
const ContentSection = styled('div')(styles.contentSection)
const PSContentSection = styled(ContentSection)(styles.psContentSection)
const SectionHeading = styled('p')(styles.sectionHeading)
const List = styled('dl')(styles.list)
const Label = styled('dt')(styles.label)
const Value = styled('dd')(styles.value)
const PolicyValue = styled(Value)(styles.policyValue)
const LinkContainer = styled('div')(styles.linkContainer)
const ApplicationType = styled('p')(styles.applicationType)
const TableHeader = styled('div')(styles.tableHeader)
const PoliciesEffectiveDateList = styled('ul')(styles.policiesEffectiveDateList)

const AltsSubmission = ({ fields }) => {
  const createQuote = useSelector(getCreateQuote)
  const policyOwners = useSelector(makePolicyOwner)
  const lifeInsured = useSelector(makeLifeInsured)
  const alteredPolicyList = useSelector(makePolicyList)
  const altsTypeLabel = useSelector(makeAltsTypeLabel)
  const isApplicationComplete = useSelector(makeIsApplicationComplete)
  const alterationType = useSelector(getAlterationType())
  const quotesData = useSelector(getDecreaseCoverPolicyDetails)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actionCreators.changeNavigationType(NAVIGATION_MAIN))
    dispatch(actionCreators.changeFooterType(FOOTER_MAIN))
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Submitted app page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} alteration Submitted app page load`,
        },
      },
    })
    tagEvent.end()
  }, [])

  const onClick = () => {
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Submitted app page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} Back to dashboard`,
        },
      },
    })
    tagEvent.end()
  }

  return (
    <PageWrapper>
      <HeadingWrap>
        <Heading variant="h2">
          <Text field={fields.QuoteDetailsHeading} />
        </Heading>
        <p>
          <Text field={fields.QuoteDetailsDesc} />
        </p>
      </HeadingWrap>
      <Wrap>
        <ApplicationDetails>
          <TableHeader>
            <ApplicationTypeHeader>
              <ApplicationType>{renderTextField(fields.AppType)}</ApplicationType>
              <SectionHeading>{altsTypeLabel}</SectionHeading>
            </ApplicationTypeHeader>
          </TableHeader>
          <ContentSection>
            <List>
              {policyOwners && <Label>{renderTextField(fields.PolicyOwner)}</Label>}
              {policyOwners && <Value>{policyOwners}</Value>}
              {lifeInsured && <Label>{renderTextField(fields.LifeInsured)}</Label>}
              {lifeInsured && <Value>{lifeInsured}</Value>}
              <Label>{renderTextField(fields.AppRef)}</Label>
              <Value>{createQuote.quoteCollectionId}</Value>
            </List>
            {!!alteredPolicyList.length &&
              (createQuote.alterationType === ALTERATION_TYPES.DECREASE_RISK ? (
                <PoliciesEffectiveDateList>
                  <li>
                    <div>{renderTextField(fields.Policies)}</div>
                    <div>{renderTextField(fields.AlterationSummaryEffectiveDateLabel, true)}</div>
                  </li>
                  {alteredPolicyList.map(policy => (
                    <li>
                      <div>{policy.policyNo}</div>
                      <div>
                        {policy.alteration?.policyAlterationEffectiveDate &&
                          moment(
                            policy.alteration?.policyAlterationEffectiveDate,
                            'YYYY-MM-DD'
                          ).format('DD MMM YYYY')}
                      </div>
                    </li>
                  ))}
                </PoliciesEffectiveDateList>
              ) : (
                <List>
                  <Label>{renderTextField(fields.Policies)}</Label>
                  <div>
                    {alteredPolicyList.map(policy => (
                      <PolicyValue>{policy.policyNo}</PolicyValue>
                    ))}
                  </div>
                </List>
              ))}
          </ContentSection>
        </ApplicationDetails>
        <PersonalStatement>
          <PersonalStatementHeader>
            <SectionHeading>{renderTextField(fields.PSActivity)}</SectionHeading>
          </PersonalStatementHeader>
          <PSContentSection>{renderTextField(fields.PSStatus, true)}</PSContentSection>
        </PersonalStatement>
      </Wrap>
      {alterationType === ALTERATION && (
        <CardWrap>
          <AltsDecreasePolicyCard
            isNewPremium={false}
            quotesData={quotesData}
            fields={fields}
            isConfirmationPage
          />
        </CardWrap>
      )}
      <WhatHappensNext>
        {renderTextField(
          isApplicationComplete ? fields.AppStatusCompletedMsg : fields.AppStatusMsg,
          true
        )}
      </WhatHappensNext>
      <LinkContainer>
        <Link variant="primary" to={DASHBOARD_ROUTE} onClick={onClick}>
          {renderTextField(fields.BackCTA)}
        </Link>
      </LinkContainer>
    </PageWrapper>
  )
}

AltsSubmission.propTypes = {
  // Sitecore authorable fields.
  fields: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default AltsSubmission
