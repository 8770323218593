import { createSelector } from 'reselect'
import moment from 'moment'

// selectors
import { getRulesForAlterationPolicies } from './altsDeclaration.selectors'
import {
  getAlterationPolicies,
  getIsAdviserPortal,
  getMasterData,
  getAlterations,
} from './common.selectors'

// constants
import { ALTERATION_TYPES } from '../constants/alterations'
import {
  PAYMENT_FREQUENCY_MULTIPLIER,
  REPORTING_PAYMENT_FREQUENCY_OPTIONS,
} from '../constants/forms'
import { ESCALATION_ANNUAL_FREQUENCY } from '../constants/benefit'

// types
import { PolicyEligiblityKey } from '../types/alterations'
import { Relationship } from '../types/ClientPolicies'

// utils
import {
  getPolicyTypeLabel,
  getConnectedBenefits,
  getBenefeciaryName,
  getPrimaryLifeInsured,
  // @ts-expect-error file not in typescript
} from '../utils/policyUtils'
import {
  pickVariantByRange,
  getAllLifeInsuredName,
  getEscalationDeadlineDaysForPolicy,
  getBenefitDetailsForPolicyCard,
  sortByDaysRemaining,
  sortPoliciesByPendingStatus,
} from '../utils/alterationPolicies'
// @ts-expect-error file not in typescript
import { policyInvalidMessageForUplift } from '../utils/alterationRules'
// @ts-expect-error file not in typescript
import { getRejectCPISavings, getLinkedPolicyList } from '../utils/alteration'
// @ts-expect-error file not in typescript
import { formatCurrency } from '../utils/quoteUtils'
import {
  getPolicyOwners,
  // @ts-expect-error file not in typescript
} from '../utils/relationshipUtils'
import { Fields } from '../types/components/AltsLandingPage'

export const getAlterationPoliciesByEligibilty = (alterationType: string, isEligible: boolean) =>
  createSelector(
    [getAlterationPolicies, getAlterations, getIsAdviserPortal],
    (alterationPolicies, { rules, policiesSubmitted = [] }, isAdviserPortal) => {
      const policiesListInRules = rules?.businessData?.policies || []
      return alterationPolicies.filter(({ bancsPolicyNo }: { bancsPolicyNo: string }) => {
        const matchingPolicyinAlterationRules = policiesListInRules.find(
          policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
        )
        let policyEligibilityKey: PolicyEligiblityKey
        switch (alterationType) {
          case ALTERATION_TYPES.DECREASE_RISK: {
            policyEligibilityKey = isAdviserPortal
              ? 'decrease_PolicyEligibleForAdviser'
              : 'decrease_PolicyEligibleForCustomer'
            break
          }
          default: {
            policyEligibilityKey = isAdviserPortal
              ? 'rejectCPI_PolicyEligibleForAdviser'
              : 'rejectCPI_PolicyEligibleForCustomer'
          }
        }
        const policyEligible = matchingPolicyinAlterationRules?.assesment?.[policyEligibilityKey]
        return isEligible
          ? policyEligible === 'Y' && !policiesSubmitted.includes(bancsPolicyNo)
          : policyEligible === 'N' ||
              (policyEligible === 'Y' && policiesSubmitted.includes(bancsPolicyNo))
      })
    }
  )

export const getAlterationPoliciesData = (
  alterationType: string,
  isEligible = true,
  fields: Fields
) =>
  createSelector(
    [getAlterationPoliciesByEligibilty(alterationType, isEligible), getAlterations, getMasterData],
    (policies, { rules, policiesSubmitted = [] }, masterData) =>
      policies
        .map(({ policy, bancsPolicyNo }) => {
          const policiesListInRules = rules?.businessData?.policies || []
          const matchingPolicyinAlterationRules = policiesListInRules.find(
            policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
          )
          const escalationDeadLine = getEscalationDeadlineDaysForPolicy(rules, bancsPolicyNo)
          return {
            bancsPolicyNo,
            policyNumber: policy.identifiers?.find(item => item.type === 'POLICY_ID')?.value,
            productType: getPolicyTypeLabel(policy.productClass),
            anniversary: moment(
              matchingPolicyinAlterationRules?.assesment.rejectCPI_EscalationDate,
              'DD/MM/YYYY'
            ),
            lifeInsured: getAllLifeInsuredName(policy.relationships),
            ...(isEligible && {
              daysRemaining: {
                variant: pickVariantByRange(escalationDeadLine),
                percentage: Math.ceil((escalationDeadLine / 105) * 100),
                label: escalationDeadLine,
              },
            }),
            ...(!isEligible && {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              reasonOptOut: policiesSubmitted.includes(bancsPolicyNo)
                ? fields?.RequestPending?.value
                : policyInvalidMessageForUplift(
                    rules?.businessData?.policies ?? [],
                    bancsPolicyNo,
                    (masterData?.alterationMessages ?? []).concat(
                      masterData?.inflationProofingCardMessages ?? []
                    )
                  ),
            }),
          }
        })
        // Sort by days remaining (least to most) with
        // fallback to anniversary date (earliest to latest)
        .sort(sortByDaysRemaining)
        // Don't re-sort eligible policies,
        // only ineligible by submitted status (submitted first)
        .sort(sortPoliciesByPendingStatus(isEligible ? [] : policiesSubmitted))
        .map(policyData => ({
          ...policyData,
          // Replace anniversary date with locale formatted date
          anniversary: policyData.anniversary.format('DD MMM YYYY'),
        }))
  )

export const getAlterationPoliciesCardsData = (alterationType: string) =>
  createSelector(
    [getAlterationPoliciesByEligibilty(alterationType, true), getAlterations],
    (policies, { rules, policySelectionMap }) => {
      let selectedPolicyPrimaryLifeInsured = ''
      let selectedPolicyEscalationDate = ''
      const selectedPolicy = Object.keys(policySelectionMap).find(
        objKey => policySelectionMap[objKey] === true
      )
      if (selectedPolicy) {
        const alterationPolicy = policies.find(
          altPolicy => altPolicy.bancsPolicyNo === selectedPolicy
        )
        selectedPolicyPrimaryLifeInsured = (
          getPrimaryLifeInsured(alterationPolicy?.policy.relationships) as Relationship[]
        )[0]?.bancsCustomerNo
        selectedPolicyEscalationDate = alterationPolicy?.escalation?.escalationDueDate ?? ''
      }

      return policies.map(policy => {
        const {
          policy: {
            identifiers,
            productClass,
            relationships = [],
            policyPremiumFrequency,
            paymentDetails,
            benefits = [],
          } = {},
          bancsPolicyNo,
          escalation,
        } = policy
        const policiesListInRules = rules?.businessData?.policies || []
        const matchingPolicyinAlterationRules = policiesListInRules.find(
          policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
        )
        const isSelected = (policySelectionMap && !!policySelectionMap[bancsPolicyNo]) || false
        const policyNumber = identifiers?.find(item => item.type === 'POLICY_ID')?.value || ''

        const policyDataWithEscalation = (escalation?.policyChangeDetails || []).find(
          item => item.changeType === 'Escalation'
        )
        const policyDataWithoutEscalation = (escalation?.policyChangeDetails || []).find(
          item => item.changeType === 'Re-Rate'
        )
        const collectionFrequency = paymentDetails?.collectionFrequency ?? policyPremiumFrequency
        const premiumSavings: number = getRejectCPISavings(policy) ?? 0
        return {
          policyNumber,
          bancsPolicyNo,
          productType: getPolicyTypeLabel(productClass),
          anniversary: moment(
            matchingPolicyinAlterationRules?.assesment.rejectCPI_EscalationDate,
            'DD/MM/YYYY'
          ).format('DD MMM YYYY'),
          lifeInsured: getAllLifeInsuredName(relationships),
          collectionFrequency:
            (
              REPORTING_PAYMENT_FREQUENCY_OPTIONS.find(
                frequency => frequency.value === collectionFrequency
              ) || {}
            ).label?.toLowerCase() ?? '',
          benefitDataWithEscalation: getBenefitDetailsForPolicyCard(
            benefits,
            policyDataWithEscalation?.revisedBenefitDetails
          ),
          policyPremiumWithEscalation: formatCurrency(policyDataWithEscalation?.newNetPremium),
          linkedInternalPolicyList: getLinkedPolicyList(policies, policy),
          linkedPolicies: [
            ...((getConnectedBenefits(policies, policy)?.connectedBenefitsInfo as string[]) || []),
          ].filter(i => !i.includes(policyNumber)),
          hasDifferentPrimaryLifeInsured:
            (selectedPolicyPrimaryLifeInsured &&
              selectedPolicyPrimaryLifeInsured !==
                (getPrimaryLifeInsured(relationships) as Relationship[])[0].bancsCustomerNo) ||
            false,
          hasDifferentAnniversaryDate:
            (selectedPolicyEscalationDate &&
              selectedPolicyEscalationDate !== policy?.escalation?.escalationDueDate) ||
            false,
          isSelected,
          ...(isSelected && {
            benefitDataWithoutEscalation: getBenefitDetailsForPolicyCard(
              benefits,
              policyDataWithoutEscalation?.revisedBenefitDetails
            ),
            policyPremiumWithoutEscalation: formatCurrency(
              policyDataWithoutEscalation?.newNetPremium
            ),
            hasPremiumIncreased: Boolean(premiumSavings < 0),
            savings: formatCurrency(Math.abs(premiumSavings)),
            ...(collectionFrequency !== ESCALATION_ANNUAL_FREQUENCY && {
              annualSavings: formatCurrency(
                Math.abs(premiumSavings) *
                  Number(
                    PAYMENT_FREQUENCY_MULTIPLIER.find(
                      frequency => frequency.value === collectionFrequency
                    )?.label
                  )
              ),
            }),
          }),
        }
      })
    }
  )

export const getIsSelectAllPoliciesAvailable = createSelector(
  [getRulesForAlterationPolicies],
  rules =>
    !(
      rules?.businessData?.assesment?.CP_multiplePrimaryLifeInsured === 'Y' ||
      rules?.businessData?.assesment?.rejectCPI_AnniversaryDateConflictForCustomer === 'Y'
    )
)

export const getSelectedPoliciesData = createSelector(
  [getAlterationPolicies, getAlterations],
  (alterationPolicies, { policySelectionMap }) => {
    // Show contact details of the selected policy parties
    const selectedPolicies = alterationPolicies.filter(
      ({ bancsPolicyNo }) => policySelectionMap[bancsPolicyNo] === true
    )
    return selectedPolicies.map(policy => {
      const { policy: { identifiers, relationships = [] } = {} } = policy
      const policyNumber = identifiers?.find(item => item.type === 'POLICY_ID')?.value || ''
      const allPolicyOwners = getPolicyOwners(policy.policy) as Array<{
        relationship: Relationship
        policyNumberList: string[]
      }>
      const policyOwnerNames = allPolicyOwners
        .map(({ relationship }) => `${getBenefeciaryName(relationship.relatedParty) as string}`)
        .join(', ')
      return {
        policyNumber,
        lifeInsured: getAllLifeInsuredName(relationships),
        policyOwners: policyOwnerNames,
      }
    })
  }
)

export const isEligiblePoliciesRemainingForSubmission = (alterationType: string) =>
  createSelector(
    [getAlterationPoliciesByEligibilty(alterationType, true), getAlterations],
    (eligiblePolicies, { policiesSubmitted, rules }) =>
      Boolean(
        rules?.businessData?.assesment?.CP_multiplePrimaryLifeInsured === 'Y' ||
          rules?.businessData?.assesment?.rejectCPI_AnniversaryDateConflictForCustomer === 'Y'
      ) && eligiblePolicies.some(({ bancsPolicyNo }) => !policiesSubmitted.includes(bancsPolicyNo))
  )
