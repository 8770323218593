import { createSelector } from 'reselect'
import get from 'lodash/get'

// FIXME: ts-expect-error comment can be removed when files are converted to typescript
// selectors
import { getClient, getPolicies, getMasterData, getConfig } from './common.selectors'

// utils
import { replaceLabelPlaceholders, reduceAuthorableFields } from '../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { getExtendedBenefitName, getConnectedBenefitData } from '../utils/benefitLinkageUtils'
// @ts-expect-error file not in typescript
import { getPolicyOwners, getUniquePolicyOwners } from '../utils/relationshipUtils'
// @ts-expect-error file not in typescript
import { browserOrigin } from '../utils/browserUtils'
// @ts-expect-error file not in typescript
import { getDocumentTypeDetails } from '../utils/fileUpload'
// @ts-expect-error file not in typescript
import { filterResults } from '../utils/serviceRequest'

// constants
import { getProductIdConfig } from '../constants/forms'

// types
import { SitecoreField } from '../types/sitecore'
import { fieldsType as AdvisorClientDetailsFields } from '../types/components/AdvisorClientDetails'
import { fieldsType as PersonalFields } from '../types/components/PersonalAndContactDetails'
import {
  benefit as benefitType,
  ClientPolicy,
  Relationship,
  ServiceHistory,
} from '../types/ClientPolicies'
import { Document as DocumentEntity } from '../types/Document'
import { DocumentType, PolicyActivity } from '../types/masterData'

type clientType = {
  details: { firstName: string; lastName: string; businessName: string }
  isLoading: boolean
}

type policiesType = {
  isLoading: boolean
  data: ClientPolicy[]
}

type dataArgType = {
  benefit: benefitType
  bancsPolicyNo: string
  benefitExtensionLabel: SitecoreField
}

type fileUploadType = {
  fields: AdvisorClientDetailsFields | PersonalFields
  type: string
  productId: string
}

type connectedBenefitDataArgType = {
  benefit: benefitType
  bancsPolicyNo: string
  connectedBenefitLabel: SitecoreField
}

export const getClientName = createSelector(getClient, (client: clientType) => {
  const {
    details: { firstName, lastName, businessName },
  } = client
  const individualName = firstName && lastName ? `${firstName} ${lastName}` : ''
  return businessName ? `${businessName}` : individualName
})

export const getPoliciesData = createSelector(getPolicies, (policies: policiesType) =>
  get(policies, 'data', []).filter(
    policy =>
      // As per "RET-22833" --- we can't show policies with no benefits or benefitstatusdate
      policy?.policy?.benefits &&
      policy?.policy?.benefits.every(benefit => !!benefit.benefitStatusDate)
  )
)

export const getIsClientDetailsLoading = createSelector(
  getClient,
  (client: clientType) => client.isLoading
)

export const getIsPoliciesLoading = createSelector(
  getPolicies,
  (policies: policiesType) => policies.isLoading
)

export const getExtendedBenefitLabel = createSelector(
  [getMasterData, (_, data: dataArgType) => data],
  (masterData, data) => {
    const { benefit, bancsPolicyNo, benefitExtensionLabel } = data
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const extendedBenefitName = getExtendedBenefitName(benefit, bancsPolicyNo, masterData)
    return (
      extendedBenefitName &&
      replaceLabelPlaceholders(benefitExtensionLabel, {
        benefitName: extendedBenefitName,
      })
    )
  }
)

export const makeConnectedBenefitData = createSelector(
  [getPoliciesData, getMasterData, (_, data: connectedBenefitDataArgType) => data],
  (policies, masterData, data) => {
    const { benefit, bancsPolicyNo, connectedBenefitLabel } = data
    const {
      benefitName,
      policyNumber,
      isPolicyOutOfForce,
      isClientNotPolicyOwnerOfPolicy,
      parentPolicyIndex,
    } =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      getConnectedBenefitData(benefit, bancsPolicyNo, masterData, policies) as {
        benefitName: string
        policyNumber: string
        isPolicyOutOfForce: boolean
        isClientNotPolicyOwnerOfPolicy: boolean
        parentPolicyIndex: number
      }
    return {
      connectedBenefitLabel:
        benefitName &&
        replaceLabelPlaceholders(connectedBenefitLabel, {
          benefitName,
          policyNumber,
        }),
      isConnectedBenefitLinkDisabled: isPolicyOutOfForce || isClientNotPolicyOwnerOfPolicy,
      parentPolicyIndex,
    }
  }
)

export const getAllOwnersInPolicies = createSelector(
  [getPoliciesData],
  (policies: ClientPolicy[]) => {
    const policyOwnerList = policies.reduce((accumulator: Relationship[], policyData) => {
      const policyOwners = getPolicyOwners(policyData.policy) as Relationship[]
      return [...accumulator, ...policyOwners]
    }, [])
    return getUniquePolicyOwners(policyOwnerList) as {
      relationship: Relationship
      policyNumberList: string[]
    }[]
  }
)

export const getFileUploadDetailsMeta = createSelector(
  [getMasterData, (_, data: fileUploadType) => data],
  (masterData, data) => {
    const { fields, type, productId } = data
    const {
      AddBeneficiaryHeading,
      AddBeneficiarySubHeading,
      EditPaymentDetailsHeading,
      EditPaymentDetailsSubHeading,
      EditPersonalDetailsHeading,
      EditPersonalDetailsSubHeading,
      EditPersonalDetailsFormName,
      ...otherFields
    } = reduceAuthorableFields(fields)
    let modalHeading = ''
    let modalSubHeading = ''
    switch (type) {
      case 'payment':
        modalHeading = EditPaymentDetailsHeading
        modalSubHeading = EditPaymentDetailsSubHeading
        break
      case 'beneficiary':
        modalHeading = AddBeneficiaryHeading
        modalSubHeading = AddBeneficiarySubHeading
        break
      case 'personal':
        modalHeading = EditPersonalDetailsHeading
        modalSubHeading = EditPersonalDetailsSubHeading
        break
      default:
    }
    const documentTypes = get(masterData, 'documentTypes', [])
    const formName = get(getProductIdConfig(productId), type, '') as string
    const documentEntity = get(
      getDocumentTypeDetails(
        type === 'personal' ? EditPersonalDetailsFormName : otherFields[formName],
        documentTypes
      ),
      '[0]',
      {}
    ) as DocumentType
    return {
      modalHeading,
      documentName: get(documentEntity, 'documentTypeName', ''),
      documentPath: {
        value: `<a href="${browserOrigin()}${get(
          documentEntity,
          'link',
          ''
        )}" target=_blank>Download</a>`,
      },
      docSubTypeCode: get(documentEntity, 'documentCode', ''),
      modalSubHeading,
      workType: get(documentEntity, 'workType', ''),
    } as DocumentEntity
  }
)

export type PolicyActivityDataType = {
  policyNumber: string
  status: string
  requestType: string
  createdDate: string
  creationDate: string
  closedDate: ''
}[]

export const getPolicyActivities = createSelector(
  [getMasterData, getConfig, (state, serviceHistory: ServiceHistory) => serviceHistory],
  (masterData, config, serviceHistory: ServiceHistory) => {
    const policyActivities = get(masterData, 'policyActivities', []) as PolicyActivity[]
    return serviceHistory?.transactions
      ? (filterResults(
          serviceHistory.transactions,
          policyActivities,
          config.SITE
        ) as PolicyActivityDataType)
      : []
  }
)
