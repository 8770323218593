import moment from 'moment'

// types
import { Policy } from '../types/ClientPolicies'

/**
 * Helper function which returns escalation due date
 * @param policy - policy data
 * @returns false when the escalation due date is before the last anniversary date.
 * Otherwise, it will return anniversary date before escalation due date in a "YYYY-MM-DD" format
 */
export const getEscalationDueDate = (
  policy: Pick<Policy, 'escalationDueDate' | 'lastAnniversaryDate' | 'nextAnniversaryDate'>
): string | boolean => {
  const escalationDueDate = moment(policy?.escalationDueDate, 'YYYY-MM-DD')
  const lastAnniversaryDate = moment(policy?.lastAnniversaryDate, 'YYYY-MM-DD')
  const nextAnniversaryDate = moment(policy?.nextAnniversaryDate, 'YYYY-MM-DD')
  if (
    escalationDueDate.isAfter(lastAnniversaryDate) &&
    escalationDueDate.isSameOrBefore(nextAnniversaryDate)
  ) {
    return lastAnniversaryDate.format('YYYY-MM-DD')
  }
  if (escalationDueDate.isAfter(nextAnniversaryDate)) {
    return nextAnniversaryDate.format('YYYY-MM-DD')
  }
  return false
}
