/* eslint-disable no-useless-escape */
// @flow
import moment from 'moment'
import get from 'lodash/get'
import { ALLOWED_DATE_FORMAT } from './quoteUtils'
import { creditTypesRegEx } from './creditTypeUtils'

// constants.
import { POLICY_NON_SUPER_BENEFIT_PREFIX_NEW } from '../constants/policies'

// regexs for condition checks.

const regexEmail = /^([a-zA-Z0-9_.-]+)@([\da-zA-Z0-9-]+)(\.([a-zA-Z0-9]{2,20}))*$/
const regexPhone = /^[+]{0,1}?(\d{1})?([\s-]*\d[\s-]*){3,13}\d{1}$/
const regexMobile = /(^[0]?4\d{2}\d{6}$)/
const regexAusLandline = /^\d{10}$/
const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/
const regexAlphaNumeric = /^\w+$/
const regexNumberWithTwoDecimal = /^\d+(\.\d{1,2})?$/
const regexAlphaNumericWithSpace = /^[a-z0-9A-Z]+([\s]{1}[a-z0-9A-Z]+)*$/
const regexAlphaNumericWithSpaceAndSpecialCharacters = /^[a-z0-9A-Z]+([\s]*['`-]*[a-z0-9A-Z]*)*$/
const regexAlphaNumericWithSpaceAndHash = /^[a-z0-9A-Z]+([\s]*[#]*[a-z0-9A-Z]*)*$/
const regexAlphaNumericWithSpecialCharacter = /^[a-z0-9A-Z]+([-]*[a-z0-9A-Z]*)*$/
const regexManualAddress = /^[a-z0-9A-Z]*([\s]*[.,\/'`-]*[a-z0-9A-Z]*)*[a-z0-9A-Z.,\/'`-]$/
const regexNumeric = /^\d*$/
const regexCvcNumber = /^[0-9]{3,4}$/
const regexCreditCard = /^[0-9]{1,19}$/g
const digit = /\d/
const regexPercentageRange = /\b^([0-9]|[1-8][0-9]|9[0-9]|100)\b/
const regexPostcode = regexAlphaNumeric
const regexTFN = /^\d{9}$/
// dd/mm/yyyy regex that also checks the right number of days per month (incl. leap years).
/* eslint-disable */
const regexDDMMYY =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{4})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/
/* eslint-enable */
const regexName = /^[a-zA-Z\s]+$/
const stripLeadingZeros = /\b0+(0\.\d+)/
const truthyRegex = /^true$/
const declaration = /\b(\w*yes\w*)\b/
const namesWithSpecialCharsRegex = /^[ ;/^\w/^\d!@#$%^&*(),.?":{}|<>'`=-]*$/
const regexAccountNumber = /^\w{0,9}$/
const regexAccountNumberWithNotAllZero = /^(?!0{1,}$)\w{0,}$/
const regexAccountName = /^[a-zA-Z0-9\s]{0,18}$/
const regexSB = /^([sS][bB]+)([\d0-9])*$/
const regexAccountNo = /^\d{1,8}$/ // Accepts only numeric values upto 8 values

// conditions.

export const manualAddressRegex = regexManualAddress
export const manualAddressPostalCode = regexAlphaNumeric
export const date = regexDDMMYY
export const email = regexEmail
export const regexEmailJsonSchema = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,20})+$/
export const phone = regexPhone
export const phoneInputs = regexNumeric
export const mobilePhone = regexMobile
export const ausLandline = regexAusLandline
export const required = ''
export const optional = 'OPTIONAL'
export const length = num => value => value.length >= num
export const specialCharacters = regexSpecialChar
export const alphaNumeric = regexAlphaNumeric
export const alphaNumericWithSpace = regexAlphaNumericWithSpace
export const quoteName = regexAlphaNumericWithSpaceAndHash
export const firstNameLastName = regexAlphaNumericWithSpaceAndSpecialCharacters
export const firstNameLastNameWithSpecialCharacter = regexAlphaNumericWithSpecialCharacter
export const numeric = regexNumeric
export const numberWithTwoDecimal = regexNumberWithTwoDecimal
export const creditCard = regexCreditCard
export const cvcNumber = regexCvcNumber
export const name = regexName
export const namesWithSpecialChars = namesWithSpecialCharsRegex
export const ausPostCode = regexPostcode
export const truthy = truthyRegex
export const declarationRegex = declaration
export const tfn = regexTFN
export const accountNo = regexAccountNumber
export const accountName = regexAccountName
export const accountNumber = regexAccountNo
export const accountNumberWithNotAllZero = regexAccountNumberWithNotAllZero
// Special condition that tell the validation to skip the validation
// so far it is used in the parent form field that having complex children values
export const skipValidation = 'SKIP_VALIDATION'

// test a given regex against a given value and return is it matches.
export const regexTest = (regex, value) => regex.test(value)

export const percentage = value => {
  let val = value
  // strip any leading zeros
  val = val.length > 1 ? String(val).replace(stripLeadingZeros, '') : val
  return !regexTest(regexPercentageRange, val)
}

export const datePast = value => {
  const currentDate = moment()
  const givenDate = moment(value, ALLOWED_DATE_FORMAT, true)
  return givenDate.diff(currentDate) > 0
}

export const validateFutureDate =
  (measurement: string = 'days', minDifference: number = 0): Function =>
  (value: string): boolean => {
    const currentDate = moment()
    const givenDate = moment(value, ALLOWED_DATE_FORMAT, true)

    return currentDate.diff(givenDate, measurement) > minDifference
  }

export const isCurrentDate = value => {
  const currentDate = moment()
  const givenDate = moment(value, ALLOWED_DATE_FORMAT, true)
  return givenDate.diff(currentDate, 'days') === 0
}

// used in form schemas for validation.
// check if the argument date string is following 'DD/MM/YYYY' format
// return true will trigger error message in the form
export const isNotFormattedDate = value => {
  let isNotValidDate = false
  if (value) isNotValidDate = !moment(value, ALLOWED_DATE_FORMAT, true).isValid()
  return isNotValidDate
}

// Check if value is a postcod if not empty
export const postcode = value => {
  if (value === '') return false
  return !regexTest(regexPostcode, value)
}

export const isSBCode = value => {
  if (value === '') return false
  return regexTest(regexSB, value)
}
// Check if value is a postcod if not empty
export const minLength = lengthValue => value => {
  if (value === '') return false
  return value.length < lengthValue
}

// Check if value is greater than given length
export const maxLength = lengthValue => value => {
  if (value === '') return false
  return value.length > lengthValue
}

// compare between old and new string
export const isEqualAsPrevious =
  (oldValue: string): Function =>
  (newValue: string): boolean => {
    const oldValueString = String(oldValue && oldValue.toLowerCase())
    const newValueString = String(newValue && newValue.toLowerCase())
    if (newValue === '') return false
    return oldValueString.valueOf() === newValueString.valueOf()
  }

/**
 * Checks and validate the field if exceeds the min value set
 * @param {min} number - minimum value to validate against
 */
export const belowMinimumCondition =
  (min: number): Function =>
  (numericValue: string): boolean => {
    if (typeof min !== 'number') {
      throw new Error(`${min} is not a valid value to validate against`)
    }
    return Number(numericValue) < min
  }

/**
 * Checks and validate the field if exceeds the min value set
 * @param {min} number - minimum value to validate against
 */
export const aboveMaximumCondition =
  (min: number): Function =>
  (numericValue: string): boolean => {
    if (typeof min !== 'number') {
      throw new Error(`${min} is not a valid value to validate against`)
    }
    return Number(numericValue) > min
  }

// returns true if derived age from supplied birthday falls within an age range.
// minimum age is only valid when age is at least `age in years + one day` (see tests).
export const calculateAgeNextBirthday = (
  min: number,
  max: number,
  dateBirthday: string,
  dateToday?: string
): boolean => {
  if (!dateBirthday) return false
  const minAge = min - 1 // subtracting 1 year from min to calculate next birthday

  if (minAge >= max) {
    console.error(`Invalid age range of minimum ${min} and maximum ${max}`)
    return false
  }
  const today = moment(dateToday || new Date())
  const birthday = moment(dateBirthday, 'DD/MM/YYYY')

  // get difference in years between today and birthday
  const years = today.diff(birthday, 'years')

  // add difference in years to birthday so we're left with remainder days
  birthday.add(years, 'years')

  // get days leftover
  const days = today.diff(birthday, 'days')

  // is the derived age at least min age + one day?
  const isMinAge = years > minAge || (years === minAge && days >= 0)

  return isMinAge && years < max
}

export const ageNextBirthday = (
  min: number,
  max: number,
  dateBirthday: string,
  dateToday?: string
): boolean => {
  if (!dateBirthday) return false
  if (min >= max) {
    console.error(`Invalid age range of minimum ${min} and maximum ${max}`)
    return false
  }
  const today = moment(dateToday || new Date())
  const birthday = moment(dateBirthday, 'DD/MM/YYYY')

  let ageNxtBday = today.diff(birthday, 'years')
  const monthDiff = today.diff(birthday, 'months') % 12

  // months passed from last birthday
  if (monthDiff >= 6) ageNxtBday += 1

  const isValidAge = min <= ageNxtBday && ageNxtBday <= max

  return isValidAge
}

// used in form schemas for validation.
// returns a "condition" function (specific to form validation) that inverts
// response of `ageIsBetween()` to help create appropriate error objects for
// form validation.
export const ageRangeCondition =
  (min: number, max: number): Function =>
  (dateValue: string): boolean =>
    !calculateAgeNextBirthday(min, max, dateValue)

// used in form schemas for validation.
// returns a "condition" function (specific to form validation) that inverts
// response of `ageNextBirthday()` to help create appropriate error objects for
// form validation.
export const nextBdayRangeCondition =
  (min: number, max: number): Function =>
  (dateValue: string): boolean =>
    !calculateAgeNextBirthday(min, max, dateValue)

// returns the value of a given value.
export const getValue = (val: { value: mixed } | string): mixed =>
  !Array.isArray(val) && typeof val === 'object' && val !== null ? val.value : val

// use to remove the comma from number
// eslint-disable-next-line no-useless-escape
export const removeComma = value => value.replace(/\,/g, '')

// make Pluralize word
export const pluralize = (count, word, suffix = 's') =>
  `${count} ${word}${count !== 1 ? suffix : ''}`

// serialize form state values and return.
// returns null if no values exist.
export const getSerializedFormState = (
  values: { [string]: { value: string } },
  withoutError? = false
): {} | null => {
  if (typeof values === 'undefined') {
    return null
  }

  const data = {}
  Object.keys(values).forEach(key => {
    if (!withoutError || (withoutError && !get(values[key], 'error.error'))) {
      data[key] = getValue(values[key].value)
    }
  })
  return data
}

// checks if option condition was given and if value is empty.
const optionalCheck = (value, conditions) =>
  (typeof conditions === 'string' || Array.isArray(conditions)) &&
  conditions.indexOf(optional) > -1 &&
  value === ''

const errorObject = (error, errorMsg) => ({ error, errorMsg })

export const handleNumberOfLines = (text, maxLengthValue) => {
  if (text && text.length > maxLengthValue) {
    return `${text.substr(0, maxLengthValue)}...`
  }
  return text
}

// checks a given value meets a supplied condition and returns an error object.
export const checkCondition = (value, condition, errorMsg, values) => {
  if (condition instanceof RegExp) {
    return errorObject(!regexTest(condition, getValue(value)), errorMsg)
  }
  if (typeof condition === 'object') {
    return checkCondition(value, condition.condition, condition.errorMsg || errorMsg, values)
  }
  if (typeof condition === 'function') {
    return errorObject(condition(getValue(value), values), errorMsg)
  }
  return errorObject(getValue(value) === condition, errorMsg)
}

// return true - if String Contains Number
export const isStringContainsNumber = (str: string) => digit.test(str)

// checks a given value meets a singular/multiple conditions.
export const errorCheck = (value, conditions, errorMsg, values = {}, currentError = false) => {
  // If skipped
  if (conditions === skipValidation) return currentError
  if (optionalCheck(value, conditions)) return false
  if (Array.isArray(conditions)) {
    let hasError = false
    conditions.forEach(condition => {
      const check = checkCondition(value, condition, errorMsg, values)
      if (check.error && !hasError) hasError = check
    })
    return hasError
  }
  return checkCondition(value, conditions, errorMsg, values)
}

// check all conditions in a given schema against the supplied form values and returns if the form
// is valid.
export const validateAll = (schema, values = {}) => {
  let isValid = true
  Object.keys(schema).forEach(key => {
    const { value, error } = values[key] || {}
    const { condition } = schema[key] || {}
    const checkedResult = errorCheck(value, condition, null, values, error)
    // checkedResult could be true | false | error object with error property
    if (checkedResult === true || checkedResult.error) {
      isValid = false
    }
  })
  return isValid
}
// Checks one of the fields has value and returns the form is valid
export const validateOneOf = fields => {
  let isValid = false
  isValid = Object.keys(fields)
    .reduce((acc, curr) => acc.concat(fields[curr].value), [])
    .some(val => val)
  return isValid
}

// Run all condition checks in given schema against the given form
// state and return the updated form state.
export const runAllValidations = (schema, formState) => {
  let newState = formState
  Object.keys(schema).forEach(key => {
    const error = errorCheck(
      newState.fields[key].value,
      schema[key].condition,
      schema[key].errorMsg,
      newState.fields,
      newState.fields[key].error
    )
    newState = {
      fields: {
        ...newState.fields,
        [key]: {
          ...newState.fields[key],
          error,
        },
      },
    }
  })
  return newState
}

// makes a label out of given string. strips non-alphanumeric character, spaces
// and replaces with dashes.
export const idMaker = (str: string) => {
  if (typeof str !== 'string' || str === '' || str.trim() === '') {
    throw new Error(`Cannot create id from ${str}`)
  }
  return `${str.toLowerCase().replace(/[\W_]+/g, '-')}`
}

// applies a given suffix to a given string.
export const suffix = (str: string, suf: string) => `${str}${suf ? '-' : ''}${suf}`

// returns a string suffxed with label.
export const labelMaker = (str: string) => suffix(idMaker(str), 'label')

// return the selected value from Array. e.g. can get selected option in dropdown
export const getSelectedValue = targetArray =>
  targetArray.find(element => element.selected === true) || false

// generates form field collection from a flat object of key/value pairs
type GeneratedFields = {
  [string]: {
    value: any,
    error: false,
  },
}
export const generateFieldsFromData = (data: { [string]: any }): GeneratedFields =>
  Object.keys(data).reduce(
    (fields, nameVal) => ({
      ...fields,
      [nameVal]: {
        value: data[nameVal] !== null ? data[nameVal] : '',
        error: false,
      },
    }),
    {}
  )

// takes `YYYY-MM-DD` and returns formatted value for dob form field
export const formDateFormat = (dateString: string) =>
  moment(dateString, ALLOWED_DATE_FORMAT, true).format('DD/MM/YYYY')

// adds comma seprator in thousands
export const numberWithCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const DOLLAR = '$'
export const dollarAmountWithCommasAndTwoDecimal = (value: string | number, hide = false) => {
  const amountWithTwoDecimal = parseFloat(value || 0).toFixed(2)
  return !hide || numberWithCommas(amountWithTwoDecimal) !== '0.00'
    ? `${DOLLAR}${numberWithCommas(amountWithTwoDecimal)}`
    : '---'
}

// handle scroll to top of the page
export const scrollToTop = (distanceFromTop: number) =>
  window.scroll({
    top: distanceFromTop,
    left: 0,
    behavior: 'smooth',
  })

export const validateCreditCardNumber = value => {
  if (value.length >= 14 && value.length <= 16) {
    return false
  }
  return true
}

export const validateMasterOrVisaNumber = value => {
  // if the number matches "master" or "visa", then return false, to hide the error message in input
  if (creditTypesRegEx.masterCard.test(value) || creditTypesRegEx.visa.test(value)) {
    return false
  }
  // if the number not match with the "master" or "visa",
  // then return true, to display an error msz in input
  return true
}

export const checkMinLength = (str: string, count: number = 9) => str.length >= count

export const checkMinLowerCase = (str: string, numberOfLowerCaseChar: number = 1) => {
  const matchResult = str.match(/[a-z]/g)
  return matchResult ? matchResult.length >= numberOfLowerCaseChar : false
}

export const checkMinUpperCase = (str: string, numberOfUpperCaseChar: number = 1) => {
  const matchResult = str.match(/[A-Z]/g)
  return matchResult ? matchResult.length >= numberOfUpperCaseChar : false
}

export const checkMinNumber = (str: string, minCount: number = 1) => {
  const matchResult = str.match(/[0-9]/g)
  return matchResult ? matchResult.length >= minCount : false
}

export const checkMinSymbol = (str: string, minCount: number = 1) => {
  // eslint-disable-next-line no-useless-escape
  const matchResult = str.match(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)
  return matchResult ? matchResult.length >= minCount : false
}

export const checkPasswordNotContainsUsername = (
  password: string,
  excludeUsername: boolean,
  username: string
) => {
  if (!password) {
    return false
  }
  if (!excludeUsername) {
    return true
  }
  return password.indexOf(username) === -1
}

export const getFieldValueFromForm = (fieldName: string, form: Object) => {
  const value = form && form.fields && form.fields[fieldName].value
  return getValue(value)
}

export const buildQuickQuoteBody = chooseCoverFormFields => {
  const coverCodeMap = {
    LC: 'LC',
    PTD: 'TPD',
    'non-LC': 'LC',
    'non-PTD': 'TPD',
    'non-CI_STD': 'CI',
  }
  const cover = Object.keys(chooseCoverFormFields).reduce(
    (accum, currProp) => {
      if (coverCodeMap[currProp]) {
        // use index to seperate situation either to update super or nonSuper
        const isUpdateSuper = Object.keys(coverCodeMap).indexOf(currProp) < 2
        const filedToUpdate = isUpdateSuper ? 'super' : 'nonSuper'
        return {
          ...accum,
          [filedToUpdate]: {
            ...accum[filedToUpdate],
            [coverCodeMap[currProp]]: !!chooseCoverFormFields[currProp].value,
          },
        }
      }
      return accum
    },
    {
      super: {
        LC: false,
        TPD: false,
      },
      nonSuper: {
        LC: false,
        TPD: false,
        CI: false,
      },
    }
  )
  return {
    quote: {
      cover,
    },
  }
}

export const buildQuickQuoteSumInsuredBody = quickQuoteSumInsuredFormFields => {
  const coverCodeMapping = {
    life_cover: 'LC',
    total_and_permanent_disability: 'TPD',
    non_life_cover: 'LC',
    non_total_and_permanent_disability: 'TPD',
    non_critical_illness_plus: 'CI',
  }
  const filteredFormFields = { ...quickQuoteSumInsuredFormFields }
  Object.keys(filteredFormFields).forEach(field => {
    // eslint-disable-next-line no-prototype-builtins
    if (!filteredFormFields[field].hasOwnProperty('value')) {
      delete filteredFormFields[field]
    }
  })
  const cover = Object.keys(filteredFormFields).reduce((accum, currProp) => {
    // use index to seperate situation either to update super or nonSuper
    const isUpdateNonSuper = currProp.includes(POLICY_NON_SUPER_BENEFIT_PREFIX_NEW)
    const filedToUpdate = isUpdateNonSuper ? 'nonSuper' : 'super'
    return {
      ...accum,
      [filedToUpdate]: {
        ...accum[filedToUpdate],
        [coverCodeMapping[currProp]]: +filteredFormFields[currProp].value,
      },
    }
  }, {})
  return {
    quote: {
      cover,
    },
  }
}

export const buildSupportStaffOnboardPayload = (supportStaffRegisterFormFields, otherInfo) => {
  const { countryCodeList } = otherInfo
  // if supportStaffRegisterFormFields is an array which means it's for bulk upload
  if (Array.isArray(supportStaffRegisterFormFields)) {
    const parties = supportStaffRegisterFormFields.map(singleSupportStaff => {
      const { firstName, lastName, dateOfBirth, countryCode, mobileNumber, emailAddress } =
        Object.keys(singleSupportStaff).reduce((accum, curr) => {
          accum[curr] = singleSupportStaff[curr].value
          return accum
        }, {})

      return {
        firstName,
        lastName,
        dob: dateOfBirth.split('/').reverse().join('-'),
        phone: {
          mobile: (countryCode === '61' && !mobileNumber.startsWith('0') ? '0' : '') + mobileNumber,
          countryCode: countryCodeList.find(
            countryCodeInfo => countryCodeInfo.value === countryCode
          ).countryCode,
          isc: countryCode,
        },
        email: emailAddress,
      }
    })

    return {
      parties,
    }
  }

  const {
    firstName,
    lastName,
    dateOfBirth,
    email: supportStaffEmail,
    contactNumber,
    contactNumberPhoneCode,
    loginId,
  } = Object.keys(supportStaffRegisterFormFields).reduce((accum, curr) => {
    accum[curr] = supportStaffRegisterFormFields[curr].value
    return accum
  }, {})
  return {
    party: {
      firstName,
      lastName,
      dob: dateOfBirth.split('/').reverse().join('-'),
      phone: {
        mobile:
          (contactNumberPhoneCode.value === '61' && !contactNumber.startsWith('0') ? '0' : '') +
          contactNumber,
        countryCode: contactNumberPhoneCode.countryCode,
        isc: contactNumberPhoneCode.value,
      },
      email: supportStaffEmail,
      supportstaffLogin: loginId,
    },
  }
}
