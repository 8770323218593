import { colours, space, fontSize, fontWeights } from '../../../styles'

const styles = {
  base: type => {
    let background = 'none'
    let color = 'inherit'
    const paddingLeft = type === 'gridlayout' ? 5 : 1
    const paddingRight = type === 'gridlayout' ? 5 : 1
    const fontScale = type === 'gridlayout' ? fontSize.xs : fontSize.xxs

    if (type === 'primary') {
      background = colours.lightGreen
      color = colours.offWhite
    } else if (type === 'danger') {
      background = colours.lightRed
      color = colours.red
    } else if (type === 'removed') {
      background = colours.darkRed
      color = colours.offWhite
    } else if (type === 'warning') {
      background = colours.lightOrange
      color = colours.offWhite
    } else if (type === 'success') {
      background = colours.lightGreen
      color = colours.black
    } else if (type === 'successlimeshade') {
      background = colours.limeGreenShade
      color = colours.offWhite
    } else if (type === 'suspend') {
      background = colours.darkBlue
      color = colours.offWhite
    } else if (type === 'gridlayout') {
      background = colours.lightGreen
      color = colours.offWhite
    }

    return {
      paddingLeft: space(paddingLeft, true),
      paddingRight: space(paddingRight, true),
      display: 'inline-block',
      borderRadius: '2px',
      fontSize: fontScale,
      fontWeight: fontWeights.semiBold,
      verticalAlign: 'top',
      background,
      color,
      wordBreak: 'normal',
    }
  },
}

export default styles
