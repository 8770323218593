import { useEffect, useRef } from 'react'

/**
 * Accepts a function that contains imperative, possibly effectful code.
 * This composed hook will only run on the second update, effectively
 * skipping the `componentDidMount` render.
 *
 * @param effect Imperative function that can return a cleanup function
 * @param deps If present, effect will only activate if the values in the list change.
 *
 * @see https://reactjs.org/docs/hooks-reference.html#useeffect
 */
const useEffectSkipMount: typeof useEffect = (effect, deps) => {
  const isMount = useRef(true)
  useEffect(() => {
    if (isMount.current) {
      // Skip effect callback on first render
      isMount.current = false
      return undefined
    }
    return effect()
  }, deps)
}

export default useEffectSkipMount
