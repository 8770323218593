// @flow
import React from 'react'

// components.
import H from '../../../atoms/Heading'

type HeadingProps = {
  style?: Object,
  params?: Object,
  fields: Object,
}

const Heading = (props: HeadingProps) => {
  const { params, fields } = props
  const properties = {
    ...(params && params.size && { size: params.size }),
    dangerouslySetInnerHTML: {
      __html: fields && fields.text && fields.text.value,
    },
  }
  return <H {...properties} />
}

Heading.defaultProps = {
  style: {},
  params: {
    size: '1',
  },
}

export default Heading
