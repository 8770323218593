import React from 'react'
import get from 'lodash/get'
import styled from '@emotion/styled'
import Container from '@mlcl-digital/mlcl-design/lib/base/Container'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'
import { Heading } from '@mlcl-digital/mlcl-design'

// utils
import { renderTextField } from '../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { getClassnameProp } from '../../../../utils/stylesUtils'

// types
import { propTypes } from '../../../../types/components/PersonalAndContactDetails'

// styles
import styles from '../personalAndContactDetails.styles'

const SectionHeading = styled(Heading)(styles.sectionHeading)
const Policy = styled('p')(styles.policy)
const StyledLabelValuePair = styled(LabelValuePair)(styles.labelValuePair)

const InternalSuperOwnerDetails = (props: propTypes) => {
  const { fields, policyNumberList, data } = props
  const policies = policyNumberList?.join(', ')
  const businessName = get(data, 'relatedParty.businessName', '') as string
  return (
    <Container {...getClassnameProp(props)}>
      {policies && (
        <Policy>
          {renderTextField(fields.Policy)} {policies}
        </Policy>
      )}
      <SectionHeading variant="h2" size="small">
        {renderTextField(fields.InternalSuperOwnerTitle)}
      </SectionHeading>
      <GridContainer>
        <StyledLabelValuePair
          label={renderTextField(fields.InternalSuperTrusteeName)}
          value={businessName}
        />
      </GridContainer>
    </Container>
  )
}

export default InternalSuperOwnerDetails
