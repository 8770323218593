import type { CSSObject } from '@emotion/styled'
import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { colours, mediaQueries, space } = Variables as VariablesType

const styles: Record<string, CSSObject> = {
  wrapper: {
    color: colours.tertiaryOne,
    marginTop: space(3),
  },
  sectionHeading: {
    marginBottom: space(5),
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(1),
    columnGap: space(3),
    flexWrap: 'wrap',
    button: {
      flexGrow: 1,
    },
    [mediaQueries.sm]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      button: {
        flexGrow: 0,
      },
    },
  },
  buttonContainer: {
    marginTop: space(4),
    marginBottom: space(4),
    display: 'flex',
    rowGap: space(1),
    columnGap: space(2.5),
    flexWrap: 'wrap',
    button: {
      flexGrow: 1,
    },
    [mediaQueries.sm]: {
      justifyContent: 'flex-start',
      button: {
        flexGrow: 0,
      },
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(5),
    alignItems: 'flex-start',
    [mediaQueries.md]: {
      maxWidth: '750px',
    },
  },
}

export default styles
