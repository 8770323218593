import { Variables } from '@mlcl-digital/mlcl-design'
import type { CSSObject } from '@emotion/styled'
import { VariablesType } from '../../../types/ComponentLibrary'

const { space, mediaQueries } = Variables as VariablesType

const styles: Record<string, CSSObject> = {
  container: {
    paddingTop: space(5),
    paddingBottom: space(9),
    [mediaQueries.md]: {
      paddingBottom: space(18),
    },
  },
  headingWrap: {
    marginBottom: space(5),
    h2: {
      marginBottom: space(1),
    },
  },
  linkContainer: {
    marginTop: space(5),
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(2),
    columnGap: space(5),
    '> a': {
      lineHeight: '20px',
    },
    [mediaQueries.md]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  policiesContainer: {
    width: '100%',
    [mediaQueries.md]: {
      maxWidth: '720px',
    },
  },
}

export default styles
