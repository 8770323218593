import { useEffect, useRef } from 'react'

/**
 * An simple React hook which updates every render to return
 * the value used in the previous render, regardless of if the
 * value has changed or not.
 *
 * @param value the current value, and will be returned next render
 * @returns the value passed into the hook the previous render
 */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePrevious
