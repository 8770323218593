import { createSelector } from 'reselect'
import get from 'lodash/get'

// Constants
import { THEME_LIGHT, THEME_DARK } from '../constants/themes'
import { ADVISOR_PORTAL, CUSTOMER_PORTAL, PAYMENTS_PORTAL } from '../constants/site'

// Types
import { Store } from '../types/store'
import { ProductRules } from '../types/productRules'
import { Relationship } from '../types/ClientPolicies'

// Utils
// @ts-expect-error file not in typescript
import { getPrimaryLifeInsured } from '../utils/policyUtils'
import {
  HEALTHY_LIVING_DISCOUNT,
  POLICY_NON_SUPER_BENEFIT_PREFIX,
  POLICY_PRODUCT_CODE_SUPER,
} from '../constants/policies'

export const getCreateQuote = (state: Store) => state.createQuote

export const getMasterData = (state: Store) => (state.masterList && state.masterList.data) || {}

export const getAlterations = (state: Store) => state.alterations

export const getConfig = (state: Store) => state.config

export const getIsAdviserPortal = createSelector(
  [getConfig],
  config => config && config.SITE === ADVISOR_PORTAL
)

export const getPolicyStructure = createSelector(
  [getCreateQuote],
  createQuote => createQuote?.quotes[createQuote.activeIndex].policyStructure
)

export const getSite = (state: Store) => state.config.SITE

export const getForm = createSelector(
  [(state: Store) => state.forms, (state, formID: string) => formID],
  (forms, formID) => forms[formID]
)

// Selector to get a theme based on the site (AP/CP)
export const getTheme = createSelector([getSite], site => {
  let theme = THEME_DARK
  if (site === CUSTOMER_PORTAL || site === PAYMENTS_PORTAL) {
    theme = THEME_LIGHT
  }
  return theme
})

export const getAlterationPolicies = createSelector(
  [getAlterations],
  alterations => alterations?.policies || []
)

export const makeAltsLifeInsuredNameAndPartyNo = createSelector(
  [getAlterationPolicies],
  alterationPolicies => {
    // Find the relationship in any of the policy.
    // Assumption: LA record in all policies are the same.
    const relationships = get(
      alterationPolicies,
      ['0', 'policy', 'relationships'],
      []
    ) as Relationship[]
    // Derive the below from the LA relationship in policy
    const lifeInsured = get(getPrimaryLifeInsured(relationships), ['0'], {}) as Relationship
    const firstName = lifeInsured?.relatedParty?.firstName || ''
    const lastName = lifeInsured?.relatedParty?.lastName || ''

    // Get party no within relatedParty.identifiers[type=PARTY_NO].value
    const identifiers = lifeInsured?.relatedParty?.identifiers || []
    const partyNo = get(
      identifiers.find((identifier: { type: string }) => identifier.type === 'PARTY_NO'),
      'value',
      ''
    )

    return {
      firstName,
      lastName,
      partyNo,
    }
  }
)

export const getFeatureControlSwitch = createSelector([getMasterData], masterData =>
  get(masterData, 'featureControlSwitch', [])
)

export const getActiveQuote = createSelector(
  [getCreateQuote],
  createQuote => createQuote.quotes[createQuote.activeIndex]
)

export const getProductRules = (state: Store) => state.productRules
export const getProductRuleData = (productRules: ProductRules) =>
  (productRules && productRules.data) || []

export const getHealthyEligibleCovers = createSelector([getProductRuleData], productRule => {
  let isHealthyLivingAllowed
  const EligibleHealthyLivingCovers: Array<string> = []
  productRule.forEach(element => {
    if (element.benefits && Array.isArray(element.benefits)) {
      element.benefits.forEach(benefit => {
        isHealthyLivingAllowed = false
        if (benefit.allowableCampaigns)
          isHealthyLivingAllowed = benefit.allowableCampaigns.find(
            (ele: { campaignCode: string }) => ele.campaignCode === HEALTHY_LIVING_DISCOUNT
          )
        if (isHealthyLivingAllowed) {
          // create benfitName as per form fields for nonSuperCover adding prefix
          const benefitName =
            element.productId === POLICY_PRODUCT_CODE_SUPER
              ? benefit.benefitCode
              : `${POLICY_NON_SUPER_BENEFIT_PREFIX}${benefit.benefitCode}`
          EligibleHealthyLivingCovers.push(benefitName)
        }
      })
    }
  })
  return EligibleHealthyLivingCovers
})

export const getIsHealthyLivingDisount = createSelector([getCreateQuote], createQuote => {
  const { quotes, activeIndex } = createQuote
  return quotes[activeIndex]?.option?.healthyLivingDiscount || false
})
export const getSessionStateMsg = createSelector([getMasterData], masterData =>
  get(masterData, 'sessionStateMessages', [])
)

export const getMyLink = (state: Store) => state.myLink || {}
export const getAuthentication = (state: Store) => state.authentication

export const getMyLinkAuthorised = createSelector([getMyLink], myLink => myLink.hasAuthorised)
export const getAuthenticated = createSelector(
  [getAuthentication],
  authentication => authentication.authenticated
)

export const getClient = (state: Store) => state.client
export const getPolicies = (state: Store) => state.policies
export const getTimeline = (state: Store) => state.timelineWithComponents
export const getTrusteeReplacement = (state: Store) => state.trusteeReplacement
