import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { mediaQueries, colours, space, mediaQueriesMax, fontSize } = Variables as VariablesType
const styles = {
  base: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  backgroundDetail: ({ theme }: { theme: { bg: string } }) => ({
    position: 'absolute' as const,
    bottom: 0 as const,
    zIndex: 0 as const,
    width: '100%',
    height: '100%',
    background: colours.tertiarySix,
    [mediaQueries.md]: {
      background: `linear-gradient(transparent 60%, ${theme.bg} 40%)`,
    },
  }),
  cardBase: {
    position: 'relative',
    zIndex: 1,
    maxWidth: space(73.5),
    margin: '0 auto',
    [mediaQueriesMax.md]: {
      border: 'none',
    },
  },
  header: {
    padding: space(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    [mediaQueriesMax.md]: {
      borderBottom: 'none',
    },
    '& a ': {
      maxWidth: space(8.75),
    },
  },
  footer: {
    textAlign: 'center',
    flex: 1,
    width: '100%',
    padding: space(2),
    borderTop: 'none',
    color: colours.tertiaryOne,
    [mediaQueriesMax.md]: {
      background: 'none',
    },
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    h2: {
      textAlign: 'center',
      marginBottom: `${space(1, true)}`,
    },
    [mediaQueries.md]: {
      padding: `${space(3, true)} ${space(10, true)}`,
    },
    '& > div': {
      width: '100%',
    },
    '& > div > span': {
      fontSize: '10px',
    },
  },
  headerOuterContent: {
    minWidth: '20%',
    '& + a': { maxWidth: 'none' },
  },
  loginWrapper: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: fontSize.xs,
    '& a': {
      textDecoration: 'none',
      display: 'inline',
      padding: 0,
      color: colours.mlcAquaDark,
      marginLeft: space(1),
    },
  },
  descriptionWrapper: {
    textAlign: 'left' as const,
    marginBottom: space(1.5),
    color: colours.tertiaryOne,
    fontSize: fontSize.xsm,
  },
  nav: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: fontSize.xs,
    padding: '17px',
    height: 'auto',
    svg: {
      marginRight: space(0.5),
      color: 'inherit',
    },
  },
}
export default styles
