import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'

// components
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
import List from '@mlcl-digital/mlcl-design/lib/base/List'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import { Heading } from '@mlcl-digital/mlcl-design'
// @ts-expect-error file not in typescript
import WithLoader from '../../../molecules/WithLoader'
import SectionWrapper from '../SectionWrapper'
import SidePanel from '../SidePanel'
import Policy from './Policy'

// types
import { PremiumCalculatorQuotePreviewProps } from '../../../../types/components/PremiumCalculator'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// constants
import { DOC_TYPE_ALTERATION_PC } from '../../../../constants/documentTypes'
import { MAX_LIMIT_ERROR_CODE } from '../../../../constants/premiumCalculator'

// utils
import { renderTextField } from '../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { downloadDocument } from '../../../../utils/downloadDocumentUtils'
// @ts-expect-error file not in typescript
import { generateCorrelationID } from '../../../../utils/commonUtils'
import { createEvent } from '../../../../utils/telemetry'

// selectors
// @ts-expect-error file not in typescript
import { getIsAnyPolicyAltered } from '../../../../selectors/createQuote'
import { getPremiumCalculatorQuotePreviewData } from '../../../../selectors/premiumCalculatorQuotePreview'
import {
  getConfig,
  getCreateQuote,
  makeAltsLifeInsuredNameAndPartyNo,
} from '../../../../selectors/common.selectors'
import { isErrorOnAlteredQuote } from '../../../../selectors/premiumCalculatorQuote'

// styles
import styles from './premiumCalculatorQuotePreview.styles'
import { Store } from '../../../../types/store'

const Wrapper = styled('div')(styles.wrapper)
const PanelHeading = styled('div')(styles.panelHeading)
const DetailsSection = styled('div')(styles.detailsSection)
const ButtonContainer = styled('div')(styles.buttonContainer)
const SidePanelContent = styled('div')(styles.sidePanelContent)

const PremiumCalculatorQuotePreview = ({ fields }: PremiumCalculatorQuotePreviewProps) => {
  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'PC-PreviewQuoteload',
        action: 'Summary page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator summary page load',
        },
      },
    })
    event.end()
  }, [])
  const isAnyPolicyAltered = useSelector(getIsAnyPolicyAltered)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const { activeIndex, quotes, quoteCustomerNo } = useSelector(getCreateQuote)
  const premiumCalculatorQuotePreviewData = useSelector((state: Store) =>
    getPremiumCalculatorQuotePreviewData(state, fields)
  )
  const config = useSelector(getConfig)
  const { isFetchingCalculateQuote, isAltsQuoteDownloaded, quoteURN } = useSelector(
    (state: Store) => state.alterations
  )
  const isErrorOnQuote = useSelector(isErrorOnAlteredQuote)

  const dispatch = useDispatch()

  const handleDownloadQuote = () => {
    const event = createEvent({
      GA: {
        category: 'PC-PQDownloadQuote',
        action: 'Download quote - summary screen',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator summary screen download quote clicked',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.updateDownloadQuoteProgress(true))
    const correlationID = generateCorrelationID()
    if (!quoteURN) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.setAltsQuoteURN(correlationID))
    }
    downloadDocument(
      {
        docType: DOC_TYPE_ALTERATION_PC,
        quote: quotes[activeIndex],
        correlationID,
        lifeInsuredFirstName: firstName,
        lifeInsuredLastName: lastName,
        isUploadToRecm: !isAltsQuoteDownloaded,
        returnData: true,
        quoteCustomerNo,
        quoteURN: quoteURN || correlationID,
      },
      null,
      null,
      config,
      (err: { status: { code: string } }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(actionCreators.updateDownloadQuoteProgress(false))
        if (!err) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(actionCreators.setIsAltsQuoteDowloaded())
        } else if (err.status?.code === MAX_LIMIT_ERROR_CODE) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(actionCreators.setIsMaxDownloadLimitModal(true))
        }
        const apiEvent = createEvent({
          Splunk: {
            attributes: {
              'workflow.name': 'Premium calculator summary screen downloading quote',
              error: !!err,
            },
          },
        })
        apiEvent.end()
      }
    )
  }

  const handleContinue = () => {
    const event = createEvent({
      GA: {
        category: 'PC-PQContinue',
        action: 'Continue',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator summary screen continue',
        },
      },
    })
    event.end()
    dispatch(actionCreators.timelineNextState())
  }

  const handleBack = () => {
    const event = createEvent({
      GA: {
        category: 'PC-PQBack',
        action: 'Back - summary screen',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator summary screen back',
        },
      },
    })
    event.end()
    dispatch(actionCreators.timelinePrevState())
  }

  return (
    <WithLoader
      isLoading={isFetchingCalculateQuote}
      overlay
      loaderProps={{ loaderContent: fields.LoaderLabel?.value }}
    >
      <Wrapper>
        <p>{renderTextField(fields.Description, true)}</p>
      </Wrapper>
      <SectionWrapper>
        <div>
          {premiumCalculatorQuotePreviewData.map(policyPreview => (
            <Policy fields={fields} policyPreview={policyPreview} />
          ))}
          <ButtonContainer>
            <Button variant="secondaryWithTheme" onClick={handleBack}>
              {renderTextField(fields.BackButton)}
            </Button>
            <Button
              onClick={handleDownloadQuote}
              variant="secondaryWithTheme"
              disabled={!isAnyPolicyAltered || isErrorOnQuote}
            >
              <Icon iconName={['far', 'download']} />
              {renderTextField(fields.DownloadQuote)}
            </Button>
            <Button onClick={handleContinue} disabled={!isAnyPolicyAltered || isErrorOnQuote}>
              {renderTextField(fields.ContinueButton)}
            </Button>
          </ButtonContainer>
        </div>
        <SidePanel heading={fields?.ImportantConsiderations?.value} fields={fields}>
          <PanelHeading>{renderTextField(fields.PaymentFrequency)}</PanelHeading>
          <DetailsSection>
            <SidePanelContent>
              <Heading variant="h6" size="xsmall">
                {renderTextField(fields.ThingsConsiderSubHeading)}
              </Heading>
              <List
                variant="bullet"
                listItems={fields?.ThingsConsiderSteps?.map(listItem =>
                  renderTextField(listItem?.fields?.Description?.value, true)
                )}
              />
            </SidePanelContent>
          </DetailsSection>
        </SidePanel>
      </SectionWrapper>
    </WithLoader>
  )
}

export default PremiumCalculatorQuotePreview
