// @flow
import get from 'lodash/get'
// utils
import { getToken } from './cookieUtils'
import { MASK_PREFIX } from '../constants/policies'
import {
  PENDING,
  PROCESSING,
  COMPLETE,
  SAVE_QUOTE_COLLECTION,
  CONVERT_TO_DRAFT_APPLICATION,
  SAVE_DRAFT_APPLICATION,
  REGISTER_MY_LINK,
  WIP_MYLINK,
  COMPLETE_MYLINK,
  TELE,
  COMPLETE_TELE,
  COMPLETE_APPLICATION,
  APPLICATION_STAGE_NTU,
  APPLICATION_STAGE_DECLINED,
  APPLICATION_STAGE_POLICY_ISSUED,
  ACTION_TYPES,
  APPLICATION_STAGES,
  MINIMUM_BMI_THRESHOLD,
  MAXIMUM_BMI_THRESHOLD,
} from '../constants/application'
import { LOADING_TYPE_EMR, LOADING_TYPE_RPM } from '../constants/benefit'

import { WHITELISTED_URL, WHITELISTED_SESSION_TIMEOUT_URL } from '../constants/login'
import { LOGIN_ROUTE } from '../constants/routes'
import {
  DATA_FEED_VERSION_CHECK,
  DATA_FEED_DEFAULT_VERSION,
} from '../constants/dataFeedRegistration'
import { AMPERSAND_CHAR_REPLACEMENT } from '../constants/occupationListApi'

// return key with underscore and remove whitespace, special chars
export const convertStringToKey = (string: string): string =>
  string.toLowerCase().replace(/[^a-zA-Z0-9]+/gi, '_')

// check if a string value exists and that it is not an empty string.
export const checkNotNullOrEmptyString = (value?: string): boolean =>
  !(value === null || value === '')

// replace space to dash and remove special character and numbers
// in a string and convert it to lowercase
export const trimStringWithDash = (value: string): string =>
  value
    .replace(/[^a-zA-Z- ]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()

// remove dots from file name except keeping the dot before extension
export const removeDotsFromFileName = (value: string): string => value.replace(/\.(?=.*\.)/g, '')

// compares object|array deeply as string comparison
export const deepEqual = (itemA: Object | Array, itemB: Object | Array): boolean =>
  JSON.stringify(itemA) === JSON.stringify(itemB)

// JSON ESCAP
export const jsonEscape = policyStructureData => {
  const stringifyData = JSON.stringify(policyStructureData)
  return stringifyData
    .replace(/\\n/g, '\\n')
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f')
}

// convert an array of object into an object with property as the keyField value
export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
  }, {})

export const nonce = length => {
  let last = null
  let repeat = 0
  let newLength = length
  if (typeof newLength === 'undefined') newLength = 15
  return function returnnonce() {
    const now = 10 ** 2 * +new Date()

    if (now === last) {
      repeat += 1
    } else {
      repeat = 0
      last = now
    }

    const s = (now + repeat).toString()
    return +s.substr(s.length - newLength)
  }
}
export const generateCorrelationID = () => new Date().valueOf().toString()

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    /* eslint no-bitwise: ["error", { "int32Hint": true }] */
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const isLoggedIn = (authentication, okta) =>
  getToken() && authentication.authenticated && okta.token !== '' && okta.expiresIn !== Date.now()

export const getPasswordInputType = field => (field && field.isText ? 'text' : 'password')
export const getMaskedCardNumber = lastFourDigits =>
  lastFourDigits ? `${MASK_PREFIX} ${lastFourDigits}` : ''

export const getProcessingStatusFromAction = (action: string, applicationStage: string) => {
  switch (applicationStage) {
    case APPLICATION_STAGE_NTU:
    case APPLICATION_STAGE_DECLINED:
    case APPLICATION_STAGE_POLICY_ISSUED:
      return COMPLETE
    default:
  }
  switch (action) {
    case SAVE_QUOTE_COLLECTION:
      return PENDING

    case CONVERT_TO_DRAFT_APPLICATION:
      return PENDING

    case SAVE_DRAFT_APPLICATION:
      return PENDING

    case REGISTER_MY_LINK:
      return PENDING

    case WIP_MYLINK:
      return PENDING

    case COMPLETE_MYLINK:
      return PROCESSING

    case TELE:
      return PENDING

    case COMPLETE_TELE:
      return PROCESSING

    case COMPLETE_APPLICATION:
      return PROCESSING

    default:
      return PENDING
  }
}

/**
 * Decision tree to return application status based on action and applicationStage.
 * @param {*} action
 * @param {*} applicationStage
 * @returns status (pending/processing/complete)
 */
export const getAlterationApplicationStatus = (action: string, applicationStage: string) => {
  switch (action) {
    case ACTION_TYPES.SAVE_DIGITAL_ALTERATION:
    case ACTION_TYPES.SAVE_QUOTE_COLLECTION:
    case ACTION_TYPES.CONVERT_TO_DRAFT_APPLICATION:
      return PENDING
    case ACTION_TYPES.COMPLETE_APPLICATION:
      return [
        APPLICATION_STAGES.NOT_TAKEN_UP,
        APPLICATION_STAGES.DECLINED,
        APPLICATION_STAGES.POLICY_ISSUED,
      ].includes(applicationStage)
        ? COMPLETE
        : PROCESSING
    default:
      return PENDING
  }
}

export const transformToSelectOptions = (options: Array<Object>): Array<Object> =>
  options.map((option: Object) => ({
    ...option,
    label: option.value,
    value: option.code,
  }))

export const getBenefitLoadingsDescription = ({
  loadingValue,
  loadingTypeCode,
  reason: reasons,
}) => ({
  value:
    loadingTypeCode === LOADING_TYPE_EMR.code
      ? `${loadingValue}${LOADING_TYPE_EMR.symbol}`
      : `${LOADING_TYPE_RPM.symbol}${loadingValue} ${LOADING_TYPE_RPM.unit}`,
  reasonDescription: (reasons || []).map(({ reasonDescription }) => reasonDescription).join(', '),
})

export const camelCase = (str: string) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '')

export const setErrorFocus = () => {
  setTimeout(() => {
    let inputField = document.querySelector('.errorField')
    if (inputField && inputField.tagName !== 'INPUT') {
      inputField = inputField.querySelector('Input')
    }
    if (inputField) {
      inputField.focus()
    }
  }, 0)
}
export const isWhiteListedURL = route =>
  WHITELISTED_URL.filter(url => route.includes(url) && route !== LOGIN_ROUTE)

export const isSessionTimeoutDisabled = route =>
  route === '' ||
  route === LOGIN_ROUTE ||
  Boolean(WHITELISTED_SESSION_TIMEOUT_URL.filter(url => route.includes(url)).length)

export const addOrRemoveElement = (array = [], element) => {
  const index = array.indexOf(element)
  if (index === -1) {
    array.push(element)
  } else {
    array.splice(index, 1)
  }
  return array
}

export const removeIndex = str => {
  const index = str.lastIndexOf('-')
  return index > -1 ? str.slice(0, index) : str
}

export const isFeatureEnabledForAP = (featureControlSwitch: Array<Object>, key: String) => {
  const renderFeature = featureControlSwitch.find(feature => feature.key === key)
  return get(renderFeature, 'apValue', true)
}

export const isFeatureEnabledForCP = (featureControlSwitch: Array<Object>, key: String) => {
  const renderFeature = featureControlSwitch.find(feature => feature.key === key)
  return get(renderFeature, 'cpValue', true)
}

export const getAPEnabledFeatures = (
  featureControlSwitch: Array<Object>,
  keys: String[],
  featuresEnabledForAdvisor?: String[] = []
) => {
  const featureObject = {}
  if (keys && featureControlSwitch) {
    featureControlSwitch.forEach(feature => {
      if (keys.includes(feature.key)) {
        featureObject[feature.key] =
          feature.apValue || featuresEnabledForAdvisor.includes(feature.key)
      }
    })
  }
  return featureObject
}

export const isKeyAvailable = (array, key, defaultValue) =>
  array && array.find(element => element.code === key)
    ? array.find(element => element.code === key).value
    : defaultValue

export const getDropdownValue = (value: string, options: Array<Object>) =>
  options.find(option => option.value === value)

export const getVersionFromUrl = (url: String) => {
  const isVersion = url && url.includes(DATA_FEED_VERSION_CHECK)
  if (isVersion) {
    const version = url.split(DATA_FEED_VERSION_CHECK)
    if (version.length > 0) {
      return version[1]
    }
  }
  return DATA_FEED_DEFAULT_VERSION
}

export const isContainsNumber = (val: string) => /\d+/g.test(val)

export const shouldRenderNativeLink = (href: string) =>
  /^https?:\/\/|^\/\/|^www\./.test(href) ||
  href.match('^#') ||
  href.match('^tel') ||
  href.match('^mailto') ||
  href.match('^callto')

// Make country dropdown options from countryCodeList in masterList
export const makeCountryOptions = (countryCodeList: Array<Object>): Array<Object> =>
  countryCodeList.map(countryObj => ({
    label: countryObj.country,
    value: countryObj.countryCode,
  }))

export const getIsUREHealthyLivingDiscount = ure => {
  const bmi = get(ure, 'fullUREresult.bmi', '')
  return bmi >= MINIMUM_BMI_THRESHOLD && bmi <= MAXIMUM_BMI_THRESHOLD
}

export const islegacySystemProductCodeGreatorThanFive = legacySystemProductCode =>
  +(legacySystemProductCode?.split(' ')[1]?.match(/\d+/)?.[0] ?? 0) >= 6

export const allOccuranceReplace = (str: string, obj: { [keyToReplace: string]: string }) => {
  let replacedStr = str
  Object.keys(obj).forEach(key => {
    replacedStr = replacedStr?.replaceAll(key, obj?.[key])
  })
  return replacedStr
}

export const replaceAmbersandSymbol = (urlString: string) =>
  urlString.replace('%26', AMPERSAND_CHAR_REPLACEMENT)
