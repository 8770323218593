import React from 'react'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'

// utils
import { renderTextField } from '../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { getPersonalDetailsData } from '../../../../utils/relationshipUtils'

// types
import { propTypes } from '../../../../types/components/PersonalAndContactDetails'

const Person = ({ fields, data }: propTypes) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { fullName, gender, dateOfBirth } = getPersonalDetailsData(data?.relatedParty) as {
    fullName: string
    gender: string
    dateOfBirth: string
  }
  return (
    <>
      <GridContainer>
        <LabelValuePair label={renderTextField(fields.FullName)} value={fullName} />
        <LabelValuePair label={renderTextField(fields.Gender)} value={gender} />
        <LabelValuePair label={renderTextField(fields.DateOfBirth)} value={dateOfBirth} />
      </GridContainer>
    </>
  )
}

export default Person
