// @flow
import moment from 'moment'
import deepmerge from 'deepmerge'
import pathOr from 'lodash/fp/pathOr'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

import {
  ADD_TFN,
  ADD_NULIS,
  ADD_OWNER_FOR_NULIS_WRAPPER,
  CREATE_QUOTE_UPDATE_BENEFICIARIES,
  CREATE_QUOTE_ADD_BENEFIT_TO_POLICY,
  CREATE_QUOTE_ADD_POLICY,
  CREATE_QUOTE_ADD_POLICY_OWNER,
  CREATE_QUOTE_UPDATE_POLICY_OWNER,
  CREATE_QUOTE_CALCULATE_ERROR,
  CREATE_QUOTE_CALCULATE_FAILURE,
  CREATE_QUOTE_CALCULATE_SUCCESS,
  CREATE_QUOTE_CALCULATION_IN_PROGRESS,
  CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_TRACK,
  CREATE_QUOTE_POLICY_WILL_UPDATE,
  CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_POLICY_FEE,
  CREATE_QUOTE_SET_FEATURES,
  CREATE_QUOTE_SET_FIXED_PAYMENT_FREQUENCY,
  CREATE_QUOTE_SET_POLICY_STRUCTURE,
  CREATE_QUOTE_UPDATE_COVERS,
  CREATE_QUOTE_SET_QUOTE_COLLECTION_NAME,
  CREATE_QUOTE_SET_QUOTE_NAME,
  RESET_TOTAL_PREMIUM_AMOUNT,
  UPDATE_IS_TERM_ACCEPTED_FIELD,
  CREATE_QUOTE_UPDATE_BENEFIT_LINK,
  CREATE_QUOTE_UPDATE_CHILD_DATA,
  CREATE_QUOTE_UPDATE_COVER,
  CREATE_QUOTE_UPDATE_MEMBER_MANDATORIES,
  CREATE_QUOTE_UPDATE_PAYMENT_DETAILS,
  CREATE_QUOTE_UPDATE_PAYMENT_FREQUENCY,
  CREATE_QUOTE_UPDATE_LEGACY_CODE,
  CREATE_QUOTE_UPDATE_SUM_INSURED,
  CREATE_QUOTE_UPDATE_MEMBER_DETAILS,
  CREATE_QUOTE_GET_CALCULATION_IN_PROGRESS,
  CREATE_QUOTE_GET_CALCULATE_ERROR,
  CREATE_QUOTE_GET_CALCULATE_FAILURE,
  CREATE_QUOTE_CALCULATE_QUOTE_ERROR_MODAL,
  CREATE_QUOTE_GET_CALCULATE_SUCCESS,
  CREATE_QUOTE_REMOVE_BENEFIT,
  CREATE_QUOTE_DUPLICATE_TEMPLATE,
  CREATE_QUOTE_DELETE_FROM_COLLECTION,
  CREATE_QUOTE_UPDATE_SECONDARY_MAIL,
  UPDATE_QUOTE_ACTIVE_INDEX,
  CREATE_QUOTE_UPDATE_IS_SAVE,
  REMOVE_ACTION_AGREEMENT_CONSENTS,
  UPDATE_QUOTE_ENTITY_MY_LINK_DETAILS,
  UPDATE_ADVISER_VERIFICATION_CONSENTS,
  RESET_UPDATE_QUOTE_COLLECTION_SUCCESS_FLAG,
  UPDATE_QUOTE_COLLECTION_SUCCESS,
  UPDATE_QUOTE_COLLECTION_ERROR,
  UPDATE_QUOTE_COLLECTION_FAILURE,
  DELETE_QUOTE_COLLECTION_SUCCESS,
  DELETE_QUOTE_COLLECTION_INIT,
  DELETE_QUOTE_COLLECTION_ERROR,
  DELETE_QUOTE_COLLECTION_FAILURE,
  CREATE_QUOTE_SHOW_SAVE_MODAL,
  UPDATE_BANCS_ADVISER_CUSTOMER_NO,
  IS_UPDATED_QUOTE,
  UPDATE_QUOTE_STATUS,
  UPDATE_QUOTE_ID_TO_ACTION,
  CREATE_QUOTE_UPDATE_QUOTE_COLLECTION,
  CREATE_QUOTE_SAVE,
  CREATE_QUOTE_SAVE_SUCCESS,
  CREATE_QUOTE_UPDATE_ADVISER_AUTHORITY_CONSENTS,
  CREATE_QUOTE_SAVE_FAILURE,
  CREATE_QUOTE_SAVE_ERROR,
  CREATE_QUOTE_SAVE_INIT,
  CREATE_QUOTE_SAVE_QUOTE_ERROR_MODAL,
  CREATE_QUOTE_SAVE_AND_EXIT_MODAL,
  CREATE_QUOTE_CONVERT_COVERS_FEATURES,
  CREATE_QUOTE_UPDATE_DUTY_OF_DISCLOSURE_CONSENTS,
  CREATE_QUOTE_UPDATE_UNDERWRITING_OPTIONS_CONSENTS,
  CREATE_QUOTE_UPDATE_DECLARATION_AUTHORIZATION_CONSENTS,
  CREATE_QUOTE_UPDATE_DECLARATION_PO_CONSENTS,
  CREATE_QUOTE_UPDATE_DECLARATION_FOR_ADVISOR_CONSENTS,
  RESET_QUOTE_DECLARATION_CONSENTS,
  CREATE_QUOTE_UPDATE_MARKETING_CONSENTS,
  CREATE_QUOTE_UPDATE_UNDERWRITING_FOLLOW_UP_TIMING,
  CREATE_QUOTE_UPDATE_MLC_ON_TRACK_CONSENTS,
  CREATE_QUOTE_UPDATE_TMD_CONSENTS,
  FETCH_QUOTE_COLLECTION_INIT,
  FETCH_QUOTE_COLLECTION_SUCCESS,
  FETCH_QUOTE_COLLECTION_ERROR,
  FETCH_QUOTE_COLLECTION_FAILURE,
  CREATE_QUOTE_DELETE_POLICY,
  CREATE_QUOTE_DELETE_MEMBER_DETAILS,
  CREATE_QUOTE_ADD_PAYMENT_DETAILS,
  CREATE_QUOTE_UPDATE_ROLE_OF_MEMBER,
  CREATE_QUOTE_UPDATE_PRINTABLE_ADVISER_DETAILS,
  CREATE_QUOTE_UPDATE_EXISTING_COVER,
  CREATE_QUOTE_UPDATE_APPLICATION_TYPE,
  CREATE_QUOTE_RESET_QUOTE_ID,
  CREATE_QUOTE_UPDATE_QUOTE_DATA,
  UPDATE_ENQUIRY_ID_URE,
  CREATE_ENQUIRY_ID_SUCCESS,
  CREATE_CHILD_ENQUIRY_ID_SUCCESS,
  REMOVE_ENQUIRY_ID_URE,
  SET_SAVE_QUOTE_INITIATOR,
  CREATE_QUOTE_UPDATE_POLICY_STRUCTURE,
  RESET_CREATE_QUOTE,
  CREATE_QUOTE_APPLY_LOADINGS,
  CREATE_QUOTE_UPDATE_FEATURE_STATUS,
  REMOVE_PARENT_BENEFIT_REFERENCE,
  CREATE_QUOTE_REMOVE_APPLIED_LOADINGS,
  CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_SUCCESS,
  CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_FAILURE,
  CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_ERROR,
  CREATE_QUOTE_APPLY_EXCLUSIONS,
  CREATE_QUOTE_REMOVE_APPLIED_EXCLUSION,
  CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS,
  CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_SUCCESS,
  CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_ERROR,
  CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_FAILURE,
  CREATE_QUOTE_APPLY_GENETIC_TEST_RESULT,
  ADD_BUSINESS_AS_PAYER,
  UPDATE_POLICY_COMMENCEMENT_DATE,
  CREATE_QUOTE_CHANGE_SMOKER_STATUS,
  UPDATE_URE_DETAIL_TO_UNDERWRITING_ENTITY,
  UPDATE_WORKTYPE_HISTORY,
  DELETE_WORKTYPE_HISTORY,
  UPDATE_IS_TELE_FROM_ADVISER,
  CREATE_QUOTE_UPDATE_POLICY_NAME,
  QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_SUCCESS,
  QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_FAILURE,
  QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_ERROR,
  CREATE_QUOTE_UPDATE_SMSF_DETAILS,
  CREATE_QUOTE_UPDATE_CALCULATED_URE_STATUS,
  RESET_CREATE_AND_SAVE_QUOTE_ERROR_MODAL,
  CREATE_QUOTE_UPDATE_TELE_CUSTOMER_TERM_CONSENTS,
  UPDATE_UNDER_WRITING_DETAILS,
  UPDATE_APPLICATION_STATUS,
  UPDATE_POLICY_REPLACE_STATUS,
  CREATE_QUOTE_PUSH_EXISTING_COVER,
  CREATE_QUOTE_UPDATE_TPD_DEFINATION,
  CREATE_QUOTE_UPDATE_IS_NON_STANDARD,
  CREATE_QUOTE_UPDATE_BMI,
  CREATE_QUOTE_SET_IS_SATISFIED,
  UPDATE_IS_TERMS_ACCEPTED_ON_POLICY_COVER,
  CREATE_QUOTE_UPDATE_LA_CONTACT_DETAILS,
  CREATE_QUOTE_ADD_OPTIMISER_REFERENCE,
  CREATE_QUOTE_REMOVE_OPTIMISER_REFERENCE,
  CREATE_QUOTE_REMOVE_EMPTY_POLICY,
  CREATE_QUOTE_UPDATE_LINKED_OPTIMISER,
  CREATE_QUOTE_UPDATE_LINKED_BENEFITS,
  CHECK_QUOTE_ACTION_STATUS_INIT,
  CHECK_QUOTE_ACTION_STATUS_SUCCESS,
  CHECK_QUOTE_ACTION_STATUS_FAILURE,
  CHECK_QUOTE_ACTION_STATUS_ERROR,
  QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_INIT,
  CREATE_QUOTE_CLEAR_POLICY_SETUP_DATA,
  UPDATE_SUBMITTED_DATE,
  UPDATE_MEMBER_MANDATORIES,
  CREATE_QUOTE_SET_REQUESTED_BY,
  CREATE_QUOTE_SET_ALTERATION_TYPE,
  CREATE_QUOTE_SET_QUOTE_COLLECTION_ID,
  CREATE_QUOTE_SET_ADVISER_NUMBER,
  CREATE_QUOTE_SET_APPLICATION_DETAILS,
  CREATE_QUOTE_SET_QUOTE_CUSTOMER_NO,
  UPDATE_CONTACT_METHODS_OF_MEMBER,
  CREATE_QUOTE_ADD_FEATURE_AS_SUB_BENEFIT,
  CREATE_QUOTE_DELETE_FEATURE_AS_SUB_BENEFIT,
  ALTER_SUM_INSURED,
  ALTS_CALCULATE_QUOTE_SUCCESS,
  RESET_SUM_INSURED,
  RESET_WAITING_BENEFIT_PERIOD,
  DELETE_ALTERATIONS_IN_QUOTE,
  CHANGE_WAITING_BENEFIT_PERIOD,
  REMOVE_BENEFIT_OPTION,
  CANCEL_BENEFIT_OPTION,
  ALTS_REMOVE_BENEFIT,
  ALTER_PAYMENT_FREQUENCY,
  RESET_PAYMENT_FREQUENCY,
  ALTS_CALCULATE_QUOTE_FAILURE,
  ALTS_CALCULATE_QUOTE_ERROR,
  CREATE_QUOTE_SET_ALTERATION_EFFECTIVE_DATE,
  CREATE_QUOTE_UPDATE_OPTION,
  CREATE_QUOTE_UPDATE_HL_CAMPAIGN,
  CREATE_QOUTE_SET_HEALTHY_LIVING_DISCOUNT_CONSENT,
  CREATE_QUOTE_ADD_HEALTHY_LIVING_DISCOUNT_IN_MISSING_REQUIREMENTS,
  UPDATE_ALTS_DOWNLOAD_QUOTE_PROGRESS,
  RESET_STATE_FOR_NEW_QUOTE,
  CREATE_QOUTE_SET_ADVISER_PRESENTED_LICOP_FLYER_CONSENT,
  UPDATE_ALTS_EFFECTIVE_DATES,
  UPDATE_MEDICAL_AUTHORITY_CONSENTS,
  UPDATE_DETAILS_FOR_CONTACT,
  CREATE_QUOTE_QUICK_QUOTE_SUCCESS,
} from '../actions/types/createQuote'

import { GET_PAYLOAD_OF_FULL_URE_SUCCESS } from '../actions/types/ure'

import { ADVISOR_DETAILS_FETCH_SUCCESS } from '../actions/types/advisor'
import {
  IP_COVER_DIGITAL_KEY_AIP,
  IP_COVER_DIGITAL_KEY_IP,
  GENETIC_TEST_KEY,
  DEFAULT_APP_OUTCOME,
} from '../constants/ure'
import { OVERSEAS_STATE } from '../constants/contactDetails'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'
// TODO:: Check why this is removed? import { OKTA_SIGNOUT } from '../actions/types/okta'

// constants.
import { DEFAULT_QUOTE_COLLECTION_NAME } from '../constants/forms'
import {
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_FIXED_PAYMENT_FREQUENCY_VALUE,
  SUPER_OWNER_INTERNAL,
  SUPER_PRODUCT_CLASS,
  EXTERNAL_SUPER_PRODUCT_CLASS,
  QUOTE_STATUS_QUOTE_STAGE,
  SAVE_QUOTE_COLLECTION,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_RELATIONSHIPS_MEMBER,
  POLICY_FEE_TYPE,
  POLICY_RELATIONSHIPS_PAYER,
  SUPER_OWNER_EXTERNAL,
  SUPER_OWNER_IOOF,
  WRAPPER_NAVIGATOR_ID,
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_MEMBER_DIRECTOR,
  POLICY_PARTY_TYPE_INDIVIDUAL,
  PREFERRED_YES,
  COUNTRY_CODE,
  ADDRESS_TYPE_STATEMENT,
  POLICY_BENEFIT_LC,
  POLICY_BENEFIT_TISO,
  POLICY_BENEFIT_PREMIUM_WAIVER,
  POLICY_BENEFIT_CI_PLUS,
  POLICY_BENEFIT_PTD,
  POLICY_BENEFIT_CI_STD,
  POLICY_FEATURE_LCBB,
  POLICY_FEATURE_DBB,
  POLICY_FEE_YES,
  POLICY_FEE_NO,
  POLICY_RELATIONSHIPS_EMPLOYER,
  INCOME_PROTECTION_PLATINUM_ID,
  POLICY_FEATURE_BO,
  POLICY_FEATURE_EBO,
  POLICY_BENEFIT_SDTRO,
  HEALTHY_LIVING_DISCOUNT,
  POLICY_PRODUCT_CODE_NON_SUPER,
  POLICY_NON_SUPER_BENEFIT_PREFIX,
  HEALTHY_LIVING_DISCOUNT_TEXT,
  HEALTHY_LIVING_DISCOUNT_MISSING_REQUIREMENT,
} from '../constants/policies'
import {
  MLC_ON_TRACK_DECLINED,
  BENEFIT_REPLACE,
  CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS,
  REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD,
  TYPE_OF_ADVICE_PERSONAL_OR_GENERAL,
} from '../constants/personalStatement'
import {
  ACCEPTED,
  HEALTHY_LIVING_DISCOUNT_CONSENT,
  SHARE_PERS_STMT_WITH_ADVISOR,
  ADVISER_PRESENTED_LICOP_FLYER,
  SHARE_AUTH1_WITH_GP,
  SHARE_AUTH2_WITH_GP,
} from '../constants/application'

// utils.
import {
  removeBenefit,
  updateQuoteBasedOnDependentOn,
  childDataToBenefitAssured,
  replacePolicyOwner,
  updatePolicyInstanceNo,
  updateOccupationalRatingInCovers,
  getLinks,
  postProcessInitialPolicyStructure,
  isOwnTPDDisabled,
  isParentInstanceSame,
  isIPCover,
  constructRelationshipsFromPolicy,
} from '../utils/quoteUtils'

import {
  buildQuoteAfterSave,
  makeFeatures,
  generateThirdPartyPolicyStructure,
  getParentBenefitReferenceForCover,
  getNextQuoteNumber,
  getSubBenefitFromProducts,
  isBoosterOptionIPAvailable,
  isEmployee,
  checkIfFeatureEnabled,
  isSGBOBenefit,
  getOptimisedChildBenefit,
  getSGBOBenefitCode,
} from '../utils/extendedQuoteUtils'
import { getBancsCustomerNumber, getAdviserNo } from '../utils/cookieUtils'

import { getExistingBenefitsFromPolicies, getUpdatedExistingCovers } from '../utils/policyUtils'
import { isFeatureEnabledForAP } from '../utils/commonUtils'
import {
  updateSumInsuredInAlteredBenefits,
  resetSumInsuredInPolicy,
  removeBenefitOptionForPolicy,
  cancelBenefitOptionForPolicy,
  removeBenefitInAlteredBenefits,
  changeWaitingPeriodForPolicy,
  resetWaitingBenefitPeriod,
  getIsAlterationInPolicies,
  removeAlterationsInPolicyStructure,
  getPolicyAlterationEffectiveDate,
} from '../utils/alteration'

import { getLinkedBenefitForSumInsured } from '../utils/altsQuoteUtils'

// eslint-disable-next-line max-len
import { INIT_STATE } from '../actions/types/navigation'
import { REF_NO } from '../constants/adviser'
import {
  BENEFIT_NATURE_TYPE_STANDALONE,
  BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
} from '../constants/benefit'

import { POLICIES_FOR_LA_FETCH_SUCCESS } from '../actions/types/policies'
import { SET_LIFE_ASSURED_NAME } from '../actions/types/lifeAssured'

export type occupationCodeType = {
  benefit: Array<string>,
  code: Array<string>,
}

export type memberMandatoriesType = {
  isSmoker: string,
  dateOfBirth: string,
  gender: string,
  stateOfResidence: string,
  occupation: string,
  occupationalRating: string,
  occupationCode: string,
  occupationClassCode: Array<occupationCodeType>,
  earning: string,
  earningExcludingSuper: string,
  employmentStatus: string,
}

export type consentCollectionType = {
  name: string,
  value: string,
}

export type quoteCollectionType = {
  memberMandatories: memberMandatoriesType,
  policyStructure: Array<Object>,
  consents: Array<consentCollectionType>,
}

export type createQuoteType = {
  activeIndex: number,
  isRecalculating: boolean,
  quoteCollectionId: string,
  quoteCollectionName: string,
  action: string,
  quotes: Array<quoteCollectionType>,
  adviserNumber: string,
  bancsAdviserCustomerNo: string,
}

export const initialQuoteState: quoteCollectionType = {
  memberMandatories: {
    isSmoker: 'Unknown',
    dateOfBirth: '',
    gender: 'Unknown',
    stateOfResidence: '',
    occupationalRating: '',
    occupationClassCode: [
      {
        benefit: [],
        code: [],
      },
    ],
    occupation: '',
    occupationCode: '',
    earning: '',
    earningExcludingSuper: '',
    employmentStatus: '',
  },
  alternateEmailDetails: {
    firstName: '',
    email: '',
  },
  policyStructure: [],
  myLinkDetails: {
    email: '',
    phone: '',
  },
  quoteName: '',
  quoteId: '',
  consents: [],
  type: QUOTE_STATUS_QUOTE_STAGE,
  printableAdviserDetails: {},
  applicationDetails: {
    overallApplicationOutcome: DEFAULT_APP_OUTCOME,
    status: [
      {
        quotePolicyNo: '',
        missingRequirements: [],
      },
    ],
  },
  option: {
    healthyLivingDiscount: false,
  },
}

export const initialState: createQuoteType = {
  activeIndex: 0,
  discountValue: 0,
  isRecalculating: false,
  hasCalculationError: false,
  quoteCollectionId: '',
  quoteCollectionName: DEFAULT_QUOTE_COLLECTION_NAME,
  quotes: [
    {
      ...initialQuoteState,
    },
  ],
  action: SAVE_QUOTE_COLLECTION,
  isSaveQuote: false,
  showSaveQuoteModal: false,
  saveQuoteError: null,
  fetchQuoteError: null,
  calculateQuoteError: null,
  isCalQuoteErrorModal: false,
  isSaveQuoteErrorModal: false,
  isSaveAndExitModal: false,
  isDownloadQuoteInProgress: false,
  isTeleFromAdviser: false,
  isUpdateQuoteSuccess: false,
  isFetchingData: false,
  applicationType: 'NEWBUS',
  bancsAdviserCustomerNo: getAdviserNo() || getBancsCustomerNumber(),
  workTypeHistory: [],
  isRedirectingToThirdParty: false,
}

export const createQuote = (
  state: Object = initialState,
  action: { type: string, payload: Object, error?: boolean }
): createQuoteType => {
  switch (action.type) {
    // create quote sidebar entry point - new business.
    case CREATE_QUOTE_UPDATE_MEMBER_MANDATORIES: {
      // spread the payload into each quote entity that exists in the quote collection.
      const { occupationalRating, earningExcludingSuper } = action.payload
      const quotes = state.quotes.map(quote => {
        const { policyStructure } = quote
        return {
          ...quote,
          memberMandatories: {
            ...quote.memberMandatories,
            ...(quote.memberMandatories.dateOfBirth && {
              dateOfBirth: moment(quote.memberMandatories.dateOfBirth, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
            }),
            ...action.payload,
            ...(!earningExcludingSuper && {
              earningExcludingSuper: 0,
            }),
          },
          ...(occupationalRating
            ? {
                policyStructure: updateOccupationalRatingInCovers(
                  policyStructure,
                  occupationalRating
                ),
              }
            : {}),
        }
      })

      // bulk update the quote collection.
      return {
        ...state,
        quotes,
      }
    }

    case CREATE_QUOTE_UPDATE_SECONDARY_MAIL: {
      // spread the payload into each quote entity that exists in the quote collection.
      const quotes = state.quotes.map(quote => ({
        ...quote,
        alternateEmailDetails: {
          ...action.payload,
        },
      }))

      // bulk update the quote collection.
      return {
        ...state,
        quotes,
      }
    }

    // update Quote Name when edited.
    case CREATE_QUOTE_SET_QUOTE_NAME: {
      const { quoteName } = action.payload
      return {
        ...state,
        quotes: state.quotes.map(
          // insert the policy structure at the active index.
          (quote, index) =>
            index === state.activeIndex
              ? {
                  ...quote,
                  quoteName,
                }
              : quote
        ),
      }
    }

    // Reset total premium amount
    case RESET_TOTAL_PREMIUM_AMOUNT: {
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                policyStructure: quote.policyStructure.map(policy =>
                  policy.covers.length === 0
                    ? {
                        ...policy,
                        totalPremiumAmount: '0',
                        policyFeeValue: '0',
                      }
                    : {
                        ...policy,
                      }
                ),
              }
            : quote
        ),
      }
    }

    // update quote status
    case UPDATE_QUOTE_STATUS: {
      const {
        quoteStatus: { actionName, quoteType, underwritingMethod },
      } = action.payload
      return {
        ...state,
        action: actionName || state.action,
        quotes: state.quotes.map(
          // insert the policy structure at the active index.
          (quote, index) =>
            index === state.activeIndex
              ? {
                  ...quote,
                  type: quoteType || quote.type,
                  ...(underwritingMethod
                    ? {
                        underwritingDetails: {
                          ...quote.underwritingDetails,
                          underwritingMethod,
                        },
                      }
                    : {}),
                }
              : quote
        ),
      }
    }

    // update firstName and lastName when Quote Name is edited.
    case SET_LIFE_ASSURED_NAME: {
      const { firstName, lastName } = action.payload
      const { quotes, activeIndex } = state

      return {
        ...state,
        quotes: quotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure:
                quote.policyStructure &&
                quote.policyStructure.map(policy => ({
                  ...policy,
                  relationships:
                    policy.relationships &&
                    policy.relationships.map(relationship => {
                      if (relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)) {
                        return {
                          ...relationship,
                          relatedParty: {
                            ...relationship.relatedParty,
                            firstName,
                            lastName,
                          },
                        }
                      }
                      return relationship
                    }),
                })),
            }
          }
          return quote
        }),
      }
    }

    // update Quote Name when edited.
    case CREATE_QUOTE_SET_QUOTE_COLLECTION_NAME: {
      const { quoteCollectionName } = action.payload
      return {
        ...state,
        quoteCollectionName,
      }
    }

    // set policy structure when entering the quote tool.
    case CREATE_QUOTE_SET_POLICY_STRUCTURE: {
      const { policyStructure, policyFee } = action.payload
      const policyFeeCheck = policyFee ? 'Y' : 'N'
      return {
        ...state,
        quotes: state.quotes.map(
          // insert the policy structure at the active index.
          (quote, index) =>
            index === state.activeIndex
              ? {
                  ...quote,
                  policyStructure: policyStructure.map(policy =>
                    index === state.activeIndex
                      ? {
                          ...policy,
                          policyFee: policyFeeCheck,
                        }
                      : policy
                  ),
                }
              : quote
        ),
      }
    }

    // update mlcOnTrack in policy structure based on toggle.
    case CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_TRACK: {
      const { mlcOnTrack } = action.payload
      const { activeIndex } = state
      const quotes = state.quotes.map((quote, index) => ({
        ...quote,
        policyStructure: quote.policyStructure.map(policy =>
          index === activeIndex
            ? {
                ...policy,
                mlcOnTrack,
              }
            : policy
        ),
        consents:
          mlcOnTrack !== true
            ? (quote.consents || []).filter(item => item.name !== MLC_ON_TRACK_DECLINED)
            : quote.consents,
      }))
      return {
        ...state,
        quotes,
      }
    }

    case CREATE_QUOTE_POLICY_WILL_UPDATE: {
      const { quoteWillBeModified } = action.payload
      return {
        ...state,
        quoteWillBeModified,
      }
    }

    case CREATE_QUOTE_SET_ALTERATION_EFFECTIVE_DATE: {
      const { alterationEffectiveDate } = action.payload
      const { quotes, activeIndex } = state
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex)
            return {
              ...quote,
              alterationEffectiveDate,
            }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_POLICY_FEE: {
      const { policyFee } = action.payload
      const { activeIndex } = state
      const policyFeeCheck = policyFee ? POLICY_FEE_YES : POLICY_FEE_NO
      let policyFeeType = {}
      if (policyFee) {
        policyFeeType = {
          policyFeeType: POLICY_FEE_TYPE,
        }
      }
      const quotes = state.quotes.map((quote, index) => ({
        ...quote,
        policyStructure: quote.policyStructure.map(policy => {
          const { policyFeeType: existingPolicyFeeType, ...policyFields } = policy
          return index === activeIndex
            ? {
                ...policyFields,
                policyFee: policyFeeCheck,
                ...policyFeeType,
              }
            : policy
        }),
      }))
      return {
        ...state,
        quotes,
      }
    }

    // payment details when in-super covers have been selected.
    case CREATE_QUOTE_UPDATE_PAYMENT_DETAILS: {
      const { fundDetails, paymentInstructions, premiumPaymentMethod, selectedPolicyFee } =
        action.payload
      const { activeIndex } = state
      const policyFee = selectedPolicyFee ? POLICY_FEE_YES : POLICY_FEE_NO
      const productClass =
        paymentInstructions === SUPER_OWNER_INTERNAL && fundDetails !== WRAPPER_NAVIGATOR_ID
          ? SUPER_PRODUCT_CLASS
          : EXTERNAL_SUPER_PRODUCT_CLASS

      return {
        ...state,
        // spread the payload into each quote entity that exists in the quote collection.
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          // create a modified version of policy structure which incorporate the payment details in
          //   the super policy, without impacting the order of policies in the array.
          policyStructure: quote.policyStructure.map(policy => {
            if (policy.productId === POLICY_PRODUCT_CODE_SUPER && index === activeIndex) {
              return {
                ...policy,
                policyFee,
                productClass,
                covers: policy.covers.map(cover => ({
                  ...cover,
                  // each cover needs to store the policy's payment method and
                  // super fund name.
                  superFundName: paymentInstructions,
                })),
                // storing superFundName and fundPaymentMethod here, need it for "Edit super policy"
                superFundName: paymentInstructions,
                premiumPaymentMethod,
                fundPaymentMethod: fundDetails,
              }
            }
            return {
              ...policy,
              policyFee,
            }
          }),
        })),
      }
    }

    // update payment frequency for policy at given index within active quote.
    case CREATE_QUOTE_UPDATE_PAYMENT_FREQUENCY: {
      const { paymentFrequency, policyIndex: currentPolicyIndex } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, policyIndex) => {
            if (policyIndex === currentPolicyIndex && index === activeIndex) {
              return {
                ...policy,
                paymentFrequency,
                ...(policy.paymentInstruction
                  ? {
                      paymentInstruction: {
                        ...policy.paymentInstruction,
                        collectionFrequency: paymentFrequency,
                      },
                    }
                  : {}),
              }
            }

            return policy
          }),
        })),
      }
    }

    // update policy commencement date
    case UPDATE_POLICY_COMMENCEMENT_DATE: {
      const { policyCommencementDate, activePolIndex } = action.payload
      const { activeIndex } = state

      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map(policy => {
            if (policy.policyInstanceNo === activePolIndex && index === activeIndex) {
              return {
                ...policy,
                policyCommencementDate,
              }
            }

            return policy
          }),
        })),
      }
    }

    // apply a fixed payment frequency to the inside super policy in the active quote.
    case CREATE_QUOTE_SET_FIXED_PAYMENT_FREQUENCY: {
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map(policy => {
            if (policy.productId === POLICY_PRODUCT_CODE_SUPER && index === activeIndex) {
              return {
                ...policy,
                paymentFrequency: POLICY_FIXED_PAYMENT_FREQUENCY_VALUE,
              }
            }
            return policy
          }),
        })),
      }
    }

    // update legacy code for active Inside Super policy based on
    // paymentInstructions/superFundName.
    case CREATE_QUOTE_UPDATE_LEGACY_CODE: {
      const { legacySystemProductCode } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map(policy => {
            if (policy.productId === POLICY_PRODUCT_CODE_SUPER && index === activeIndex) {
              return {
                ...policy,
                legacySystemProductCode,
              }
            }
            return policy
          }),
        })),
      }
    }

    case CREATE_QUOTE_ADD_POLICY: {
      const { policy } = action.payload
      const { activeIndex } = state

      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure:
            index === activeIndex ? [...quote.policyStructure, policy] : quote.policyStructure,
        })),
      }
    }

    // set policy structure when entering the quote tool.
    case UPDATE_APPLICATION_STATUS: {
      const { status } = action.payload
      const { activeIndex, quotes } = state
      return {
        ...state,
        quotes: quotes.map((quote, index) =>
          index === activeIndex
            ? {
                ...quote,
                ...(status && status.length
                  ? {
                      applicationDetails: {
                        ...quote.applicationDetails,
                        overallApplicationOutcome: DEFAULT_APP_OUTCOME,
                        status,
                      },
                    }
                  : {
                      applicationDetails: {
                        ...quote.applicationDetails,
                        status: get(initialQuoteState, 'applicationDetails.status', []),
                        overallApplicationOutcome: ACCEPTED,
                      },
                    }),
              }
            : quote
        ),
      }
    }

    // update a single covers sum insured values through the quote tool.
    case CREATE_QUOTE_UPDATE_SUM_INSURED: {
      const { policyIndex, coverIndex, value, sumAssuredApplicable } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, policyMapIndex) => {
            // if this is the actively edited quote, update within policy structure.
            if (index === activeIndex && policyMapIndex === policyIndex) {
              return {
                ...policy,
                covers: policy.covers.map((cover, coverMapIndex) =>
                  // update the correct cover by index.
                  coverMapIndex === coverIndex
                    ? {
                        ...cover,
                        coverAmount: value ? parseInt(value, 10) : '',
                        sumAssuredApplicable,
                      }
                    : cover
                ),
              }
            }

            return policy
          }),
        })),
      }
    }

    // update a cover's child (benefitChildCI) data
    case CREATE_QUOTE_UPDATE_CHILD_DATA: {
      const { policyIndex, benefitInstanceNo, childData } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, policyMapIndex) => {
            // if this is the actively edited quote, update within policy structure.
            if (index === activeIndex && policyMapIndex === policyIndex) {
              return {
                ...policy,
                covers: policy.covers.map(cover =>
                  // update the correct cover by `benefitInstanceNo` identifier.
                  cover.benefitInstanceNo === benefitInstanceNo
                    ? {
                        ...cover,
                        ...childDataToBenefitAssured(childData),
                      }
                    : cover
                ),
              }
            }

            return policy
          }),
        })),
      }
    }

    // Update a policy's covers
    case CREATE_QUOTE_UPDATE_COVERS: {
      const { policyIndex, covers } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, policyMapIndex) => {
            // if this is the actively edited quote, update within policy structure.
            if (index === activeIndex && policyMapIndex === policyIndex) {
              return {
                ...policy,
                covers,
              }
            }
            return policy
          }),
        })),
      }
    }

    case CREATE_QUOTE_SET_FEATURES: {
      const { policyIndex, benefitInstanceNo, features, type } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, policyMapIndex) => {
            // if this is the actively edited quote, update within policy structure.
            if (index === activeIndex && policyMapIndex === policyIndex) {
              return {
                ...policy,
                covers: policy.covers.map(cover =>
                  // update the correct cover by `benefitInstanceNo` identifier.
                  cover.benefitInstanceNo === benefitInstanceNo && cover.type === type
                    ? {
                        ...cover,
                        features,
                      }
                    : cover
                ),
              }
            }

            return policy
          }),
        })),
      }
    }

    case REMOVE_PARENT_BENEFIT_REFERENCE: {
      const { policyIndex, coverIndex } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, policyMapIndex) => {
            if (index === activeIndex && policyMapIndex === policyIndex) {
              return {
                ...policy,
                covers: policy.covers.map((cover, coverMapIndex) => {
                  if (coverMapIndex === coverIndex) {
                    delete cover.parentBenefitReference
                    return {
                      ...cover,
                    }
                  }
                  return cover
                }),
              }
            }
            return policy
          }),
        })),
      }
    }

    case CREATE_QUOTE_UPDATE_FEATURE_STATUS: {
      const { policyIndex, coverIndex } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, policyMapIndex) => {
            if (index === activeIndex && policyMapIndex === policyIndex) {
              return {
                ...policy,
                covers: policy.covers.map((cover, coverMapIndex) => {
                  if (coverMapIndex === coverIndex) {
                    return {
                      ...cover,
                      features: cover.features.map(feature => {
                        if (
                          ((feature.featureName === POLICY_FEATURE_LCBB ||
                            feature.featureName === POLICY_FEATURE_DBB) &&
                            (cover.type === POLICY_BENEFIT_CI_PLUS ||
                              cover.type === POLICY_BENEFIT_PTD)) ||
                          (feature.featureName === POLICY_FEATURE_LCBB &&
                            cover.type === POLICY_BENEFIT_CI_STD) ||
                          feature.featureName === POLICY_BENEFIT_SDTRO
                        ) {
                          return {
                            ...feature,
                            featureApplicable: 'N',
                          }
                        }
                        return {
                          ...feature,
                        }
                      }),
                    }
                  }
                  return cover
                }),
              }
            }
            return policy
          }),
        })),
      }
    }

    case CREATE_QUOTE_UPDATE_BENEFIT_LINK: {
      const { policyIndex, coverIndex, value, productRules, masterData } = action.payload
      const { allowedTPDOccupationTypeGroup, excludedTPDOccupationTypeCode, featureControlSwitch } =
        masterData
      const { activeIndex } = state
      const enableTPDOptimser = isFeatureEnabledForAP(featureControlSwitch, 'enableTPDOptimser')
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, policyMapIndex) => {
            // if this is the actively edited quote, update within policy structure.
            if (index === activeIndex && policyMapIndex === policyIndex) {
              return {
                ...policy,
                covers: policy.covers.map((cover, coverMapIndex) => {
                  // update the correct cover by index.
                  if (coverMapIndex === coverIndex) {
                    if ((value && value.benefitNature) === BENEFIT_NATURE_TYPE_STANDALONE) {
                      delete cover.parentBenefitReference
                      cover.features = get(cover, 'features', []).map(feature => {
                        if (
                          ((feature.featureName === POLICY_FEATURE_LCBB ||
                            feature.featureName === POLICY_FEATURE_DBB) &&
                            (cover.type === POLICY_BENEFIT_CI_PLUS ||
                              cover.type === POLICY_BENEFIT_PTD)) ||
                          (feature.featureName === POLICY_FEATURE_LCBB &&
                            cover.type === POLICY_BENEFIT_CI_STD)
                        ) {
                          return {
                            ...feature,
                            featureApplicable: 'N',
                          }
                        }
                        return {
                          ...feature,
                        }
                      })
                      if (cover.type === POLICY_BENEFIT_CI_PLUS) {
                        quote.policyStructure.forEach(pol => {
                          pol.covers.forEach(cov => {
                            if (
                              isParentInstanceSame(cov, cover, policy.policyInstanceNo) ||
                              isParentInstanceSame(
                                {
                                  parentBenefitReference: cov.optimiserParentBenefitReference,
                                },
                                cover,
                                policy.policyInstanceNo
                              )
                            ) {
                              // Make new benefit links for new cover.
                              delete cov.parentBenefitReference
                              delete cov.optimiserParentBenefitReference
                              const benefitLinks = getLinks(
                                cov,
                                productRules,
                                quote.policyStructure,
                                pol,
                                enableTPDOptimser,
                                isOwnTPDDisabled(
                                  cov.type,
                                  get(quote, 'memberMandatories.occupationClassCode'),
                                  allowedTPDOccupationTypeGroup,
                                  excludedTPDOccupationTypeCode,
                                  get(quote, 'memberMandatories.occupationCode')
                                )
                              )
                              const updatedLinkage =
                                benefitLinks.find(
                                  ({ value: link }) =>
                                    Number(link.parentPolicyReferenceNo) ===
                                      Number(pol.policyInstanceNo) &&
                                    link.parentType === POLICY_BENEFIT_LC
                                ) || null

                              cov.parentBenefitReference = updatedLinkage && updatedLinkage.value
                              if (!cov.parentBenefitReference) {
                                delete cov.parentBenefitReference
                              }
                            }
                          })
                        })
                      }
                      return {
                        ...cover,
                      }
                    }
                    return {
                      ...cover,
                      parentBenefitReference: {
                        ...value,
                      },
                    }
                  }
                  return cover
                }),
              }
            }

            return policy
          }),
        })),
      }
    }

    // used to freeze the ui from user input while a calculation is in progress.
    case CREATE_QUOTE_GET_CALCULATION_IN_PROGRESS:
    case CREATE_QUOTE_CALCULATION_IN_PROGRESS: {
      return {
        ...state,
        isRecalculating: true,
        hasCalculationError: false,
      }
    }

    case CREATE_QUOTE_UPDATE_MEMBER_DETAILS: {
      const { activeIndex } = state
      const { memberEntityStructure: memberEntityStr, role, policyInstanceNo } = action.payload
      const memberEntityStructure = { ...memberEntityStr }
      const { employment, contactMethods, firstName, lastName } = memberEntityStructure
      const address = contactMethods.addresses.find(
        contactMethodAddress => contactMethodAddress.preferred === PREFERRED_YES
      )
      return {
        ...state,
        lifeInsuredFirstName:
          role && role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
            ? firstName
            : state.lifeInsuredFirstName,
        lifeInsuredLastName:
          role && role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
            ? lastName
            : state.lifeInsuredLastName,
        quoteCollectionName:
          role && role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
            ? `${firstName} ${lastName}`
            : state.quoteCollectionName,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            const {
              dateOfBirth,
              stateOfResidence,
              employmentStatus,
              earningExcludingSuper,
              occupation,
              occupationCode,
              gender,
              isSmoker,
              occupationalRating,
            } = quote.memberMandatories
            let isPrimary = false
            const updatedOccupationalRating = get(memberEntityStructure, 'occupationalRating', {})
            const secondaryOccupationCode = get(
              updatedOccupationalRating,
              'secondaryOccupationCode',
              ''
            )
            const updatedPolicyStructure = updatedOccupationalRating.attributes
              ? updateOccupationalRatingInCovers(
                  quote.policyStructure,
                  updatedOccupationalRating.attributes
                )
              : quote.policyStructure
            const employmentStatusUpdated = get(employment, 'employmentStatus', employmentStatus)
            const earningExcludingSuperUpdated =
              isEmployee(employmentStatusUpdated) &&
              get(employment, 'yearlyIncomeExcludingSuper', earningExcludingSuper)
            if (employment) {
              delete employment.yearlyIncomeExcludingSuper
            }
            return {
              ...quote,
              ...(memberEntityStructure.isSmoker !== 'Unknown'
                ? {
                    memberMandatories: {
                      ...quote.memberMandatories,
                      dateOfBirth: get(memberEntityStructure, 'dateOfBirth', dateOfBirth),
                      earning: employment.totalGrossYrlyIncome,
                      earningExcludingSuper: earningExcludingSuperUpdated || 0,
                      isSmoker: get(memberEntityStructure, 'isSmoker', isSmoker),
                      gender: get(memberEntityStructure, 'gender', gender),
                      stateOfResidence:
                        get(address, 'country') === COUNTRY_CODE
                          ? get(address, 'state', stateOfResidence)
                          : OVERSEAS_STATE,
                      employmentStatus: employmentStatusUpdated,
                      occupation: get(employment, 'occupation', occupation),
                      occupationalRating: {
                        ...get(updatedOccupationalRating, 'attributes', occupationalRating),
                        secondaryOccupationCode: secondaryOccupationCode || '',
                      },
                      occupationCode:
                        get(employment, 'occupationCode', occupationCode) || occupationCode,
                    },
                  }
                : {}),
              policyStructure: updatedPolicyStructure.map(policy => {
                let memberIdentifierFound = false
                policy.relationships = policy.relationships || []
                if (
                  policy.policyInstanceNo === policyInstanceNo &&
                  !policy.relationships.filter(mem => mem.role.includes(POLICY_RELATIONSHIPS_OWNER))
                    .length
                ) {
                  isPrimary = true
                }
                policy.relationships.some(member => {
                  member.relatedParty &&
                    member.relatedParty.identifiers &&
                    member.relatedParty.identifiers.some(identifier => {
                      const newMemberIdentifier = memberEntityStructure.identifiers.find(
                        id => id.type === REF_NO
                      )
                      memberIdentifierFound =
                        identifier.value === (newMemberIdentifier && newMemberIdentifier.value)
                      return memberIdentifierFound
                    })
                  if (memberIdentifierFound) {
                    if (policy.policyInstanceNo === policyInstanceNo || !policyInstanceNo) {
                      member.role = member.role.includes(role[0])
                        ? [...member.role]
                        : Array.from(
                            new Set([...member.role, ...role, POLICY_RELATIONSHIPS_MEMBER])
                          )
                    } else {
                      member.role = member.role.includes(role[0])
                        ? [...member.role]
                        : Array.from(new Set([...member.role, POLICY_RELATIONSHIPS_MEMBER]))
                    }
                    member.relatedParty = memberEntityStructure
                    member.isPrimary =
                      (member.role.includes(POLICY_RELATIONSHIPS_OWNER) && member.isPrimary) ||
                      false
                  }
                  return memberIdentifierFound
                })

                const lifeAssured =
                  (policy.relationships &&
                    policy.relationships.find(mem =>
                      mem.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
                    )) ||
                  {}

                if (!memberIdentifierFound) {
                  return {
                    ...policy,
                    relationships: [
                      ...(lifeAssured &&
                      lifeAssured.relatedParty &&
                      !lifeAssured.relatedParty.identifiers
                        ? policy.relationships.filter(
                            mem => !mem.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
                          )
                        : policy.relationships),
                      {
                        role:
                          policy.policyInstanceNo === policyInstanceNo || !policyInstanceNo
                            ? [...role, POLICY_RELATIONSHIPS_MEMBER]
                            : [POLICY_RELATIONSHIPS_MEMBER],
                        relatedParty: memberEntityStructure,
                        isPrimary,
                        companies: [],
                      },
                    ],
                  }
                }

                return {
                  ...policy,
                  beneficiaries:
                    (policy.beneficiaries &&
                      policy.beneficiaries.length &&
                      policy.beneficiaries.map(beneficary =>
                        beneficary.relatedParty.identifiers[0].value ===
                        memberEntityStructure.identifiers[0].value
                          ? {
                              ...beneficary,
                              relatedParty: {
                                ...beneficary.relatedParty,
                                ...memberEntityStructure,
                                contactMethods: {
                                  ...beneficary.relatedParty.contactMethods,
                                  ...memberEntityStructure.contactMethods,
                                },
                              },
                            }
                          : beneficary
                      )) ||
                    [],
                }
              }),
            }
          }
          return quote
        }),
      }
    }
    case CREATE_QUOTE_DELETE_MEMBER_DETAILS: {
      const { activeIndex } = state
      const { policyInstanceNo, identifier, roleToGetRemoved, deletePolicyOwner } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                if (
                  (policyInstanceNo && policy.policyInstanceNo === policyInstanceNo) ||
                  !policyInstanceNo
                ) {
                  const toBeDeletetedPayerIndex =
                    policy.relationships &&
                    policy.relationships.findIndex(
                      member =>
                        member.relatedParty &&
                        member.relatedParty.identifiers.some(id => id.value === identifier.value)
                    )

                  const indexOfRole =
                    policy.relationships[toBeDeletetedPayerIndex].role.indexOf(roleToGetRemoved)
                  policy.relationships[toBeDeletetedPayerIndex].role.splice(indexOfRole, 1)
                  if (policy.relationships[toBeDeletetedPayerIndex] && deletePolicyOwner) {
                    delete policy.relationships[toBeDeletetedPayerIndex].isPrimary
                  }

                  if (deletePolicyOwner && policy.relationships && policy.relationships.length) {
                    const primayOwnerToUpdate = policy.relationships.findIndex(relationship =>
                      relationship.role.includes(POLICY_RELATIONSHIPS_OWNER)
                    )
                    if (primayOwnerToUpdate !== -1) {
                      policy.relationships[primayOwnerToUpdate].isPrimary = true
                    }
                  }
                  if (roleToGetRemoved === POLICY_RELATIONSHIPS_PAYER)
                    delete policy.paymentInstruction
                }
                return { ...policy }
              }),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_ADD_POLICY_OWNER: {
      const { activeIndex } = state
      const { policyInstanceNo, newRelationship } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy =>
                policy.policyInstanceNo === policyInstanceNo
                  ? {
                      ...policy,
                      relationships: replacePolicyOwner(policy, newRelationship),
                    }
                  : policy
              ),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_POLICY_OWNER: {
      const { activeIndex } = state
      const { policyInstanceNo, identifier } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                const updatedRelationships = policy.relationships.map(relationship => {
                  const updatedRelationship = { ...relationship }
                  delete updatedRelationship.isPrimary
                  return {
                    ...updatedRelationship,
                    ...(updatedRelationship?.role?.includes(POLICY_RELATIONSHIPS_OWNER) && {
                      isPrimary: !!updatedRelationship?.relatedParty?.identifiers.some(
                        id => id.value === identifier.value
                      ),
                    }),
                  }
                })

                return policy.policyInstanceNo === policyInstanceNo
                  ? {
                      ...policy,
                      relationships: updatedRelationships,
                    }
                  : policy
              }),
            }
          }
          return quote
        }),
      }
    }

    // update a quote in the collection once recalculated by api.
    case CREATE_QUOTE_CALCULATE_SUCCESS: {
      const { quoteIndex, ...recalculatedQuote } = action.payload
      const { memberMandatories, policyStructure, underwritingDetails } = recalculatedQuote.quote
      return {
        ...state,
        discountValue: 0,
        isRecalculating: false,
        quotes: state.quotes.map(
          // insert the updated quote at the correct index.
          (quote, index) =>
            index === quoteIndex
              ? {
                  ...quote,
                  // spread mandatories over the existing values, in case keys are missing.
                  memberMandatories: {
                    ...quote.memberMandatories,
                    ...memberMandatories,
                  },
                  policyStructure: policyStructure.map(policyStructureItem =>
                    policyStructureItem.covers === undefined
                      ? {
                          // set blank array in case covers is missing in calculate response.
                          ...policyStructureItem,
                          covers: [],
                        }
                      : policyStructureItem
                  ),
                  ...(underwritingDetails && { underwritingDetails }),
                }
              : quote
        ),
      }
    }

    case CREATE_QUOTE_GET_CALCULATE_SUCCESS: {
      return {
        ...state,
        isRecalculating: false,
        ...action.payload,
      }
    }

    case CREATE_QUOTE_ADD_BENEFIT_TO_POLICY: {
      const { activeIndex } = state
      const { benefit, policyIndex, policyFee = 'N' } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quote.policyStructure.map((policy, currPolicyIndex) => {
            if (index === activeIndex && currPolicyIndex === policyIndex) {
              return {
                ...policy,
                policyFee,
                covers: [...policy.covers, benefit],
              }
            }

            return policy
          }),
        })),
      }
    }

    case CREATE_QUOTE_UPDATE_COVER: {
      const { policyIndex, coverIndex, value, productRules, masterData } = action.payload
      const { activeIndex } = state
      const updatedQuotes = state.quotes.map((quote, index) => ({
        ...quote,
        policyStructure: quote.policyStructure.map((policy, policyMapIndex) => {
          if (index === activeIndex && policyMapIndex === policyIndex) {
            return {
              ...policy,
              covers: policy.covers.map((cover, coverMapIndex) =>
                coverMapIndex === coverIndex
                  ? deepmerge(cover, value, {
                      arrayMerge: (destinationArray, sourceArray) => sourceArray,
                    })
                  : {
                      ...cover,
                      ...(cover.commissionDetails &&
                        (cover.type === POLICY_BENEFIT_TISO ||
                          cover.type === POLICY_BENEFIT_PREMIUM_WAIVER) && {
                          commissionDetails: value.commissionDetails,
                        }),
                    }
              ),
            }
          }

          return policy
        }),
      }))
      return {
        ...state,
        quotes: updatedQuotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                covers: policy.covers.map(cover => {
                  const updatedParentBenefitReference = getParentBenefitReferenceForCover({
                    cover,
                    policy,
                    activeQuote: quote,
                    productRules,
                    masterData,
                  })
                  // Case example: PTD linked to CI_PLUS. CI_PLUS changed to CI_STD. Hence PTD
                  // is set to standalone. Delete PTD parentBenefitReference
                  if (!updatedParentBenefitReference && cover.parentBenefitReference) {
                    delete cover.parentBenefitReference
                  }
                  return {
                    ...cover,
                    ...updatedParentBenefitReference,
                  }
                }),
              })),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_ADD_FEATURE_AS_SUB_BENEFIT: {
      const { activeIndex } = state
      const { productRulesData, benefitCode, sbenefitCode } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                covers: policy.covers.map(cover => {
                  if (
                    cover.type === benefitCode &&
                    (isBoosterOptionIPAvailable(quote) ||
                      checkIfFeatureEnabled(cover, POLICY_FEATURE_EBO))
                  ) {
                    const subBenefit = getSubBenefitFromProducts(
                      productRulesData,
                      policy.productId,
                      benefitCode,
                      sbenefitCode
                    )
                    const { subBenefitCode = '', subBenefitName = '' } = subBenefit
                    const subBenefitData = {
                      subBenefitCode,
                      subBenefitName,
                      applicable: 'Y',
                      mandatoryIndicator: 'M',
                    }
                    return {
                      ...cover,
                      subBenefits: cover.subBenefits
                        ? [
                            ...cover.subBenefits.filter(sub => sub.subBenefitCode !== sbenefitCode),
                            subBenefitData,
                          ]
                        : [subBenefitData],
                      ...(cover.features && {
                        features: cover.features.map(feature => {
                          if (feature.featureName === sbenefitCode) {
                            return {
                              ...feature,
                              featureApplicable: 'Y',
                            }
                          }
                          return {
                            ...feature,
                          }
                        }),
                      }),
                    }
                  }
                  return cover
                }),
              })),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_DELETE_FEATURE_AS_SUB_BENEFIT: {
      const { activeIndex } = state
      const { benefitCode = '', policyInstanceNo = 0, benefitInstanceNo = 0 } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                covers: policy.covers.map(cover => {
                  const { subBenefits = [] } = cover
                  if (
                    cover.type === INCOME_PROTECTION_PLATINUM_ID ||
                    (cover.type === POLICY_BENEFIT_CI_PLUS &&
                      cover.policyInstanceNo === policyInstanceNo &&
                      cover.benefitInstanceNo === benefitInstanceNo)
                  ) {
                    return {
                      ...cover,
                      ...(cover.features && {
                        features: cover.features.map(feature => {
                          if (
                            feature.featureName === POLICY_FEATURE_BO ||
                            feature.featureName === POLICY_FEATURE_EBO
                          ) {
                            return {
                              ...feature,
                              featureApplicable: 'N',
                            }
                          }
                          return {
                            ...feature,
                          }
                        }),
                      }),
                      subBenefits:
                        cover.type === benefitCode
                          ? subBenefits.filter(
                              subBenefit =>
                                subBenefit.subBenefitCode !== POLICY_FEATURE_BO ||
                                subBenefit.subBenefitCode !== POLICY_FEATURE_EBO
                            )
                          : subBenefits,
                    }
                  }
                  return cover
                }),
              })),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS:
    case CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_SUCCESS: {
      const {
        form: {
          adviserOutlet,
          splitRenumeration,
          otherAdviserCode,
          otherAdviserName,
          productionPercentageSplit,
          renewalPercentageSplit,
        },
      } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                commissionSplitDetails:
                  splitRenumeration.value === 'Y'
                    ? {
                        agencyCode: adviserOutlet.value.value,
                        splitRemuneration: true,
                        splitAgencyCode: otherAdviserCode.value,
                        splitAdvisorName: otherAdviserName.value,
                        splitInitialRemunerationPercentage: productionPercentageSplit.value,
                        splitRenewalRemunerationPercentage: renewalPercentageSplit.value,
                      }
                    : {
                        agencyCode: adviserOutlet.value.value,
                        splitRemuneration: false,
                      },
              })),
            }
          }
          return quote
        }),
      }
    }

    case UPDATE_IS_TERM_ACCEPTED_FIELD: {
      const { updatedTermValue } = action.payload

      const clonedQuote = cloneDeep(state)
      const { quotes, activeIndex } = clonedQuote

      updatedTermValue.forEach(termItem => {
        const { policyIndex, coverIndex, value } = termItem

        quotes[activeIndex].policyStructure[policyIndex].covers[coverIndex].isTermsAccepted =
          value.toLowerCase() === 'accept'
      })

      return {
        ...clonedQuote,
      }
    }

    case CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_ERROR:
    case CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_FAILURE: {
      return {
        ...state,
      }
    }

    case CREATE_QUOTE_CALCULATE_ERROR: {
      return {
        ...state,
        calculateQuoteError: action.payload,
        isRecalculating: false,
        hasCalculationError: true,
        isCalQuoteErrorModal: false,
      }
    }
    case CREATE_QUOTE_CALCULATE_FAILURE: {
      return {
        ...state,
        calculateQuoteError: action.payload,
        isRecalculating: false,
        hasCalculationError: true,
        isCalQuoteErrorModal: true,
      }
    }

    case CREATE_QUOTE_GET_CALCULATE_ERROR:
    case CREATE_QUOTE_GET_CALCULATE_FAILURE: {
      return {
        ...state,
        isRecalculating: false,
      }
    }

    case CREATE_QUOTE_CALCULATE_QUOTE_ERROR_MODAL: {
      const { isCalQuoteErrorModal } = action.payload
      return {
        ...state,
        isCalQuoteErrorModal,
      }
    }

    case CREATE_QUOTE_DUPLICATE_TEMPLATE: {
      const { quoteItem, activeIndex } = action.payload
      const nextQuoteNumber = getNextQuoteNumber(state.quotes)
      return {
        ...state,
        activeIndex,
        quotes: [{ ...quoteItem, quoteName: `Quote# ${nextQuoteNumber}` }, ...state.quotes],
        saveQuoteInitiator: '',
      }
    }

    case UPDATE_QUOTE_ID_TO_ACTION: {
      const { quoteId } = action.payload
      return {
        ...state,
        quoteIDToAction: quoteId,
      }
    }

    case CREATE_QUOTE_UPDATE_QUOTE_COLLECTION: {
      const { quoteCollection } = action.payload
      return {
        ...state,
        ...quoteCollection,
      }
    }

    case CREATE_QUOTE_DELETE_FROM_COLLECTION: {
      const { activeIndex } = action.payload
      const newCollection = [...state.quotes]
      newCollection.splice(activeIndex, 1)
      // in the collection stack the activeIndex = 0 indicates the last quote
      const newActiveIndex = activeIndex === newCollection.length ? activeIndex - 1 : activeIndex

      return {
        ...state,
        activeIndex: newActiveIndex,
        quotes: newCollection,
      }
    }

    case UPDATE_QUOTE_ACTIVE_INDEX: {
      const { activeIndex } = action.payload
      return {
        ...state,
        activeIndex,
      }
    }

    case IS_UPDATED_QUOTE: {
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                isUpdated: true,
              }
            : {
                ...quote,
                isUpdated: false,
              }
        ),
      }
    }

    case REMOVE_ACTION_AGREEMENT_CONSENTS: {
      return {
        ...state,
        action: '',
      }
    }

    case UPDATE_QUOTE_ENTITY_MY_LINK_DETAILS: {
      const { contact, adviserContact, targeteIndex } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          const agencyCode = get(quote, 'policyStructure[0].agencyDetails[0].agencyCode', '')
          const partyType = POLICY_PARTY_TYPE_INDIVIDUAL
          const roleCode = POLICY_RELATIONSHIPS_LIFEASSURED
          const relationships = get(quote, 'policyStructure[0].relationships', [])
          const isRoleLifeAssured = relationships.filter(relationship =>
            relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
          )
          const recipientFirstName = get(isRoleLifeAssured[0], 'relatedParty.firstName', '')
          const recipientLastName = get(isRoleLifeAssured[0], 'relatedParty.lastName', '')
          if (index === targeteIndex) {
            return {
              ...quote,
              myLinkDetails: {
                ...quote.myLinkDetails,
                agencyCode,
                partyType,
                roleCode,
                recipientFirstName,
                recipientLastName,
                ...adviserContact,
                application: {
                  ...(quote.myLinkDetails ? quote.myLinkDetails.application : {}),
                  ...contact,
                },
              },
            }
          }
          return quote
        }),
      }
    }

    case UPDATE_ADVISER_VERIFICATION_CONSENTS: {
      const { consentsData, activeIndex } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === activeIndex
            ? {
                ...quote,
                ...(quote.consents
                  ? {
                      consents: uniqBy(
                        [
                          {
                            name: 'ADVISER_VERIFICATION',
                            value: consentsData,
                          },
                          ...quote.consents,
                        ],
                        'name'
                      ),
                    }
                  : {
                      consents: [
                        {
                          name: 'ADVISER_VERIFICATION',
                          value: consentsData,
                        },
                      ],
                    }),
              }
            : quote
        ),
      }
    }

    case DELETE_QUOTE_COLLECTION_SUCCESS: {
      const {
        dataReceived: { businessData },
        deletedQuoteId,
      } = action.payload
      const { quoteIDToAction } = businessData
      const { quotes } = state
      const remainingQuotes = quotes.filter(({ quoteId }) => quoteId !== deletedQuoteId)
      // If there is any quote that is not in DRAFT_QT status
      // we want that to be the new active quote so that we may apply quoteIDToAction
      // This is needed as an adviser can leave from the quote screen but
      // then when loading the quote from
      // the apps screen will need a quoteIDToAction to be applied.
      const indexOfFirstDraftApplication = remainingQuotes.findIndex(
        ({ type }) => type !== QUOTE_STATUS_QUOTE_STAGE
      )
      const newActiveIndex = Math.max(0, indexOfFirstDraftApplication)
      return {
        ...state,
        isFetchingData: false,
        quotes: remainingQuotes,
        activeIndex: newActiveIndex,
        quoteIDToAction:
          quoteIDToAction &&
          // If this is -1 it means there are only quotes with status of 'DRAFT_QT',
          // assigning a quoteIDToAction make the subsequent save fail due to validation.
          indexOfFirstDraftApplication > -1 &&
          remainingQuotes[newActiveIndex].quoteId,
      }
    }

    case DELETE_QUOTE_COLLECTION_FAILURE:
    case DELETE_QUOTE_COLLECTION_ERROR: {
      return {
        ...state,
        isFetchingData: false,
      }
    }

    case UPDATE_QUOTE_COLLECTION_SUCCESS: {
      const {
        dataReceived: { businessData },
        identifier,
      } = action.payload
      return {
        ...state,
        ...businessData,
        isUpdateQuoteSuccess: true,
        saveQuoteInitiator: identifier || state.saveQuoteInitiator,
      }
    }

    case UPDATE_QUOTE_COLLECTION_FAILURE:
    case UPDATE_QUOTE_COLLECTION_ERROR: {
      return {
        ...state,
        isUpdateQuoteSuccess: false,
      }
    }

    case RESET_UPDATE_QUOTE_COLLECTION_SUCCESS_FLAG: {
      return {
        ...state,
        isUpdateQuoteSuccess: false,
      }
    }

    case CREATE_QUOTE_REMOVE_BENEFIT: {
      const { benefit, productRulesData, data, isIPCalculated } = action.payload
      const newQuoteCollection = [...state.quotes]
      let newPolicyStructure = newQuoteCollection[benefit.quoteIndex].policyStructure
      const { memberMandatories } = newQuoteCollection[benefit.quoteIndex]
      // Removing the benefit and child benefits
      newPolicyStructure = removeBenefit(newPolicyStructure, benefit)
      // Removing those benefits for which no dependentOn benefits exist
      newQuoteCollection[benefit.quoteIndex].policyStructure = updateQuoteBasedOnDependentOn(
        newPolicyStructure,
        productRulesData
      )
      // adding default sumIsured to first IP cover
      const { updatedPolicyStructure } = postProcessInitialPolicyStructure(
        productRulesData,
        memberMandatories,
        newQuoteCollection[benefit.quoteIndex].policyStructure,
        data,
        isIPCalculated
      )
      newQuoteCollection[benefit.quoteIndex].policyStructure = updatedPolicyStructure

      return {
        ...state,
        quotes: newQuoteCollection,
      }
    }

    case CREATE_QUOTE_UPDATE_IS_SAVE: {
      const { isSaveQuote } = action.payload
      return {
        ...state,
        isSaveQuote,
      }
    }

    case CREATE_QUOTE_SHOW_SAVE_MODAL: {
      const { showSaveQuoteModal, exitToDashboard, identifier } = action.payload
      return {
        ...state,
        showSaveQuoteModal,
        exitToDashboard,
        saveQuoteInitiator: identifier || state.saveQuoteInitiator,
      }
    }

    case CREATE_QUOTE_SAVE: {
      return {
        ...state,
        saveQuoteError: null,
      }
    }

    case UPDATE_BANCS_ADVISER_CUSTOMER_NO: {
      const { bancsCustomerNo } = action.payload
      return {
        ...state,
        bancsAdviserCustomerNo: bancsCustomerNo || getAdviserNo() || getBancsCustomerNumber(),
      }
    }

    case CREATE_QUOTE_SAVE_SUCCESS: {
      const { dataReceived, identifier, productData } = action.payload
      return buildQuoteAfterSave(dataReceived, state, productData, identifier)
    }

    case DELETE_QUOTE_COLLECTION_INIT:
    case CREATE_QUOTE_SAVE_INIT: {
      return {
        ...state,
        isFetchingData: true,
      }
    }

    case CREATE_QUOTE_SAVE_FAILURE:
    case CREATE_QUOTE_SAVE_ERROR: {
      return {
        ...state,
        saveQuoteError: action.payload,
        isSaveQuoteErrorModal: true,
        isFetchingData: false,
      }
    }

    case CREATE_QUOTE_SAVE_QUOTE_ERROR_MODAL: {
      const { isSaveQuoteErrorModal } = action.payload
      return {
        ...state,
        isSaveQuoteErrorModal,
      }
    }

    case CREATE_QUOTE_SAVE_AND_EXIT_MODAL: {
      const { isSaveAndExitModal } = action.payload
      return {
        ...state,
        isSaveAndExitModal,
      }
    }

    case CREATE_QUOTE_CONVERT_COVERS_FEATURES: {
      const { productData, quotes } = action.payload
      if (!productData.length > 0) {
        return state
      }
      return {
        ...state,
        quotes: quotes.map((quote, index) => ({
          ...quote,
          policyStructure: quotes[index].policyStructure.map(policy => ({
            ...policy,
            covers: policy.covers.map(cover => {
              if (cover.parentBenefitReferance && cover.parentBenefitReferance.parentQuotePolicyNo)
                delete cover.parentBenefitReferance.parentQuotePolicyNo
              return {
                ...cover,
                ...(cover.features &&
                  cover.features.length > 0 && {
                    features: makeFeatures(cover, policy, productData),
                  }),
              }
            }),
          })),
        })),
      }
    }

    case CREATE_QUOTE_UPDATE_DUTY_OF_DISCLOSURE_CONSENTS: {
      const { isDutyOfDisclosureChecked, isDutyOfDisclosurePrivacyChecked, targetedIndex } =
        action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === targetedIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: 'DUTY_OF_DISCLOSURE',
                      value: isDutyOfDisclosureChecked,
                    },
                    {
                      name: 'DUTY_OF_DISCLOSURE_PRIVACY',
                      value: isDutyOfDisclosurePrivacyChecked,
                    },
                    ...quote.consents,
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case CREATE_QOUTE_SET_ADVISER_PRESENTED_LICOP_FLYER_CONSENT: {
      return {
        ...state,
        quotes: state.quotes.map(
          // insert the adviser presented licop flyer incentive result consent at the active index.
          (quote, index) =>
            index === state.activeIndex
              ? {
                  ...quote,
                  consents: uniqBy(
                    [
                      {
                        name: ADVISER_PRESENTED_LICOP_FLYER,
                        value: true,
                      },
                      ...quote.consents,
                    ],
                    'name'
                  ),
                }
              : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_DECLARATION_AUTHORIZATION_CONSENTS: {
      const { isDeclarationAuthorizationChecked, targetedIndex, identifier, description } =
        action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === targetedIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: 'DECLARATION_AUTHORIZATION_FOR_LIFE_INSURED',
                      value: isDeclarationAuthorizationChecked,
                      description,
                      ...(identifier ? { identifier: Number(identifier) } : {}),
                    },

                    ...quote.consents,
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_DECLARATION_PO_CONSENTS: {
      const { isDeclarationPOChecked, targetedIndex, identifier } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === targetedIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: 'DECLARATION_AUTHORIZATION_FOR_POLICY_OWNER',
                      value: isDeclarationPOChecked,
                      ...(identifier ? { identifier: Number(identifier) } : {}),
                    },

                    ...quote.consents,
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_TELE_CUSTOMER_TERM_CONSENTS: {
      const { isTeleTermConfirmed, targetedIndex, identifier } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === targetedIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: 'DECLARATION_AUTHORIZATION_FOR_LIFE_INSURED',
                      value: isTeleTermConfirmed,
                      ...(identifier ? { identifier: Number(identifier) } : {}),
                    },

                    ...quote.consents,
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_ADVISER_AUTHORITY_CONSENTS: {
      const { isAdviserAuthorityConfirm, targetedIndex } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === targetedIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: SHARE_PERS_STMT_WITH_ADVISOR,
                      value: isAdviserAuthorityConfirm,
                    },
                    ...quote.consents,
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case UPDATE_MEDICAL_AUTHORITY_CONSENTS: {
      const { MAOne, MATwo } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: SHARE_AUTH1_WITH_GP,
                      value: MAOne,
                    },
                    {
                      name: SHARE_AUTH2_WITH_GP,
                      value: MATwo,
                    },
                    ...quote.consents,
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_DECLARATION_FOR_ADVISOR_CONSENTS: {
      const { isAdviserDeclarationChecked, targetedIndex, identifier, description } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === targetedIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: 'DECLARATION_FOR_ADVISOR',
                      value: isAdviserDeclarationChecked,
                      description,
                      ...(identifier ? { identifier: Number(identifier) } : {}),
                    },
                    ...(quote.consents || []),
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case RESET_QUOTE_DECLARATION_CONSENTS: {
      const { targetedIndex } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === targetedIndex
            ? {
                ...quote,
                consents: quote.consents.filter(
                  item =>
                    !(
                      item.name === 'DECLARATION_AUTHORIZATION_FOR_LIFE_INSURED' ||
                      item.name === 'MARKETING_CONSENT' ||
                      item.name === 'DECLARATION_FOR_ADVISOR' ||
                      item.name === 'DECLARATION_AUTHORIZATION_FOR_POLICY_OWNER'
                    )
                ),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_MARKETING_CONSENTS: {
      const { isMarketingConsentsChecked, targetedIndex, marketingContent } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === targetedIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: 'MARKETING_CONSENT',
                      value: isMarketingConsentsChecked,
                      description: marketingContent,
                    },

                    ...quote.consents,
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_UNDERWRITING_OPTIONS_CONSENTS: {
      const {
        isTrackMedicalAgreed,
        isTrackFollowupAgreed,
        fastTrackingMedicalContent,
        fastTrackingFollowupContent,
      } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: 'FAST_TRACKING_MEDICAL_REQTS',
                      value: isTrackMedicalAgreed,
                      description: fastTrackingMedicalContent,
                    },
                    {
                      name: 'FAST_TRACKING_FOLLOWUP_INFO',
                      value: isTrackFollowupAgreed,
                      description: fastTrackingFollowupContent,
                    },
                    ...quote.consents,
                  ],
                  'name'
                ),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_UNDERWRITING_FOLLOW_UP_TIMING: {
      const { preferred, alternateContact } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                fastTrackingFollowupInfo: { preferred, alternateContact },
              }
            : quote
        ),
      }
    }
    case CREATE_QUOTE_UPDATE_MLC_ON_TRACK_CONSENTS: {
      const { isMlcOnTrackTermsAndConditionsAgreed, isFitnessTrackerFollowupAgreed } =
        action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                consents: uniqBy(
                  [
                    {
                      name: MLC_ON_TRACK_DECLINED,
                      value: isMlcOnTrackTermsAndConditionsAgreed,
                    },
                    ...quote.consents,
                  ],
                  'name'
                ),
                policyStructure: quote.policyStructure.map(policy => ({
                  ...policy,
                  fitnessTracker: isFitnessTrackerFollowupAgreed,
                })),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_TMD_CONSENTS: {
      const {
        meetsTMDRequirementRadio,
        notMeetsTMDRequirementReason,
        isPersonalOrGeneralAdviceForTMDRadio,
      } = action.payload

      let newConsents = []
      const oldConsents = [...state.quotes[state.activeIndex].consents]

      if (meetsTMDRequirementRadio) {
        newConsents = [
          {
            name: CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS,
            value: meetsTMDRequirementRadio,
          },
          {
            name: TYPE_OF_ADVICE_PERSONAL_OR_GENERAL,
            value: isPersonalOrGeneralAdviceForTMDRadio,
          },
          ...oldConsents.filter(
            consent => consent.name !== REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD
          ),
        ]
      } else {
        newConsents = [
          {
            name: CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS,
            value: meetsTMDRequirementRadio,
          },
          {
            name: REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD,
            value: !meetsTMDRequirementRadio,
            textResponse: notMeetsTMDRequirementReason,
          },
          {
            name: TYPE_OF_ADVICE_PERSONAL_OR_GENERAL,
            value: isPersonalOrGeneralAdviceForTMDRadio,
          },
          ...oldConsents,
        ]
      }
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                consents: uniqBy(newConsents, 'name'),
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_DELETE_POLICY: {
      const {
        policy: deletedPolicy,
        productRulesData,
        memberMandatories,
        data,
        isIPCalculated,
      } = action.payload
      const newQuoteCollection = [...state.quotes]
      let newPolicyStructure = newQuoteCollection[deletedPolicy.quoteIndex].policyStructure
      // PolicyIndex of policy deleted based on policyInstanceNo
      const policyIndex = newPolicyStructure.findIndex(
        policy => policy.policyInstanceNo === deletedPolicy.policyInstanceNo
      )
      // Iterating on the policy benefits and deleting them one by one so that
      // child benefits in another policy also gets deleted
      newPolicyStructure[policyIndex] &&
        newPolicyStructure[policyIndex].covers.forEach(cover => {
          const benefit = {
            type: cover.type,
            benefitInstanceNo: cover.benefitInstanceNo,
            policyInstanceNo: deletedPolicy.policyInstanceNo,
          }
          newPolicyStructure = removeBenefit(newPolicyStructure, benefit)
        })
      // Deleting the entire policy
      newPolicyStructure.splice(policyIndex, 1)

      // Using updateQuoteBasedOnDependentOn to delete those benefits(if any) for which
      // none of dependentOn benefits are present
      newPolicyStructure = updateQuoteBasedOnDependentOn(newPolicyStructure, productRulesData)

      // Updating policyInstanceNo of policies as per current order after deleting the policy
      let index
      for (
        index = deletedPolicy.policyInstanceNo - 1;
        index < newPolicyStructure.length;
        index += 1
      ) {
        newPolicyStructure = updatePolicyInstanceNo(newPolicyStructure, index + 2, index + 1)
      }
      const { updatedPolicyStructure } = postProcessInitialPolicyStructure(
        productRulesData,
        memberMandatories,
        newPolicyStructure,
        data,
        isIPCalculated
      )
      newQuoteCollection[deletedPolicy.quoteIndex].policyStructure = updatedPolicyStructure
      return {
        ...state,
        quotes: newQuoteCollection,
      }
    }

    case CHECK_QUOTE_ACTION_STATUS_INIT: {
      return {
        ...state,
        isFetchingData: true,
      }
    }

    case CHECK_QUOTE_ACTION_STATUS_SUCCESS: {
      const {
        payload: { businessData, status },
      } = action
      if (status.type === 'success') {
        const updateState = {
          ...state,
          ...businessData,
          isFetchingData: false,
        }
        return updateState
      }
      return state
    }

    case CHECK_QUOTE_ACTION_STATUS_FAILURE:
    case CHECK_QUOTE_ACTION_STATUS_ERROR: {
      return { ...state, isFetchingData: false, saveQuoteError: true }
    }

    case FETCH_QUOTE_COLLECTION_INIT: {
      return {
        ...initialState,
        isFetchingData: true,
      }
    }

    case FETCH_QUOTE_COLLECTION_SUCCESS: {
      const {
        payload: { businessData, status },
      } = action
      if (status.type === 'success') {
        const quoteIdToAction = businessData.quoteIDToAction
        const updateState = {
          ...state,
          activeIndex:
            quoteIdToAction && businessData.quotes
              ? businessData.quotes.findIndex(({ quoteId }) => quoteId === quoteIdToAction)
              : state.activeIndex || 0,
          ...businessData,
          isFetchingData: false,
        }
        return updateState
      }
      return state
    }

    case FETCH_QUOTE_COLLECTION_FAILURE:
    case FETCH_QUOTE_COLLECTION_ERROR: {
      // TODO:
      // This should set to blank quote collection object
      // For now updating - saveQuoteError state

      return { ...state, saveQuoteError: true, isFetchingData: false }
    }

    case CREATE_QUOTE_ADD_PAYMENT_DETAILS: {
      const { policyInstanceNo, paymentMethod } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy =>
                policy.policyInstanceNo === policyInstanceNo
                  ? {
                      ...policy,
                      paymentInstruction: paymentMethod,
                    }
                  : policy
              ),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_ROLE_OF_MEMBER: {
      const { policyInstanceNo, role, payerType } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                if (
                  (policyInstanceNo && policy.policyInstanceNo === policyInstanceNo) ||
                  !policyInstanceNo
                ) {
                  const payerMemberIndex =
                    policy.relationships &&
                    policy.relationships.findIndex(
                      member =>
                        member.relatedParty &&
                        member.relatedParty.identifiers.some(
                          identifier => identifier.value === payerType.identifier.value
                        )
                    )
                  if (
                    typeof payerMemberIndex !== 'undefined' &&
                    payerMemberIndex !== -1 &&
                    !policy.relationships[payerMemberIndex].role.includes(role)
                  ) {
                    policy.relationships[payerMemberIndex] = {
                      ...policy.relationships[payerMemberIndex],
                      role: [role, ...policy.relationships[payerMemberIndex].role],
                    }
                  }
                }
                return {
                  ...policy,
                }
              }),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_BENEFICIARIES: {
      const { activeIndex } = state
      const { policyInstanceNo, beneficiaries } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                const beneficiariesIndexs = []
                const updatedPolicy = {
                  ...policy,
                  beneficiaries:
                    policy.policyInstanceNo === policyInstanceNo
                      ? beneficiaries
                      : policy.beneficiaries,
                  relationships: [
                    ...policy.relationships.map(relation => {
                      // identifiers not present in case of NULIS/SMSF
                      if (get(relation, 'relatedParty.identifiers.[0].value', '')) {
                        const beneficiaryIdentifireIndex = beneficiaries.findIndex(
                          beneficary =>
                            beneficary.relatedParty.identifiers[0].value ===
                            relation.relatedParty.identifiers[0].value
                        )
                        if (beneficiaryIdentifireIndex !== -1) {
                          beneficiariesIndexs.push(beneficiaryIdentifireIndex)
                          return {
                            ...relation,
                            relatedParty: {
                              ...relation.relatedParty,
                              ...beneficiaries[beneficiaryIdentifireIndex].relatedParty,
                              contactMethods: {
                                ...relation.relatedParty.contactMethods,
                                ...beneficiaries[beneficiaryIdentifireIndex].relatedParty
                                  .contactMethods,
                              },
                            },
                          }
                        }
                      }
                      return relation
                    }),
                    ...(beneficiariesIndexs.length !== beneficiaries.length
                      ? beneficiaries
                          .filter((b, i) => !beneficiariesIndexs.some(ind => ind === i))
                          .map(b => ({
                            companies: [],
                            role: [POLICY_RELATIONSHIPS_MEMBER],
                            relatedParty: b.relatedParty,
                          }))
                      : []),
                  ],
                }
                return updatedPolicy
              }),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_EXISTING_COVER: {
      const { existingCover } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                ...existingCover,
              }
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_APPLICATION_TYPE: {
      const { applicationType } = action.payload
      return {
        ...state,
        applicationType,
      }
    }

    case CREATE_QUOTE_RESET_QUOTE_ID: {
      return {
        ...state,
        internalQuoteCollectionId: '',
        quotes: state.quotes.map(quote => ({
          ...quote,
          quoteId: '',
        })),
      }
    }

    case ADVISOR_DETAILS_FETCH_SUCCESS: {
      return {
        ...state,
        adviserNumber: pathOr('', 'customerDetails.customerNo', action.payload),
      }
    }

    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    case CREATE_QUOTE_UPDATE_PRINTABLE_ADVISER_DETAILS: {
      const {
        firstName,
        lastName,
        businessName,
        abn,
        address1,
        address2,
        state: stateAddress,
        postCode,
        phone,
        email,
      } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              printableAdviserDetails: {
                firstName,
                lastName,
                businessName,
                abn,
                address1,
                address2,
                state: stateAddress,
                postCode,
                phone,
                email,
              },
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_QUOTE_DATA: {
      const { quotes, productRules, advisorDetails } = action.payload
      const productData = productRules.data
      const { relationships } = quotes[0].policyStructure[0]
      const lifeInsured = relationships.find(relationship =>
        relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
      )

      const lifeInsuredFirstName =
        lifeInsured.relatedParty && get(lifeInsured.relatedParty, 'firstName', '')
      const lifeInsuredLastName =
        lifeInsured.relatedParty && get(lifeInsured.relatedParty, 'lastName', '')
      return {
        ...initialState,
        adviserNumber: get(advisorDetails, 'customerDetails.customerNo', ''),
        bancsAdviserCustomerNo: getAdviserNo() || getBancsCustomerNumber(),
        lifeInsuredFirstName,
        lifeInsuredLastName,
        ...(lifeInsuredFirstName && {
          quoteCollectionName: `${lifeInsuredFirstName} ${lifeInsuredLastName}`,
        }),
        quotes: quotes.map(quote => ({
          ...quote,
          policyStructure: generateThirdPartyPolicyStructure(
            quote.policyStructure,
            productData,
            advisorDetails
          ),
        })),
      }
    }

    case CREATE_ENQUIRY_ID_SUCCESS: {
      const { dataReceived, isPreassessment } = action.payload
      const { quotes } = state
      const underwritingEntityKey = isPreassessment ? 'preAssessmentDetails' : 'underwritingDetails'
      return {
        ...state,
        quotes: quotes.map(quote => ({
          ...quote,
          [underwritingEntityKey]: {
            enquiryId: dataReceived.enquiryId,
            createdOn: dataReceived.creationTime,
            tag: dataReceived.tag,
            ...(!isPreassessment
              ? {
                  underwritingMethod:
                    dataReceived.underwritingMethod ||
                    (quote[underwritingEntityKey] &&
                      quote[underwritingEntityKey].underwritingMethod),
                }
              : {}),
          },
        })),
      }
    }

    case CREATE_CHILD_ENQUIRY_ID_SUCCESS: {
      const { dataReceived, childCI } = action.payload
      const { quotes, activeIndex } = state
      const underwritingEntityKey = 'childCIUnderwritingInfo'
      quotes[activeIndex].policyStructure[childCI.policyStructurePosition].covers[
        childCI.coversPosition
      ][underwritingEntityKey] = {
        enquiryId: dataReceived.enquiryId,
        createdOn: dataReceived.creationTime,
        tag: dataReceived.tag,
      }
      return {
        ...state,
      }
    }
    case CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_SUCCESS: {
      const {
        dataReceived: { outcome, id },
      } = action.payload
      const { quotes, activeIndex } = state
      const activeQuote = quotes[activeIndex]
      const underwritingEntityKey =
        get(activeQuote, 'preAssessmentDetails.enquiryId') === id
          ? 'preAssessmentDetails'
          : 'underwritingDetails'
      return {
        ...state,
        quotes: quotes.map(quote => {
          // eslint-disable-next-line no-param-reassign
          quote[underwritingEntityKey].calculatedUREStatus = outcome
          return quote
        }),
      }
    }

    case CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_FAILURE:
    case CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_ERROR: {
      return {
        ...state,
      }
    }

    case REMOVE_ENQUIRY_ID_URE: {
      const { quotes } = state
      return {
        ...state,
        quotes: quotes.map(quote => {
          const newQuote = { ...quote }
          delete newQuote.preAssessmentDetails
          delete newQuote.underwritingDetails
          return newQuote
        }),
      }
    }

    case UPDATE_UNDER_WRITING_DETAILS: {
      const { quotes } = state
      const { data } = action.payload
      return {
        ...state,
        quotes: quotes.map(quote => ({
          ...quote,
          underwritingDetails: {
            ...quote.underwritingDetails,
            ...data,
          },
        })),
      }
    }

    case UPDATE_ENQUIRY_ID_URE: {
      const { enquiryId, createdOn, underwritingMethod } = action.payload
      const { quotes } = state
      return {
        ...state,
        quotes: quotes.map(quote => ({
          ...quote,
          underwritingDetails: {
            enquiryId,
            createdOn,
            ...(underwritingMethod
              ? {
                  underwritingMethod,
                }
              : {}),
          },
        })),
      }
    }

    case SET_SAVE_QUOTE_INITIATOR: {
      const { saveQuoteInitiator } = action.payload
      return {
        ...state,
        saveQuoteInitiator,
      }
    }

    // currently used when member mandatories change and affect product benefit rules
    case CREATE_QUOTE_UPDATE_POLICY_STRUCTURE: {
      const { policyStructure } = action.payload
      const { activeIndex } = state

      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure,
            }
          }
          return quote
        }),
      }
    }

    case RESET_STATE_FOR_NEW_QUOTE:
    case INIT_STATE: {
      const { adviserNumber, bancsAdviserCustomerNo } = action.payload
      if (adviserNumber && bancsAdviserCustomerNo) {
        initialState.adviserNumber = adviserNumber
        initialState.bancsAdviserCustomerNo = getAdviserNo() || bancsAdviserCustomerNo
      }
      return initialState
    }

    case RESET_CREATE_QUOTE: {
      return initialState
    }

    case CREATE_QUOTE_APPLY_LOADINGS: {
      const { loadings, benefitMasterData } = action.payload

      if (loadings && loadings.length && state.quotes && state.quotes.length) {
        loadings.forEach(loadingItem => {
          state.quotes.map(quote => {
            if (quote.policyStructure && quote.policyStructure.length) {
              quote.policyStructure.map(policy => {
                if (policy.covers && policy.covers.length) {
                  policy.covers.map(cover => {
                    let isExist
                    try {
                      isExist = benefitMasterData
                        .find(benefit => benefit.umeCode === loadingItem.benefit)
                        .covers.some(cty => cty === cover.type)
                    } catch (error) {
                      isExist = false
                    }
                    if (
                      isExist ||
                      (isSGBOBenefit(cover.type) && loadingItem.benefit === IP_COVER_DIGITAL_KEY_IP)
                    ) {
                      const Mloading = Object.assign({}, loadingItem)
                      delete Mloading.benefit
                      // eslint-disable-next-line no-param-reassign
                      cover.loading = [
                        ...(cover.loading && cover.loading.length ? cover.loading : []),
                        Mloading,
                      ]
                    }
                    return cover
                  })
                }
                return policy
              })
            }
            return quote
          })
        })
      }

      return state
    }

    case CREATE_QUOTE_REMOVE_APPLIED_LOADINGS: {
      if (state.quotes && state.quotes.length) {
        state.quotes.map(quote => {
          if (quote.policyStructure && quote.policyStructure.length) {
            quote.policyStructure.map(policy => {
              if (policy.covers && policy.covers.length) {
                policy.covers.map(cover => {
                  // eslint-disable-next-line no-param-reassign
                  cover.loading = []

                  return cover
                })
              }
              return policy
            })
          }
          return quote
        })
      }

      return state
    }

    case CREATE_QUOTE_APPLY_EXCLUSIONS: {
      const { exclusions, benefitMasterData } = action.payload
      if (exclusions && exclusions.length && state.quotes && state.quotes.length) {
        exclusions
          .filter(
            exclusionItem =>
              exclusionItem.saveToCarm && exclusionItem.saveToCarm.toLowerCase() === 'yes'
          )
          .forEach(exclusionItem => {
            state.quotes.map(quote => {
              if (quote.policyStructure && quote.policyStructure.length) {
                quote.policyStructure.map(policy => {
                  if (policy.covers && policy.covers.length) {
                    policy.covers.map(cover => {
                      let isExist

                      try {
                        isExist = benefitMasterData
                          .find(benefit => benefit.umeCode === exclusionItem.benefit)
                          .covers.some(cty => cty === cover.type)
                      } catch (error) {
                        isExist = false
                      }

                      const MlExclusion = Object.assign({}, exclusionItem)
                      if (MlExclusion.benefit === IP_COVER_DIGITAL_KEY_AIP) {
                        if (cover.coverStyle === 'Agreed Value') {
                          isExist = true
                        } else {
                          isExist = false
                        }
                      }
                      if (MlExclusion.benefit === IP_COVER_DIGITAL_KEY_IP) {
                        if (cover.coverStyle === 'Indemnity Value') {
                          isExist = true
                        } else {
                          isExist = false
                        }
                      }

                      // calculate new exclusion based on waiting period
                      let shouldExclusionUpdate = false
                      if (isExist) {
                        delete MlExclusion.benefit
                        delete MlExclusion.saveToCarm
                        if (cover.waitingPeriod) {
                          const { unit, value } = cover.waitingPeriod
                          if (unit && value) {
                            const waitingPeriod = `${value} ${unit}`
                            if (
                              MlExclusion.waitingPeriod &&
                              MlExclusion.waitingPeriod.includes(waitingPeriod)
                            ) {
                              delete MlExclusion.waitingPeriod
                              shouldExclusionUpdate = true
                            }
                          }
                        } else {
                          shouldExclusionUpdate = true
                        }
                      }

                      if (shouldExclusionUpdate) {
                        // eslint-disable-next-line no-param-reassign
                        cover.exclusion = [
                          ...(cover.exclusion && cover.exclusion.length ? cover.exclusion : []),
                          MlExclusion,
                        ]
                      }
                      return cover
                    })
                  }
                  return policy
                })
              }
              return quote
            })
          })
      }

      return state
    }

    case CREATE_QUOTE_REMOVE_APPLIED_EXCLUSION: {
      if (state.quotes && state.quotes.length) {
        state.quotes.map(quote => {
          if (quote.policyStructure && quote.policyStructure.length) {
            quote.policyStructure.map(policy => {
              if (policy.covers && policy.covers.length) {
                policy.covers.map(cover => {
                  // eslint-disable-next-line no-param-reassign
                  cover.exclusion = []

                  return cover
                })
              }
              return policy
            })
          }
          return quote
        })
      }

      return state
    }

    case CREATE_QUOTE_APPLY_GENETIC_TEST_RESULT: {
      const { result } = action.payload
      const { activeIndex, quotes } = state
      return {
        ...state,
        ...(result && {
          quotes: quotes.map((quote, index) => {
            if (index === activeIndex) {
              // eslint-disable-next-line no-param-reassign
              quote.applicationDetails.status.map(item => {
                // eslint-disable-next-line no-param-reassign
                item.missingRequirements = [
                  ...new Set([...item.missingRequirements, GENETIC_TEST_KEY]),
                ]

                return item
              })
            }
            return quote
          }),
        }),
      }
    }
    case ADD_BUSINESS_AS_PAYER: {
      const { activeIndex } = state
      const { memberEntity, sidebarPanelProps, directors } = action.payload
      const { policyInstanceNo, payerType, partyType } = sidebarPanelProps
      const { identifier } = payerType || partyType
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                const updatedPolicy = { ...policy }
                const indexOfBussinessMember = updatedPolicy.relationships.findIndex(
                  relation =>
                    get(relation, 'relatedParty.identifiers[0].value') === identifier.value
                )

                const companyDetail = updatedPolicy.relationships[indexOfBussinessMember]

                if (companyDetail) {
                  const roles = updatedPolicy.relationships[indexOfBussinessMember].role
                  companyDetail.relatedParty = memberEntity
                  companyDetail.companies = directors.map(director => {
                    const finalDirectorData = {}
                    finalDirectorData.isSecretary =
                      director.relatedParty.companyRole !== POLICY_MEMBER_DIRECTOR
                    finalDirectorData.isPrimary = director.isPrimary
                    delete director.isPrimary
                    delete director.sameAsCompanyAddress
                    finalDirectorData.relatedParty = director.relatedParty
                    finalDirectorData.relatedParty.businessName = memberEntity.businessName
                    return finalDirectorData
                  })
                  if (
                    payerType &&
                    !roles.includes(POLICY_RELATIONSHIPS_PAYER) &&
                    policy.policyInstanceNo === policyInstanceNo
                  ) {
                    roles.push(POLICY_RELATIONSHIPS_PAYER)
                  } else if (
                    !payerType &&
                    !roles.includes(POLICY_RELATIONSHIPS_OWNER) &&
                    policy.policyInstanceNo === policyInstanceNo
                  ) {
                    roles.push(POLICY_RELATIONSHIPS_OWNER)
                    companyDetail.isPrimary = true
                  }
                } else {
                  updatedPolicy.relationships = [
                    ...updatedPolicy.relationships,
                    {
                      isPrimary: true,
                      relatedParty: memberEntity,
                      companies: directors.map(director => {
                        const finalDirectorData = {}
                        finalDirectorData.isSecretary =
                          director.relatedParty.companyRole !== POLICY_MEMBER_DIRECTOR
                        finalDirectorData.isPrimary = director.isPrimary
                        finalDirectorData.relatedParty = director.relatedParty
                        finalDirectorData.relatedParty.businessName = memberEntity.businessName
                        if (director.relatedParty.sameAsCompanyAddress) {
                          const addressOfDirector = memberEntity.contactMethods.addresses.find(
                            add => add.preferred === PREFERRED_YES
                          )
                          director.relatedParty.contactMethods.addresses = [
                            {
                              houseNo: get(addressOfDirector, 'houseNo', ''),
                              street: get(addressOfDirector, 'street', ''),
                              locality: get(addressOfDirector, 'locality', ''),
                              state: get(addressOfDirector, 'state', ''),
                              country: get(addressOfDirector, 'country', '') || COUNTRY_CODE,
                              postCode: get(addressOfDirector, 'postCode', ''),
                              preferred: PREFERRED_YES,
                              addressType: ADDRESS_TYPE_STATEMENT,
                            },
                          ]
                        }
                        delete director.sameAsCompanyAddress
                        delete director.isPrimary
                        return finalDirectorData
                      }),

                      role: [
                        POLICY_RELATIONSHIPS_MEMBER,
                        ...(policy.policyInstanceNo === policyInstanceNo
                          ? [payerType ? POLICY_RELATIONSHIPS_PAYER : POLICY_RELATIONSHIPS_OWNER]
                          : []),
                      ],
                    },
                  ]
                }
                return updatedPolicy
              }),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_CHANGE_SMOKER_STATUS: {
      const { currentSmokerStatus } = action.payload
      const { activeIndex, quotes } = state
      return {
        ...state,
        ...(currentSmokerStatus && {
          quotes: quotes.map((quote, index) => {
            if (index === activeIndex) {
              const policies = quote.policyStructure
              // eslint-disable-next-line no-param-reassign
              quote.memberMandatories.isSmoker = currentSmokerStatus

              if (policies && policies.length) {
                policies.map(policy => {
                  const { relationships } = policy
                  if (relationships && relationships.length) {
                    relationships.map(relationship => {
                      if (relationship.role.includes('LA')) {
                        // eslint-disable-next-line no-param-reassign
                        relationship.relatedParty.isSmoker = currentSmokerStatus
                      }

                      return relationship
                    })
                  }
                  return policy
                })
              }
            }
            return quote
          }),
        }),
      }
    }

    case UPDATE_URE_DETAIL_TO_UNDERWRITING_ENTITY: {
      const { data } = action.payload
      const { activeIndex, quotes } = state
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            const { underwritingDetails } = quote
            // eslint-disable-next-line no-param-reassign
            quote.underwritingDetails = {
              ...underwritingDetails,
              ...data.find(entity => !entity.isChildCI).underwritingData,
            }
            if (quote.policyStructure && quote.policyStructure.length) {
              quote.policyStructure.map(policy => {
                if (policy.covers && policy.covers.length) {
                  policy.covers.map(cover => {
                    if (cover.type && cover.type.toLowerCase() === 'child_ci') {
                      const { childCIUnderwritingInfo } = cover
                      const hasData = data.find(
                        entity => entity.enquiryId === childCIUnderwritingInfo.enquiryId
                      )
                      if (hasData && hasData.underwritingData) {
                        // eslint-disable-next-line no-param-reassign
                        cover.childCIUnderwritingInfo = {
                          ...childCIUnderwritingInfo,
                          ...hasData.underwritingData,
                        }
                      }
                    }
                    return cover
                  })
                }
                return policy
              })
            }
          }
          return quote
        }),
      }
    }

    case GET_PAYLOAD_OF_FULL_URE_SUCCESS: {
      const {
        data: { activeQuote },
      } = action.payload
      const { activeIndex, quotes } = state
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex && activeQuote) {
            return activeQuote
          }
          return quote
        }),
      }
    }

    case ADD_TFN: {
      const { activeIndex } = state
      const { tfnval, sidebarPanelProps } = action.payload
      const { policyInstanceNo } = sidebarPanelProps
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                const updatedPolicy = { ...policy }
                if (policy.policyInstanceNo === policyInstanceNo) {
                  const lifeAssuredIndex = updatedPolicy.relationships.findIndex(member =>
                    member.role.find(role => role.includes(POLICY_RELATIONSHIPS_LIFEASSURED))
                  )
                  if (lifeAssuredIndex !== -1) {
                    updatedPolicy.relationships[lifeAssuredIndex].tfn = tfnval
                    if (
                      Object.prototype.hasOwnProperty.call(
                        updatedPolicy.relationships[lifeAssuredIndex].relatedParty,
                        'isTFNProvided'
                      )
                    )
                      delete updatedPolicy.relationships[lifeAssuredIndex].relatedParty
                        .isTFNProvided
                  }
                }
                return updatedPolicy
              }),
            }
          }

          return quote
        }),
      }
    }

    case ADD_OWNER_FOR_NULIS_WRAPPER: {
      const { activeIndex } = state
      const { beneficiaryObj, policyInstanceNo } = action.payload

      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                const updatedPolicy = { ...policy }
                if (policy.policyInstanceNo === policyInstanceNo) {
                  const ownerIndex = updatedPolicy.relationships.findIndex(member =>
                    member.role.find(role => role.includes(POLICY_RELATIONSHIPS_OWNER))
                  )
                  if (ownerIndex === -1) {
                    updatedPolicy.relationships.push(beneficiaryObj[0])
                  }
                }
                return updatedPolicy
              }),
            }
          }
          return quote
        }),
      }
    }

    case ADD_NULIS: {
      const { activeIndex } = state
      const {
        beneficiaryObj,
        sidebarPanelProps,
        consents: consentsPayload,
        contributionType: contributionTypePayload,
      } = action.payload
      const { policyInstanceNo } = sidebarPanelProps

      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                let { relationships, consents, contributionType } = policy
                if (policy.policyInstanceNo === policyInstanceNo) {
                  contributionType =
                    typeof contributionTypePayload.value === 'object'
                      ? contributionTypePayload.value.value
                      : contributionTypePayload.value

                  consents = consentsPayload

                  const ownerIndex = relationships.findIndex(member =>
                    member.role.find(role => role.includes(POLICY_RELATIONSHIPS_OWNER))
                  )
                  if (ownerIndex !== -1) {
                    relationships[ownerIndex] = beneficiaryObj[0]
                  } else {
                    relationships.push(beneficiaryObj[0])
                  }
                  if (beneficiaryObj.length > 1) {
                    relationships = relationships.filter(
                      relationship =>
                        !relationship.role.find(role =>
                          role.includes(POLICY_RELATIONSHIPS_EMPLOYER)
                        )
                    )
                    relationships.push(beneficiaryObj[1])
                  }
                }
                return {
                  ...policy,
                  consents,
                  relationships,
                  contributionType,
                }
              }),
            }
          }

          return quote
        }),
      }
    }

    case UPDATE_WORKTYPE_HISTORY: {
      const { data: currentWorkType } = action.payload
      const existingWorkTypeHistory = state.workTypeHistory
      let workTypeData = currentWorkType ? [currentWorkType] : []
      if (
        existingWorkTypeHistory &&
        existingWorkTypeHistory.length &&
        currentWorkType &&
        currentWorkType.type
      ) {
        const historyIndex = existingWorkTypeHistory.findIndex(
          historyItem => historyItem.type === currentWorkType.type
        )
        workTypeData = [...existingWorkTypeHistory]
        workTypeData[historyIndex] = currentWorkType
      }
      return {
        ...state,
        workTypeHistory: [...workTypeData],
      }
    }

    case DELETE_WORKTYPE_HISTORY: {
      const createQuoteCopy = state
      if (createQuoteCopy.workTypeHistory) delete createQuoteCopy.workTypeHistory
      return {
        ...createQuoteCopy,
      }
    }

    case UPDATE_IS_TELE_FROM_ADVISER: {
      const { isTeleFromAdviser } = action.payload
      return {
        ...state,
        isTeleFromAdviser,
      }
    }

    case CREATE_QUOTE_UPDATE_POLICY_NAME: {
      const { quotes, activeIndex } = state
      const updatedPolicyStructure = quotes[activeIndex].policyStructure.map(policy => {
        const { productId, superFundName, fundPaymentMethod, productName } = policy
        if (productId === POLICY_PRODUCT_CODE_SUPER) {
          const isOwnedByMLCSuper = superFundName === SUPER_OWNER_INTERNAL
          const isOwnedByIOOF = superFundName === SUPER_OWNER_IOOF
          const isOwnedBySMSF = superFundName === SUPER_OWNER_EXTERNAL
          const isWrapPaymentMethod = fundPaymentMethod === WRAPPER_NAVIGATOR_ID
          const modifiedProductName = productName.replace(/ \(Wrap\/SMSF\)| \(Super\)/g, '')
          const splitProductName = modifiedProductName.split('-')
          // eslint-disable-next-line no-param-reassign
          policy.productName = `${splitProductName[0]}${
            isOwnedBySMSF || isOwnedByIOOF || (isOwnedByMLCSuper && isWrapPaymentMethod)
              ? '(Wrap/SMSF)'
              : '(Super)'
          } -${splitProductName[1]}`

          return policy
        }
        return policy
      })
      quotes[activeIndex] = {
        ...quotes[activeIndex],
        policyStructure: updatedPolicyStructure,
      }
      return {
        ...state,
        quotes,
      }
    }
    case CREATE_QUOTE_UPDATE_SMSF_DETAILS: {
      const { activeIndex } = state
      const {
        sidebar: { panelProps: sidebarPanelProps },
        relationship,
      } = action.payload

      const { policyInstanceNo } = sidebarPanelProps

      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                const updatedPolicy = { ...policy }

                const lifeAssured = updatedPolicy.relationships.find(rel =>
                  rel.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
                )

                lifeAssured.fundDetails = {}
                lifeAssured.role = lifeAssured.role.filter(r => r !== POLICY_RELATIONSHIPS_SMSF)
                updatedPolicy.relationships = updatedPolicy.relationships.filter(
                  rel => !rel.role.includes(POLICY_RELATIONSHIPS_SMSF)
                )
                if (policy.policyInstanceNo === policyInstanceNo) {
                  updatedPolicy.relationships = [
                    ...updatedPolicy.relationships,
                    {
                      role: [POLICY_RELATIONSHIPS_SMSF],
                      isAssociatedToSuperFund: false,
                      ...relationship,
                    },
                  ]
                }

                return updatedPolicy
              }),
            }
          }

          return quote
        }),
      }
    }

    case QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_INIT: {
      return {
        ...state,
        isFetchingData: true,
      }
    }

    case QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_SUCCESS: {
      const { businessData } = action.payload
      return {
        ...state,
        ...businessData,
        isFetchingData: false,
        fetchQuoteError: null,
      }
    }
    case QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_FAILURE:
    case QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_ERROR: {
      return {
        ...state,
        isFetchingData: false,
        fetchQuoteError: action.payload,
      }
    }

    case CREATE_QUOTE_UPDATE_CALCULATED_URE_STATUS: {
      const { status } = action.payload
      const { activeIndex, quotes } = state
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            const { underwritingDetails } = quote
            quote.underwritingDetails = {
              ...underwritingDetails,
              calculatedUREStatus: status,
            }
          }
          return quote
        }),
      }
    }

    case RESET_CREATE_AND_SAVE_QUOTE_ERROR_MODAL: {
      return {
        ...state,
        isSaveQuoteErrorModal: false,
        isCalQuoteErrorModal: false,
      }
    }

    case POLICIES_FOR_LA_FETCH_SUCCESS: {
      const {
        businessData: { policies },
      } = action.payload
      const { existingCovers } = getExistingBenefitsFromPolicies(policies)
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                existingCovers,
              }
            : quote
        ),
      }
    }

    case UPDATE_POLICY_REPLACE_STATUS: {
      const { policyIdx, benefitIdx, value } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          const insurancesToUpdate = [...quote.existingCovers.existingInsurances]
          insurancesToUpdate[policyIdx - 1].benefits[benefitIdx - 1].replacingThisCover =
            value.toLowerCase() === BENEFIT_REPLACE
          return index === state.activeIndex
            ? {
                ...quote,
                existingCovers: {
                  ...quote.existingCovers,
                  existingInsurances: quote.existingCovers.existingInsurances
                    ? [...quote.existingCovers.existingInsurances]
                    : [],
                },
              }
            : quote
        }),
      }
    }

    case CREATE_QUOTE_PUSH_EXISTING_COVER: {
      const { existingCoverArray } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex && existingCoverArray
            ? getUpdatedExistingCovers(quote, existingCoverArray)
            : quote
        ),
      }
    }

    case CREATE_QUOTE_UPDATE_TPD_DEFINATION: {
      const {
        data: { policyInstanceNo, benefitInstanceNo, type, value },
      } = action.payload

      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === state.activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                const updatedPolicy = { ...policy }
                if (policy.policyInstanceNo === policyInstanceNo) {
                  updatedPolicy.covers = updatedPolicy.covers.map(cover => {
                    if (cover.type === type && cover.benefitInstanceNo === benefitInstanceNo) {
                      return {
                        ...cover,
                        tpdDefinition: value,
                      }
                    }
                    return cover
                  })
                }
                return updatedPolicy
              }),
            }
          }

          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_IS_NON_STANDARD: {
      const { isNonStandard } = action.payload
      return {
        ...state,
        isNonStandard,
      }
    }

    case CREATE_QUOTE_UPDATE_BMI: {
      const { BMIValue } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === state.activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                relationships: policy.relationships.map(relationship => {
                  const { role } = relationship
                  const updatedRelationship = { ...relationship }
                  if (role.includes('LA')) {
                    updatedRelationship.bmi = BMIValue
                  }
                  return updatedRelationship
                }),
              })),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_SET_IS_SATISFIED: {
      const { isSatisfied } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === state.activeIndex) {
            return {
              ...quote,
              underwritingDetails: {
                ...quote.underwritingDetails,
                isSatisfied,
              },
            }
          }
          return quote
        }),
      }
    }

    case UPDATE_IS_TERMS_ACCEPTED_ON_POLICY_COVER: {
      const { isTermsAccepted, policyIndex, coverIndex } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === state.activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map((policy, pIndex) => {
                if (pIndex === policyIndex) {
                  return {
                    ...policy,
                    covers: policy.covers.map((cover, cIndex) => {
                      if (cIndex === coverIndex) {
                        return {
                          ...cover,
                          isTermsAccepted,
                        }
                      }
                      return cover
                    }),
                  }
                }
                return policy
              }),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_LA_CONTACT_DETAILS: {
      const { email, phone } = action.payload
      const { quotes, activeIndex } = state
      return {
        ...state,
        quotes: quotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                relationships: policy.relationships.map(relationship => {
                  if (relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)) {
                    return {
                      ...relationship,
                      relatedParty: {
                        ...relationship.relatedParty,
                        contactMethods: {
                          ...relationship.relatedParty.contactMethods,
                          emails: relationship.relatedParty.contactMethods.emails.map(emailData => {
                            if (emailData.preferred === PREFERRED_YES) {
                              return {
                                ...emailData,
                                ...email,
                              }
                            }
                            return emailData
                          }),
                          phones: relationship.relatedParty.contactMethods.phones.map(phoneData => {
                            if (phoneData.preferred === PREFERRED_YES) {
                              return {
                                ...phoneData,
                                ...phone,
                              }
                            }
                            return phoneData
                          }),
                        },
                      },
                    }
                  }
                  return relationship
                }),
              })),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_ADD_OPTIMISER_REFERENCE: {
      const { cover, policyInstanceNo, optimiserParentBenefitReference } = action.payload
      const { quotes, activeIndex } = state
      return {
        ...state,
        quotes: quotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                if (policy.policyInstanceNo.toString() === policyInstanceNo.toString()) {
                  return {
                    ...policy,
                    covers: policy.covers.map(policyCover => {
                      if (
                        policyCover.type === cover.type &&
                        policyCover.benefitInstanceNo.toString() ===
                          cover.benefitInstanceNo.toString()
                      ) {
                        return {
                          ...policyCover,
                          optimiserParentBenefitReference,
                        }
                      }
                      return policyCover
                    }),
                  }
                }
                return policy
              }),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_REMOVE_OPTIMISER_REFERENCE: {
      const { cover, policyInstanceNo } = action.payload
      const { quotes, activeIndex } = state
      const activeQuote = quotes[activeIndex]
      let existingCover = null
      activeQuote.policyStructure.some(policy => {
        if (policy.policyInstanceNo.toString() === policyInstanceNo.toString()) {
          return policy.covers.some(policyCover => {
            if (
              policyCover.type === cover.type &&
              policyCover.benefitInstanceNo.toString() === cover.benefitInstanceNo.toString()
            ) {
              existingCover = policyCover
              return true
            }
            return false
          })
        }
        return false
      })
      return {
        ...state,
        quotes: quotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                covers: policy.covers.map(policyCover => {
                  if (
                    policy.policyInstanceNo.toString() === policyInstanceNo.toString() &&
                    policyCover.type === cover.type &&
                    policyCover.benefitInstanceNo.toString() === cover.benefitInstanceNo.toString()
                  ) {
                    // removing optimiserParentBenefitReference from the selected cover
                    delete policyCover.optimiserParentBenefitReference
                    return policyCover
                  }
                  if (
                    existingCover.parentBenefitReference &&
                    policy.policyInstanceNo.toString() ===
                      existingCover.parentBenefitReference.parentPolicyReferenceNo.toString() &&
                    policyCover.type === existingCover.parentBenefitReference.parentType &&
                    policyCover.benefitInstanceNo.toString() ===
                      existingCover.parentBenefitReference.parentBenefitInstanceNo.toString()
                  ) {
                    // removing optimiserParentBenefitReference from parent cover
                    delete policyCover.optimiserParentBenefitReference
                    return policyCover
                  }
                  return policyCover
                }),
              })),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_REMOVE_EMPTY_POLICY: {
      const { activeIndex, quotes } = state
      return {
        ...state,
        quotes: quotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.filter(policy => policy.covers.length),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_LINKED_OPTIMISER: {
      const { cover } = action.payload
      const {
        parentBenefitReference,
        type,
        benefitInstanceNo,
        features,
        premiumStyle,
        policyInstanceNo,
      } = cover
      const { activeIndex, quotes } = state
      const getUpdatedCover = selectedCover => ({
        ...selectedCover,
        features,
        premiumStyle,
      })

      return {
        ...state,
        quotes: quotes.map((quote, quoteIndex) => {
          if (quoteIndex === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                covers: policy.covers.map(policyCover => {
                  // If cover is child optimiser, find its parent and update values
                  if (
                    parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER &&
                    policy.policyInstanceNo.toString() ===
                      parentBenefitReference.parentPolicyReferenceNo.toString() &&
                    policyCover.type === parentBenefitReference.parentType &&
                    policyCover.benefitInstanceNo.toString() ===
                      parentBenefitReference.parentBenefitInstanceNo.toString()
                  ) {
                    return getUpdatedCover(policyCover)
                  }
                  // If cover is parent optimiser, find child optimiser and update values
                  const currentCoverParentBenefitReference = policyCover.parentBenefitReference
                  if (
                    currentCoverParentBenefitReference &&
                    parentBenefitReference.benefitNature !== BENEFIT_NATURE_TYPE_RIDER_OPTIMISER &&
                    policyInstanceNo.toString() ===
                      currentCoverParentBenefitReference.parentPolicyReferenceNo.toString() &&
                    currentCoverParentBenefitReference.parentType === type &&
                    currentCoverParentBenefitReference.parentBenefitInstanceNo.toString() ===
                      benefitInstanceNo.toString()
                  ) {
                    return getUpdatedCover(policyCover)
                  }
                  return policyCover
                }),
              })),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_UPDATE_LINKED_BENEFITS: {
      const { cover, policyInstanceNo, oldCover } = action.payload
      const { benefitInstanceNo: oldBenefitInstanceNo, type: oldBenefitType } = oldCover
      return {
        ...state,
        quotes: state.quotes.map((quote, quoteIndex) => {
          if (quoteIndex === state.activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => ({
                ...policy,
                covers: policy.covers.map(policyCover => {
                  const { parentBenefitReference, optimiserParentBenefitReference } = policyCover
                  const updatedCover = {
                    ...policyCover,
                    parentBenefitReference: {
                      ...policyCover.parentBenefitReference,
                    },
                  }
                  if (
                    parentBenefitReference &&
                    parentBenefitReference.parentType === oldBenefitType &&
                    parentBenefitReference.parentBenefitInstanceNo.toString() ===
                      oldBenefitInstanceNo.toString() &&
                    parentBenefitReference.parentPolicyReferenceNo.toString() ===
                      policyInstanceNo.toString()
                  ) {
                    updatedCover.parentBenefitReference.parentType = cover.type
                    updatedCover.parentBenefitReference.parentBenefitInstanceNo =
                      cover.benefitInstanceNo
                    updatedCover.parentBenefitReference.parentBenefitCode = cover.type
                    updatedCover.type = isIPCover(cover)
                      ? getSGBOBenefitCode(cover.type)
                      : updatedCover.type
                  }
                  if (
                    optimiserParentBenefitReference &&
                    optimiserParentBenefitReference.parentType === oldBenefitType &&
                    optimiserParentBenefitReference.parentBenefitInstanceNo.toString() ===
                      oldBenefitInstanceNo.toString() &&
                    optimiserParentBenefitReference.parentPolicyReferenceNo.toString() ===
                      policyInstanceNo.toString()
                  ) {
                    updatedCover.optimiserParentBenefitReference.parentType = cover.type
                    updatedCover.optimiserParentBenefitReference.parentBenefitInstanceNo =
                      cover.benefitInstanceNo
                  }
                  return updatedCover
                }),
              })),
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_CLEAR_POLICY_SETUP_DATA: {
      const { activeIndex } = state
      const { policyInstanceNo } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                const policyUpdate = { ...policy }
                if (policyInstanceNo && policyUpdate.policyInstanceNo === policyInstanceNo) {
                  if (policyUpdate.beneficiaries) delete policyUpdate.beneficiaries
                  if (policyUpdate.contributionType) delete policyUpdate.contributionType

                  const relatioshipsLA = policyUpdate.relationships.filter(relationship =>
                    relationship.role.includes('LA')
                  )

                  const newRelationships = relatioshipsLA.map(relationship => {
                    const relationshipUpdate = { ...relationship }
                    if (
                      relationshipUpdate.relatedParty &&
                      relationshipUpdate.relatedParty.isTFNProvided
                    ) {
                      relationshipUpdate.relatedParty.isTFNProvided = 'No'
                    }
                    if (relationshipUpdate.tfn) delete relationshipUpdate.tfn
                    return relationshipUpdate
                  })

                  policyUpdate.relationships = newRelationships
                }
                return { ...policyUpdate }
              }),
            }
          }
          return quote
        }),
      }
    }

    case UPDATE_SUBMITTED_DATE: {
      const { submittedDate } = action.payload
      return {
        ...state,
        submittedDate,
      }
    }

    case UPDATE_MEMBER_MANDATORIES: {
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              memberMandatories: action.payload.memberMandatories,
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_SET_REQUESTED_BY: {
      const { requestedBy } = action.payload
      return {
        ...state,
        requestedBy,
      }
    }

    case CREATE_QUOTE_SET_ALTERATION_TYPE: {
      const { alterationType } = action.payload
      return {
        ...state,
        alterationType,
      }
    }

    case CREATE_QUOTE_SET_QUOTE_COLLECTION_ID: {
      const { quoteCollectionId } = action.payload
      return {
        ...state,
        quoteCollectionId,
      }
    }

    case CREATE_QUOTE_SET_ADVISER_NUMBER: {
      const { adviserNumber } = action.payload
      return {
        ...state,
        adviserNumber,
      }
    }

    case CREATE_QUOTE_SET_APPLICATION_DETAILS: {
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              applicationDetails: action.payload.applicationDetails,
            }
          }
          return quote
        }),
      }
    }

    case CREATE_QUOTE_SET_QUOTE_CUSTOMER_NO: {
      const { quoteCustomerNo } = action.payload
      return {
        ...state,
        quoteCustomerNo,
      }
    }

    /**
     * Update the contactMethods from memberDetail for each
     * matching relationship in store based on bancsCustomerNo
     */
    case UPDATE_CONTACT_METHODS_OF_MEMBER: {
      const { activeIndex } = state
      const { bancsCustomerNo, memberDetails } = action.payload

      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(eachPolicyStructure => ({
                ...eachPolicyStructure,
                relationships: constructRelationshipsFromPolicy(
                  eachPolicyStructure.relationships.map(eachRelation => ({
                    ...eachRelation,
                    relatedParty: {
                      ...eachRelation.relatedParty,
                      contactMethods:
                        bancsCustomerNo === get(eachRelation, 'bancsCustomerNo', '')
                          ? get(memberDetails, 'contactMethods', {})
                          : eachRelation.relatedParty.contactMethods,
                    },
                  })),
                  true
                ),
              })),
            }
          }
          return quote
        }),
      }
    }

    case ALTER_SUM_INSURED: {
      const { quotes, activeIndex } = state
      const { cover, sumInsured, bancsPolicyNo, policyInstanceNo, isPremiumCalculator } =
        action.payload
      const { optimiserParentBenefitReference } = cover
      // Check weather the policy is altered
      // if not then effective date is modified
      let previousPolicyAltered = false
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          previousPolicyAltered = get(policy, 'alteration.isPolicyAltered', false)
          let newAlteredBenefits = updateSumInsuredInAlteredBenefits(
            get(policy, 'alteration.alteredBenefits'),
            cover,
            sumInsured
          )
          if (isPremiumCalculator) {
            const linkedBenefitForSumInsured = getLinkedBenefitForSumInsured(cover, policy)
            if (linkedBenefitForSumInsured) {
              const { linkedBenefit, maxSumInsured } = linkedBenefitForSumInsured
              newAlteredBenefits = updateSumInsuredInAlteredBenefits(
                newAlteredBenefits,
                linkedBenefit,
                sumInsured < maxSumInsured ? sumInsured : maxSumInsured
              )
            }
          }
          return {
            ...policy,
            alteration: {
              ...policy.alteration,
              isPolicyAltered: true,
              ...(!previousPolicyAltered && {
                policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                  policy,
                  quotes[activeIndex].policyStructure
                ),
              }),
              // for orchestrator to know to only request a price for the policy and linked policy
              calculatePolicyPrice: true,
              alteredBenefits: newAlteredBenefits,
            },
          }
        }
        if (
          optimiserParentBenefitReference &&
          optimiserParentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
        ) {
          const childOptimisedBenefit = getOptimisedChildBenefit(cover, policy, policyInstanceNo)
          previousPolicyAltered = get(policy, 'alteration.isPolicyAltered', false)
          if (childOptimisedBenefit) {
            return {
              ...policy,
              alteration: {
                ...policy.alteration,
                isPolicyAltered: true,
                ...(!previousPolicyAltered && {
                  policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                    policy,
                    quotes[activeIndex].policyStructure
                  ),
                }),
                calculatePolicyPrice: true,
                alteredBenefits: updateSumInsuredInAlteredBenefits(
                  get(policy, 'alteration.alteredBenefits'),
                  childOptimisedBenefit,
                  sumInsured
                ),
              },
            }
          }
        }
        return policy
      })
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure,
            }
          }
          return quote
        }),
      }
    }

    case RESET_SUM_INSURED: {
      const { quotes, activeIndex } = state
      const { cover, bancsPolicyNo, policyInstanceNo, isPremiumCalculator } = action.payload
      const { optimiserParentBenefitReference } = cover
      // Check weather the policy is altered or not after removal
      // if not then effective date is modified
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          let updatedPolicy = resetSumInsuredInPolicy(policy, cover)
          if (isPremiumCalculator) {
            const linkedBenefitForSumInsured = getLinkedBenefitForSumInsured(cover, policy)
            if (linkedBenefitForSumInsured) {
              updatedPolicy = resetSumInsuredInPolicy(
                updatedPolicy,
                linkedBenefitForSumInsured.linkedBenefit
              )
            }
          }
          return updatedPolicy
        }
        if (
          optimiserParentBenefitReference &&
          optimiserParentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
        ) {
          const childOptimisedBenefit = getOptimisedChildBenefit(cover, policy, policyInstanceNo)
          if (childOptimisedBenefit) {
            return resetSumInsuredInPolicy(policy, childOptimisedBenefit)
          }
        }
        return policy
      })

      const isAlterationsInQuote = getIsAlterationInPolicies(policyStructure)

      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure: isAlterationsInQuote
                ? policyStructure
                : removeAlterationsInPolicyStructure(policyStructure),
            }
          }
          return quote
        }),
      }
    }

    case REMOVE_BENEFIT_OPTION: {
      const { quotes, activeIndex } = state
      const { cover, bancsPolicyNo, featureName, policyInstanceNo } = action.payload
      const { optimiserParentBenefitReference } = cover
      let previousPolicyAltered = false
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          previousPolicyAltered = get(policy, 'alteration.isPolicyAltered', false)
          return {
            ...policy,
            alteration: {
              ...policy.alteration,
              isPolicyAltered: true,
              ...(!previousPolicyAltered && {
                policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                  policy,
                  quotes[activeIndex].policyStructure
                ),
              }),
              calculatePolicyPrice: true,
              alteredBenefits: removeBenefitOptionForPolicy(
                get(policy, 'alteration.alteredBenefits'),
                cover,
                featureName
              ),
            },
          }
        }
        if (
          optimiserParentBenefitReference &&
          optimiserParentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
        ) {
          const childOptimisedBenefit = getOptimisedChildBenefit(cover, policy, policyInstanceNo)
          if (childOptimisedBenefit) {
            previousPolicyAltered = get(policy, 'alteration.isPolicyAltered', false)
            return {
              ...policy,
              alteration: {
                ...policy.alteration,
                isPolicyAltered: true,
                ...(!previousPolicyAltered && {
                  policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                    policy,
                    quotes[activeIndex].policyStructure
                  ),
                }),
                calculatePolicyPrice: true,
                alteredBenefits: removeBenefitOptionForPolicy(
                  get(policy, 'alteration.alteredBenefits'),
                  childOptimisedBenefit,
                  featureName
                ),
              },
            }
          }
        }
        return policy
      })

      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure,
            }
          }
          return quote
        }),
      }
    }

    case CANCEL_BENEFIT_OPTION: {
      const { quotes, activeIndex } = state
      const { cover, bancsPolicyNo, featureName, policyInstanceNo } = action.payload
      const { optimiserParentBenefitReference } = cover
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          const updatedPolicy = cancelBenefitOptionForPolicy(policy, cover, featureName)
          return updatedPolicy
        }
        if (
          optimiserParentBenefitReference &&
          optimiserParentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
        ) {
          const childOptimisedBenefit = getOptimisedChildBenefit(cover, policy, policyInstanceNo)
          if (childOptimisedBenefit) {
            return cancelBenefitOptionForPolicy(policy, childOptimisedBenefit, featureName)
          }
        }
        return policy
      })

      const isAlterationsInQuote = getIsAlterationInPolicies(policyStructure)

      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure: isAlterationsInQuote
                ? policyStructure
                : removeAlterationsInPolicyStructure(policyStructure),
            }
          }
          return quote
        }),
      }
    }

    case CHANGE_WAITING_BENEFIT_PERIOD: {
      const { quotes, activeIndex } = state
      const { cover, bancsPolicyNo, value, isBenefitPeriod } = action.payload
      let previousPolicyAltered = false
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          previousPolicyAltered = get(policy, 'alteration.isPolicyAltered', false)
          return {
            ...policy,
            alteration: {
              ...policy.alteration,
              isPolicyAltered: true,
              ...(!previousPolicyAltered && {
                policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                  policy,
                  quotes[activeIndex].policyStructure
                ),
              }),
              calculatePolicyPrice: true,
              alteredBenefits: changeWaitingPeriodForPolicy(
                get(policy, 'alteration.alteredBenefits'),
                cover,
                value,
                isBenefitPeriod
              ),
            },
          }
        }
        return policy
      })

      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure,
            }
          }
          return quote
        }),
      }
    }

    case RESET_WAITING_BENEFIT_PERIOD: {
      const { quotes, activeIndex } = state
      const { cover, bancsPolicyNo, isBenefitPeriod = false } = action.payload
      // Check weather the policy is altered or not after removal
      // if not then effective date is modified
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          const updatedPolicy = resetWaitingBenefitPeriod(policy, cover, isBenefitPeriod)
          return updatedPolicy
        }
        return policy
      })

      const isAlterationsInQuote = getIsAlterationInPolicies(policyStructure)
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure: isAlterationsInQuote
                ? policyStructure
                : removeAlterationsInPolicyStructure(policyStructure),
            }
          }
          return quote
        }),
      }
    }

    case ALTS_REMOVE_BENEFIT: {
      const { quotes, activeIndex } = state
      const { cover, bancsPolicyNo, policyInstanceNo } = action.payload
      const { optimiserParentBenefitReference } = cover
      let previousPolicyAltered = false
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          previousPolicyAltered = get(policy, 'alteration.isPolicyAltered', false)
          return {
            ...policy,
            alteration: {
              ...policy.alteration,
              isPolicyAltered: true,
              ...(!previousPolicyAltered && {
                policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                  policy,
                  quotes[activeIndex].policyStructure
                ),
              }),
              calculatePolicyPrice: true,
              alteredBenefits: removeBenefitInAlteredBenefits(
                get(policy, 'alteration.alteredBenefits'),
                cover
              ),
            },
          }
        }
        if (
          optimiserParentBenefitReference &&
          optimiserParentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
        ) {
          const childOptimisedBenefit = getOptimisedChildBenefit(cover, policy, policyInstanceNo)
          if (childOptimisedBenefit) {
            previousPolicyAltered = get(policy, 'alteration.isPolicyAltered', false)
            return {
              ...policy,
              alteration: {
                ...policy.alteration,
                isPolicyAltered: true,
                ...(!previousPolicyAltered && {
                  policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                    policy,
                    quotes[activeIndex].policyStructure
                  ),
                }),
                calculatePolicyPrice: true,
                alteredBenefits: removeBenefitInAlteredBenefits(
                  get(policy, 'alteration.alteredBenefits'),
                  childOptimisedBenefit
                ),
              },
            }
          }
        }
        return policy
      })

      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure,
            }
          }
          return quote
        }),
      }
    }

    case ALTER_PAYMENT_FREQUENCY: {
      const { quotes, activeIndex } = state
      const { newPremiumPayingFrequency, bancsPolicyNo } = action.payload
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          const previousPolicyAltered = get(policy, 'alteration.isPolicyAltered', false)
          return {
            ...policy,
            alteration: {
              ...policy.alteration,
              isPolicyAltered: true,
              ...(!previousPolicyAltered && {
                policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                  policy,
                  quotes[activeIndex].policyStructure
                ),
              }),
              calculatePolicyPrice: true,
              newPremiumPayingFrequency,
            },
          }
        }
        return policy
      })

      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure,
            }
          }
          return quote
        }),
      }
    }

    case RESET_PAYMENT_FREQUENCY: {
      const { quotes, activeIndex } = state
      const { bancsPolicyNo } = action.payload
      const policyStructure = quotes[activeIndex].policyStructure.map(policy => {
        if (policy.bancsPolicyNo === bancsPolicyNo) {
          delete policy.alteration.newPremiumPayingFrequency
          if (!policy.alteration?.alteredBenefits?.length) {
            delete policy.alteration
          }
          return {
            ...policy,
            ...(policy.alteration?.alteredBenefits?.length && {
              alteration: {
                ...policy.alteration,
                calculatePolicyPrice: true,
              },
            }),
          }
        }
        return policy
      })

      const isAlterationsInQuote = getIsAlterationInPolicies(policyStructure)
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quotes[activeIndex],
              policyStructure: isAlterationsInQuote
                ? policyStructure
                : removeAlterationsInPolicyStructure(policyStructure),
            }
          }
          return quote
        }),
      }
    }

    case ALTS_CALCULATE_QUOTE_SUCCESS: {
      const { businessData } = action.payload
      return {
        ...state,
        ...businessData,
        hasCalculationError: false,
      }
    }
    case ALTS_CALCULATE_QUOTE_FAILURE:
    case ALTS_CALCULATE_QUOTE_ERROR: {
      return {
        ...state,
        hasCalculationError: true,
      }
    }

    case DELETE_ALTERATIONS_IN_QUOTE: {
      const { quotes, activeIndex } = state
      return {
        ...state,
        hasCalculationError: false,
        quotes: quotes.map((eachQuote, index) => {
          if (index === activeIndex) {
            return {
              ...eachQuote,
              policyStructure: eachQuote.policyStructure.map(item => {
                const { alteration, ...rest } = item
                return rest
              }),
            }
          }
          return eachQuote
        }),
      }
    }

    // update Quote option when edited.
    case CREATE_QUOTE_UPDATE_OPTION: {
      const { option } = action.payload
      return {
        ...state,
        quotes: state.quotes.map(
          // insert the policy structure at the active index.
          (quote, index) =>
            index === state.activeIndex
              ? {
                  ...quote,
                  option: {
                    ...quote.option,
                    ...option,
                  },
                }
              : quote
        ),
      }
    }
    case CREATE_QUOTE_UPDATE_HL_CAMPAIGN: {
      const { activeIndex } = state
      const { healthyLivingDiscount, eligibleHealthyCovers } = action.payload
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === activeIndex
            ? {
                ...quote,
                policyStructure: quote.policyStructure.map(policy => ({
                  ...policy,
                  covers: policy.covers.map(cover => {
                    const clonedCover = { ...cover }
                    const coverType =
                      policy.productId === POLICY_PRODUCT_CODE_NON_SUPER
                        ? `${POLICY_NON_SUPER_BENEFIT_PREFIX}${clonedCover.type}`
                        : clonedCover.type
                    /**
                     * here we are checking if cover is not eligible for healthy living
                     * then we are returning the same because if it is not eligible then
                     *  it does not have campaign detail property
                     */
                    if (!eligibleHealthyCovers.includes(coverType)) return clonedCover
                    if (
                      !Object.prototype.hasOwnProperty.call(clonedCover, 'campaignDetail') &&
                      !Array.isArray(clonedCover.campaignDetail)
                    )
                      // on success of calculate quote campaignDetail is getting undefined
                      clonedCover.campaignDetail = []
                    const healthyLivingIndex = clonedCover.campaignDetail.findIndex(
                      object => object.campaignCode === HEALTHY_LIVING_DISCOUNT
                    )
                    /**
                     * healthyLivingDiscount toggle is true and if healthy living is not
                     * present in campaignDetail then we are adding healthy living in
                     * campaigndetail and if healthyliving toggle is false and if
                     *  healthy living is present in campaignDetail then we are
                     *  removing healthy living from campaignDetail
                     *  */
                    if (healthyLivingDiscount) {
                      if (healthyLivingIndex < 0) {
                        clonedCover.campaignDetail.push({
                          campaignCode: HEALTHY_LIVING_DISCOUNT,
                          campaignName: HEALTHY_LIVING_DISCOUNT_TEXT,
                        })
                      }
                    } else if (healthyLivingIndex > -1) {
                      // it means it has only healthyLiving object so
                      // we are removing campaignDetail property with value
                      if (clonedCover.campaignDetail.length === 1) delete clonedCover.campaignDetail
                      else clonedCover.campaignDetail.splice(healthyLivingIndex, 1)
                    }
                    return clonedCover
                  }),
                })),
              }
            : quote
        ),
      }
    }

    // Adding healthy living discount consent in quote consents at the active index
    case CREATE_QOUTE_SET_HEALTHY_LIVING_DISCOUNT_CONSENT: {
      const { isVivoIncentiveDiscountApplied } = action.payload
      return {
        ...state,
        quotes: state.quotes.map(
          // insert the vivo incentive result consent at the active index.
          (quote, index) =>
            index === state.activeIndex
              ? {
                  ...quote,
                  consents: uniqBy(
                    [
                      {
                        name: HEALTHY_LIVING_DISCOUNT_CONSENT,
                        value: isVivoIncentiveDiscountApplied,
                      },
                      ...quote.consents,
                    ],
                    'name'
                  ),
                }
              : quote
        ),
      }
    }

    // Adding healthy living disount in missingRequirements in quotes based on URE status
    case CREATE_QUOTE_ADD_HEALTHY_LIVING_DISCOUNT_IN_MISSING_REQUIREMENTS: {
      const { activeIndex, quotes } = state
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            quote.applicationDetails.status.map(item => {
              item.missingRequirements = [
                ...new Set([
                  ...item.missingRequirements,
                  HEALTHY_LIVING_DISCOUNT_MISSING_REQUIREMENT,
                ]),
              ]
              return item
            })
          }
          return quote
        }),
      }
    }

    case UPDATE_ALTS_DOWNLOAD_QUOTE_PROGRESS: {
      const { isDownloadQuoteInProgress } = action.payload
      return {
        ...state,
        isDownloadQuoteInProgress,
      }
    }

    case UPDATE_ALTS_EFFECTIVE_DATES: {
      const { activeIndex, quotes } = state
      return {
        ...state,
        quotes: quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy => {
                if (policy?.alteration?.isPolicyAltered) {
                  return {
                    ...policy,
                    alteration: {
                      ...policy.alteration,
                      policyAlterationEffectiveDate: getPolicyAlterationEffectiveDate(
                        policy,
                        quotes[activeIndex].policyStructure
                      ),
                    },
                  }
                }
                return policy
              }),
            }
          }
          return quote
        }),
      }
    }

    case UPDATE_DETAILS_FOR_CONTACT: {
      const { payload } = action
      return {
        ...state,
        ...payload,
      }
    }

    case CREATE_QUOTE_QUICK_QUOTE_SUCCESS: {
      const { quoteIndex, quote } = action.payload
      return {
        ...state,
        quotes: state.quotes.map(
          // insert the updated quote at the correct index.
          (q, index) => (index === quoteIndex ? quote : q)
        ),
      }
    }

    default: {
      return state
    }
  }
}

export default createQuote
