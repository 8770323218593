import {
  SET_TRUSTEE_UPDATE_NOTIFICATION,
  SHOW_CALL_TO_ACTION_POP_UP,
} from '../types/trusteeReplacement'

export const setTrusteeUpdateNotification = (isNotification: boolean) => ({
  type: SET_TRUSTEE_UPDATE_NOTIFICATION,
  payload: isNotification,
})

export const handlePaymentPopupDisplay = (showPaymentPopup: boolean) => ({
  type: SHOW_CALL_TO_ACTION_POP_UP,
  payload: showPaymentPopup,
})
