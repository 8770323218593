import { Variables } from '@mlcl-digital/mlcl-design'
import type { CSSObject } from '@emotion/styled'
import type { VariablesType } from '../../../types/ComponentLibrary'

const { space } = Variables as VariablesType

const styles: Record<string, CSSObject> = {
  wrapper: {
    marginTop: space(5),
    marginBottom: space(5),
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    columnGap: space(2.5),
  },
  wrapperEven: {
    '> *': {
      flex: '1',
    },
  },
  wrapperMainLeft: {
    '> :first-child': {
      flexBasis: '68.75%',
    },
  },
  wrapperMainRight: {
    '> :last-child': {
      flexBasis: '68.75%',
    },
  },
}

export default styles
