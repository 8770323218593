import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { space, colours, fontWeights } = Variables as VariablesType

const styles = {
  financialAdviserContainer: {
    padding: `${space(4, true)} ${space(3, true)}`,
    h2: {
      color: colours.mlcAquaAccessible,
    },
    '> dl': {
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      gridRowGap: space(2, true),
      span: {
        color: colours.tertiaryOne,
        fontWeight: fontWeights.bold,
        paddingLeft: space(1),
      },
      a: {
        textDecoration: 'none',
        color: colours.mlcAquaAccessible,
      },
    },
  },
}

export default styles
