import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Heading, Checkbox as CheckboxComponent, ToggleGroup } from '@mlcl-digital/mlcl-design'
// @ts-expect-error non-ts-code
import * as actions from '../../../../../actions'
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import {
  getHasMedicalAuthorityBeenCompleted,
  getMedicalAuthorityValues,
} from '../../../../../selectors/consents'

// Components
// @ts-expect-error non-TS-code
import Accordion from '../Accordion'

// Styles
import styles from './MedicalAuthorityDeclaration.styles'

import { SitecoreField } from '../../../../../types/sitecore'

const RichTextContainer = styled('div')(styles.container)
const Divider = styled('div')(styles.divider)
const Checkbox = styled(CheckboxComponent)(styles.checkbox)

type Props = {
  handleToggle: (key: string) => void
  handleInCompleteAccordion: () => void
  isOpen: boolean
  fields: {
    MedicalAuthorityLabel: SitecoreField
    MedicalAuthorityInfoHeader: SitecoreField
    MedicalAuthorityInfoBody: SitecoreField
    MedicalAuthorityOneHeader: SitecoreField
    MedicalAuthorityOneBody: SitecoreField
    MedicalAuthorityOneConsent: SitecoreField
    MedicalAuthorityTwoHeader: SitecoreField
    MedicalAuthorityTwoBody: SitecoreField
    MedicalAuthorityTwoConsent: SitecoreField
    MedicalAuthorityTwoConsentYes: SitecoreField
    MedicalAuthorityTwoConsentNo: SitecoreField
  }
  lifeInsuredName: string
}

const MedicalAuthorityDeclaration = (props: Props) => {
  const { actionCreators } = actions
  const dispatch = useDispatch()
  const { fields, handleToggle, isOpen, handleInCompleteAccordion, lifeInsuredName } = props
  const {
    MedicalAuthorityTwoConsentYes,
    MedicalAuthorityTwoConsentNo,
    MedicalAuthorityTwoConsent,
    MedicalAuthorityLabel,
  } = reduceAuthorableFields(fields) as {
    MedicalAuthorityTwoConsentYes: string
    MedicalAuthorityTwoConsentNo: string
    MedicalAuthorityTwoConsent: string
    MedicalAuthorityLabel: string
  }

  const { MAOne, MATwo } = useSelector(getMedicalAuthorityValues)

  const [MAOneConsent, toggleMAOneConsent] = useState(!!MAOne?.value)
  const [MATwoConsent, toggleMATwoConsent] = useState(
    typeof MATwo?.value === 'undefined' ? 'unknown' : (MATwo?.value && 'yes') || 'no'
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.updateMedicalAuthorityConsents(MAOneConsent, MATwoConsent === 'yes'))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.saveQuote())
  }, [MAOneConsent, MATwoConsent])

  const handleContinue = useCallback(() => {
    handleInCompleteAccordion()
  }, [MAOneConsent, MATwoConsent])

  const hasMedicalAuthorityBeenCompleted = useSelector(getHasMedicalAuthorityBeenCompleted)

  return (
    <>
      <Accordion
        label={`${MedicalAuthorityLabel} ${lifeInsuredName}`}
        fields={{
          applicationDeclarationContinueButton: {
            value: 'Confirm',
          },
        }}
        checked={hasMedicalAuthorityBeenCompleted}
        isOpen={isOpen}
        continueHandler={() => {
          handleContinue()
        }}
        toggleListener={handleToggle('medicalAuthority')}
        inProgress={false}
        disableScrollContent
        disableButton={MATwoConsent === 'unknown' || !MAOneConsent}
      >
        <RichTextContainer>
          <Heading variant="h2" size="LGR">
            {renderTextField(fields.MedicalAuthorityInfoHeader)}
          </Heading>
          {renderTextField(fields.MedicalAuthorityInfoBody, true)}
        </RichTextContainer>
        <RichTextContainer>
          <Heading variant="h2" size="LGR">
            {renderTextField(fields.MedicalAuthorityOneHeader)}
          </Heading>
          {renderTextField(fields.MedicalAuthorityOneBody, true)}
          <Checkbox
            onChangeHandler={() => toggleMAOneConsent(!MAOneConsent)}
            checked={MAOneConsent}
            text={renderTextField(fields.MedicalAuthorityOneConsent)}
            name="MAOneConsent"
            htmlFor="MAOneConsent"
            value="true"
          />
        </RichTextContainer>
        <Divider />
        <RichTextContainer>
          <Heading variant="h2" size="LGR">
            {renderTextField(fields.MedicalAuthorityTwoHeader)}
          </Heading>
          {renderTextField(fields.MedicalAuthorityTwoBody, true)}
        </RichTextContainer>
        <ToggleGroup
          htmlFor="MATwoConsent"
          name="MATwoConsent"
          handleChange={(event: { label: string; value: string }) =>
            toggleMATwoConsent(event.value)
          }
          label={MedicalAuthorityTwoConsent}
          variant="stacked"
          value={MATwoConsent}
          error={false}
          options={[
            {
              label: MedicalAuthorityTwoConsentYes,
              value: 'yes',
            },
            {
              label: MedicalAuthorityTwoConsentNo,
              value: 'no',
            },
          ]}
        />
      </Accordion>
    </>
  )
}

export default MedicalAuthorityDeclaration
