// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Heading, Button, Notification, Loader } from '@mlcl-digital/mlcl-design'
import Link from '@mlcl-digital/mlcl-design/lib/base/Link'
import { createEvent } from '../../../../utils/telemetry'
import { actionCreators } from '../../../../actions'

import BusinessDetails from '../BusinessDetails'

// selectors
import {
  getClientCurrentContactDetails,
  getCurrentPersonalDetails,
} from '../../../../selectors/personalDetails'
import { checkIfAllPoliciesLoaded } from '../../../../selectors/common'
import { getAllPolicyOwnersBancsCustomerNos } from '../../../../selectors/customerPolicies'

// components
import ListOfActiveAuthorities from './components/ListOfActiveAuthorities'

// styles.
import styles from './customerPersonalDetails.styles'

// helper
import FileUpload from '../../../molecules/FileUpload'

// utils
import { getBancsCustomerNumber } from '../../../../utils/cookieUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { browserOrigin } from '../../../../utils/browserUtils'
import { getDocumentTypeDetails } from '../../../../utils/fileUpload'
import { isFeatureEnabled } from '../../../../utils/featureToggling'

// constants.
import { DOC_TYPE_DOC } from '../../../../constants/documentTypes'
import { FORM_ID } from '../../../molecules/ContactDetails/ContactDetailsForm/contactDetailsForm.schema'
import { EXP_CUSTOMER_STATUS_LOADING } from '../../../../constants/apis'
import { GENERIC_ERROR_MESSAGE } from '../../../../constants/forms'

const Wrap = styled('div')(styles.base)
const Header = styled('div')(styles.header)
const Details = styled('div')(styles.details)
const DetailsLabel = styled('div')(styles.label)
const EditButton = styled(Button)(styles.button)
const ValueHeading = styled('div')(styles.values)
const Authorities = styled('div')(styles.authorities)

const authorityAddButtonLabel = 'Add new'

type CustomerPersonalDetailsFormProps = {
  actions: Object,
  isManualResidential: Boolean,
  fields: {
    customerPersonalDetailsFullName: string,
    customerPersonalDetailsTabTitle: string,
    customerPersonalDetailsCustomerId: string,
    customerPersonalDetailsDateOfBirth: string,
    customerDetailsEditButton: string,
    customerContactDetails: string,
  },
  details: {
    title: string,
    firstName: string,
    lastName: string,
    middleName: string,
    dateOfBirth: string,
    contactMethods: Array<Object>,
    identifiers: Array<Object>,
  },
  // isBusiness flag for policyOwner tobe business
  isBusiness: Boolean,
  // master data objectFit
  masterList: Object,
  // welcome journey completed flag
  hasCompletedWelcomeJourney: Boolean,
  fileUploadInfo: Object,
  customerCurrentPersonalDetails: Object,
  customerContactDetails: Object,
  // sitecore rendering
  rendering: Object,
  isManualPostal: Boolean,
  haveAllPoliciesLoaded: Boolean,
  canShowActiveAuthoritiesSection: Boolean,
  doesPolicyHaveMultiplePolicyOwners: Boolean,
  hasLoaFailed: Boolean,
  isLetterOfAuthorityEnabled: Boolean,
}

export class CustomerPersonalDetailsForm extends Component<CustomerPersonalDetailsFormProps> {
  displayEditModal = () => {
    const {
      actions: { toggleFileUploadModal },
    } = this.props

    const tagEvent = createEvent({
      GA: {
        category: 'Personal details',
        action: 'Edit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Personal details - Edit',
        },
      },
    })
    tagEvent.end()

    toggleFileUploadModal(true)
  }

  renderPersonalDetails = () => {
    const { fields, customerCurrentPersonalDetails } = this.props

    const { fullName, dateOfBirth, partyNumber } = customerCurrentPersonalDetails
    return (
      <Fragment>
        <Header>
          <Heading size="large" variant="h2" styleOverrides={{ marginBottom: '6px' }}>
            {renderTextField(fields.customerPersonalDetailsTabTitle)}
          </Heading>
          <p>{renderTextField(fields.customerPersonalDetailsSubtitle)}</p>
        </Header>
        <Heading size="small" variant="h3" styleOverrides={styles.subHeading}>
          {renderTextField(fields.customerPersonalDetailsSubHeading)}
        </Heading>
        <Details>
          <DetailsLabel>{renderTextField(fields.customerPersonalDetailsFullName)}</DetailsLabel>
          <ValueHeading>{fullName}</ValueHeading>
        </Details>
        <Details>
          <DetailsLabel>{renderTextField(fields.customerPersonalDetailsCustomerId)}</DetailsLabel>
          <ValueHeading>{partyNumber}</ValueHeading>
        </Details>
        <Details>
          <DetailsLabel>{renderTextField(fields.customerPersonalDetailsDateOfBirth)}</DetailsLabel>
          <ValueHeading>{dateOfBirth}</ValueHeading>
        </Details>
        <Details>
          <EditButton variant="secondary" size="small" onClick={this.displayEditModal}>
            {renderTextField(fields.customerDetailsEditButton)}
          </EditButton>
        </Details>
      </Fragment>
    )
  }

  renderActiveAuthorities = () => {
    const {
      fields,
      haveAllPoliciesLoaded,
      doesPolicyHaveMultiplePolicyOwners,
      hasLoaFailed,
      isBusiness,
    } = this.props
    return (
      <Authorities>
        {haveAllPoliciesLoaded ? (
          <>
            <Header>
              <Heading size="large" variant="h2" styleOverrides={{ marginBottom: '6px' }}>
                {renderTextField(fields.activeAuthoritiesHeader)}
              </Heading>
              <p>{renderTextField(fields.activeAuthoritiesSubtitle)}</p>
            </Header>
            {!doesPolicyHaveMultiplePolicyOwners && !hasLoaFailed && !isBusiness && (
              <Link variant="secondary" size="small" to="/customerPersonalDetails/add-authority">
                {authorityAddButtonLabel}
              </Link>
            )}
            {hasLoaFailed && <Notification variant="error">{GENERIC_ERROR_MESSAGE}</Notification>}

            {(doesPolicyHaveMultiplePolicyOwners || isBusiness) && (
              <Notification variant="info">
                {renderTextField(fields.activeAuthoritiesDisabledMessage, true)}
              </Notification>
            )}
            <ListOfActiveAuthorities
              canRemoveAuthorities={!doesPolicyHaveMultiplePolicyOwners && !isBusiness}
              fields={fields}
            />
          </>
        ) : (
          <Loader />
        )}
      </Authorities>
    )
  }

  renderContactDetails = () => {
    const {
      isManualResidential,
      hasCompletedWelcomeJourney,
      customerContactDetails,
      rendering,
      isManualPostal,
      isBusiness,
    } = this.props

    return (
      <Fragment>
        {rendering && rendering.placeholders['contact-details'] && (
          <Placeholder
            name="contact-details"
            rendering={rendering}
            isManualResidential={isManualResidential}
            hasCompletedWelcomeJourney={hasCompletedWelcomeJourney}
            customerContactDetails={customerContactDetails}
            isManualPostal={isManualPostal}
            isPartyTypeOrg={isBusiness}
          />
        )}
      </Fragment>
    )
  }

  renderFileUpload = () => {
    const {
      fields,
      fileUploadInfo: { isModalOpen },
      masterList,
    } = this.props

    const {
      customerUploadFileHeading,
      customerUploadFileModalSubHeading,
      customerUploadFileConfirmationButtonLabel,
      customerUploadFileConfirmationFormMaxFileCount,
      customerUploadFileFormName,
      customerUploadFileFormCode,
      customerUploadFileFormMaxFileSize,
      customerUploadFileFormMaxFileSizeError,
      customerUploadFileFormWrongMimeType,
      customerUploadFileFormUploading,
      customerUploadFileFormUploadSuccess,
      customerUploadFileFormUploadFailure,
      customerUploadFileFormWorkItemFailure,
      customerUploadFileFormWorkItemSuccess,
      customerUploadFileFormFileRemoveLabel,
      customerUploadFileFormNoFileError,
      customerUploadFileFileDownloadLabel,
      customerUploadFileFormWrongFileNameError,
    } = reduceAuthorableFields(fields)

    const documentTypes = get(masterList, 'data.documentTypes', [])
    const documentEntity = getDocumentTypeDetails(customerUploadFileFormName, documentTypes)
    const personalDetailsDocumentLink = documentEntity.length && get(documentEntity[0], 'link', '')

    const href = `<a href=${
      browserOrigin() + personalDetailsDocumentLink
    } target=_blank>${customerUploadFileFileDownloadLabel}</a>`

    return (
      <Fragment>
        {isModalOpen && (
          <FileUpload
            formDownloadMeta={{
              documentName: customerUploadFileFormName,
              documentPath: { value: href },
              docSubTypeCode: customerUploadFileFormCode,
            }}
            modalMeta={{
              modalHeading: customerUploadFileHeading,
              modalSubHeading: customerUploadFileModalSubHeading,
              modalConfirmButton: customerUploadFileConfirmationButtonLabel,
            }}
            dropzoneMeta={{
              maxFileNumber: customerUploadFileConfirmationFormMaxFileCount,
              maxFileSize: customerUploadFileFormMaxFileSize,
            }}
            fileUploadMeta={{
              maxFileSizeError: customerUploadFileFormMaxFileSizeError,
              wrongFileType: customerUploadFileFormWrongMimeType,
              fileUploading: customerUploadFileFormUploading,
              fileUploadSuccess: customerUploadFileFormUploadSuccess,
              fileUploadFailure: customerUploadFileFormUploadFailure,
              createWorkItemError: customerUploadFileFormWorkItemFailure,
              createWorkItemSuccess: customerUploadFileFormWorkItemSuccess,
              fileRemoveButtonLabel: customerUploadFileFormFileRemoveLabel,
              noFileUploadedError: customerUploadFileFormNoFileError,
              wrongFileNameError: customerUploadFileFormWrongFileNameError,
            }}
            createWorkItemRequest={{
              workTypeCode: documentEntity.length && get(documentEntity[0], 'workType', ''),
              bancsPolicyNo: getBancsCustomerNumber,
            }}
            uploadDocumentRequest={{
              docSubTypeCode: documentEntity.length && get(documentEntity[0], 'documentCode', ''),
              docType: DOC_TYPE_DOC,
              workType: documentEntity.length && get(documentEntity[0], 'workType', ''),
            }}
            analytics={{
              category: 'Personal details edit modal',
              action: 'CP - update personal details',
            }}
          />
        )}
      </Fragment>
    )
  }

  renderBusinessDetails = () => {
    const { fields, fileUploadInfo, hasCompletedWelcomeJourney } = this.props

    return (
      <BusinessDetails
        fileUploadInfo={fileUploadInfo}
        fields={fields}
        hasCompletedWelcomeJourney={hasCompletedWelcomeJourney}
      />
    )
  }

  render() {
    const {
      isBusiness,
      hasCompletedWelcomeJourney,
      canShowActiveAuthoritiesSection,
      isLetterOfAuthorityEnabled,
    } = this.props

    return (
      <Fragment>
        {!isBusiness && (
          <Wrap>
            {hasCompletedWelcomeJourney && this.renderPersonalDetails()}
            {this.renderContactDetails()}
            {this.renderFileUpload()}
          </Wrap>
        )}
        {isBusiness && <Fragment>{this.renderBusinessDetails()}</Fragment>}
        {hasCompletedWelcomeJourney &&
          isLetterOfAuthorityEnabled &&
          canShowActiveAuthoritiesSection &&
          this.renderActiveAuthorities()}
      </Fragment>
    )
  }
}

export const mapStateToProps = state => {
  const {
    forms,
    addressLookup,
    fileUploadInfo,
    masterList,
    authentication: { hasCompletedWelcomeJourney },
    customerPolicySummaryStatus,
    letterOfAuthority,
    config,
  } = state
  return {
    hasLoaFailed: letterOfAuthority.hasError,
    fileUploadInfo,
    isLetterOfAuthorityEnabled: isFeatureEnabled('LetterOfAuthority', config.FEATURES),
    form: forms[FORM_ID],
    isManualResidential:
      addressLookup.residentialAddress && addressLookup.residentialAddress.isManual,
    isManualPostal: addressLookup.postalAddress && addressLookup.postalAddress.isManual,
    masterList,
    hasCompletedWelcomeJourney,
    customerContactDetails: getClientCurrentContactDetails(state),
    customerCurrentPersonalDetails: getCurrentPersonalDetails(state),
    canShowActiveAuthoritiesSection:
      customerPolicySummaryStatus.isLoading !== EXP_CUSTOMER_STATUS_LOADING,
    haveAllPoliciesLoaded: checkIfAllPoliciesLoaded(state),
    doesPolicyHaveMultiplePolicyOwners:
      getAllPolicyOwnersBancsCustomerNos(state).filter(bcn => bcn !== 'NULIS' && bcn !== 'IIML')
        .length > 1,
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPersonalDetailsForm)
