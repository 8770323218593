// @flow
import moment from 'moment'
import get from 'lodash/get'

// constants
import {
  PAYMENT_HISTORY_DATE_FORMAT,
  POLICY_FREQUENCY_MONTHLY,
  POLICY_FREQUENCY_BIANNUAL,
  POLICY_FREQUENCY_ANNUAL,
} from '../constants/policies'
import { REPORTING_PAYMENT_FREQUENCY_OPTIONS } from '../constants/forms'

import directDebitSchema, {
  FORM_ID as directDebitSchemaId,
  // @ts-expect-error non TS code
} from '../components/organisms/AddPayers/components/PaymentDetails/DirectDebit/directDebit.schema'

import creditCardSchema, {
  FORM_ID as creditCardSchemaId,
  // @ts-expect-error non TS code
} from '../components/organisms/AddPayers/components/PaymentDetails/CreditCard/creditCard.schema'

import linkedMasterkeySchema, {
  FORM_ID as linkedMasterkeyId,
  // @ts-expect-error non TS code
} from '../components/organisms/AddPayers/components/PaymentDetails/LinkedMasterKey/linkedMasterKey.schema'

import wrapSuperSmsfSchema, {
  FORM_ID as wrapSuperSmsfSchemaId,
  // @ts-expect-error non TS code
} from '../components/organisms/AddPayers/components/PaymentDetails/WrapSuperSmf/wrapSuperSmf.schema'

import rolloverPaymentMethodSchema, {
  FORM_ID as rolloverPaymentMethodSchemaId,
  // @ts-expect-error non TS code
} from '../components/organisms/AddPayers/components/PaymentDetails/RolloverPaymentMethod/rolloverPaymentMethod.schema'

import {
  CC,
  DD,
  WRAP,
  MASTER_KEY_ACCOUNT,
  ROLLOVER_FROM_EXTERNAL_FUND,
  BPAY,
  CHEQUE,
} from '../constants/customerPaymentDetails'

// types
import { historyRecord, Policy } from '../types/ClientPolicies'
import { Policy as PolicyItem } from '../types/createQuote'
import { PolicyWithPaymentHistoryAndIdentifier, Schema } from '../types/payment'
import { isSitecoreField } from './sitecoreUtils'

export const getActivePaymentDetailsForm = (
  paymentMethod: string,
  {
    fields,
  }: {
    fields: Record<string, unknown>
  },
  displayCardListing?: boolean
): {
  activeFormId: string
  activeSchema: Schema | null
} => {
  let activeFormId
  let activeSchema
  switch (paymentMethod) {
    case DD: {
      activeFormId = directDebitSchemaId
      activeSchema = directDebitSchema({ fields })
      break
    }
    case CC: {
      activeFormId = creditCardSchemaId
      activeSchema = creditCardSchema({ fields }, displayCardListing)
      break
    }
    case WRAP: {
      activeFormId = wrapSuperSmsfSchemaId
      activeSchema = wrapSuperSmsfSchema
      break
    }
    case MASTER_KEY_ACCOUNT: {
      activeFormId = linkedMasterkeyId
      activeSchema = linkedMasterkeySchema()({}) as Schema
      break
    }
    case ROLLOVER_FROM_EXTERNAL_FUND: {
      activeFormId = rolloverPaymentMethodSchemaId
      activeSchema = rolloverPaymentMethodSchema(fields)
      break
    }
    default: {
      activeFormId = null
      activeSchema = null
    }
  }
  return {
    activeFormId,
    activeSchema,
  }
}
const COLLECTION_FREQUENCY: Record<string, string> = {
  MON: 'Monthly',
  QTR: 'Quarterly',
  BIAN: 'Half - Yearly',
  ANN: 'Annual',
  SNGL: 'Single',
}
export const getCollectionFrequency = (frequency: string | undefined) =>
  (frequency && COLLECTION_FREQUENCY[frequency]) || ''

const PREMIUM_COLLECTION_FREQUENCY: Record<string, string> = {
  MON: 'month',
  QTR: 'quarter',
  BIAN: 'half-year',
  ANN: 'year',
  SNGL: 'single',
}
export const getCollectionFrequencyUnit = (frequency: string) =>
  PREMIUM_COLLECTION_FREQUENCY[frequency] || ''

export const isWithinOneYear = (paymentEntry: Pick<historyRecord, 'paymentDate'>) => {
  const currentTime = moment()
  const oneYearBack = moment().subtract(1, 'years')
  const paymentDate = moment(paymentEntry.paymentDate, PAYMENT_HISTORY_DATE_FORMAT)
  return paymentDate.isBetween(oneYearBack, currentTime)
}

export const getPolicyID = (policy: Pick<Policy, 'identifiers'>) => {
  const identifiers = get(policy, 'identifiers', [])
  const policyIdIndex =
    identifiers && identifiers.length
      ? identifiers.findIndex(item => item.type === 'POLICY_ID')
      : -1
  return policyIdIndex !== -1 ? identifiers[policyIdIndex].value : ''
}
export const extractPaymentHistory = (policies: PolicyWithPaymentHistoryAndIdentifier[]) => {
  const paymentHistory = policies
    ? policies.map(entity =>
        entity && entity.policy && entity.policy.paymentHistory
          ? { ...entity.policy.paymentHistory, policyId: getPolicyID(entity.policy) }
          : null
      )
    : []

  const accumulatedHistory: (historyRecord & { policyId: string })[] = []
  paymentHistory.forEach(policyPaymentHistory => {
    if (policyPaymentHistory && policyPaymentHistory.history) {
      policyPaymentHistory.history.forEach(historyItem => {
        if (isWithinOneYear(historyItem))
          accumulatedHistory.push({ ...historyItem, policyId: policyPaymentHistory.policyId })
      })
    }
  })
  return accumulatedHistory
}

export const getPaymentFrequency = (
  activePolicy: Pick<PolicyItem, 'paymentFrequency'>,
  fields: Record<string, unknown>
) => {
  const frequencyKey = fields[`paymentDetailsPaymentFrequency${activePolicy.paymentFrequency}`]
  if (frequencyKey && isSitecoreField(frequencyKey)) {
    return frequencyKey.value
  }
  return null
}

export const getEligiblePaymentFrequency = (premiumPaymentMethod: string) => {
  switch (premiumPaymentMethod) {
    case ROLLOVER_FROM_EXTERNAL_FUND: {
      return [POLICY_FREQUENCY_ANNUAL]
    }
    case BPAY:
    case CHEQUE: {
      return [POLICY_FREQUENCY_BIANNUAL, POLICY_FREQUENCY_ANNUAL]
    }
    default: {
      return [POLICY_FREQUENCY_MONTHLY, POLICY_FREQUENCY_BIANNUAL, POLICY_FREQUENCY_ANNUAL]
    }
  }
}

export const getPaymentFrequencyOptions = (
  premiumPaymentMethod: string,
  paymentFrequency: string
) => {
  const eligiblePaymentFrequency = getEligiblePaymentFrequency(premiumPaymentMethod)
  return REPORTING_PAYMENT_FREQUENCY_OPTIONS.reduce(
    (paymentFrequencyOptions: Array<{ label: string; value: string }>, option) => {
      if (eligiblePaymentFrequency.includes(option.value) || paymentFrequency === option.value) {
        paymentFrequencyOptions.push(option)
      }
      return paymentFrequencyOptions
    },
    []
  )
}
