import {
  BPAY,
  CHEQUE,
  CREDIT_CARD_ID,
  DIRECT_DEBIT_ID,
  MLC_ACC,
  ROLL_OVER_ID,
} from '../constants/policies'
import { REPORTING_PAYMENT_FREQUENCY_OPTIONS } from '../constants/forms'
import { PaymentDetails } from '../types/ClientPolicies'
import {
  ExplainerTextData,
  CoverGroups,
  CoverGroup,
  ListItemWithHeading,
  FeatureList,
  TypeDetails,
  PremiumCalculatorQuotePreviewFields,
} from '../types/components/PremiumCalculator'
// @ts-expect-error non-ts code
import { isSGBOBenefit } from './extendedQuoteUtils'
import { getPaymentMethod, sourceFundSystem2paymentMethod } from './paymentMethods'

export const fetchExplainerTextData = (
  coverGroups: CoverGroups[]
): {
  premiumStyle: string[]
  coversExplainerText: ExplainerTextData[]
} => {
  const selectedPremiumStyles: string[] = []
  const explainerTextData: ExplainerTextData[] = coverGroups.map((group: CoverGroups) => {
    const selectedTPDDefinition: string[] = []
    const selectedCoverStyle: string[] = []
    const selectedBenefitTypes: TypeDetails[] = []
    let showWaitingPeriod = false
    let showBenefitPeriod = false
    const features: FeatureList[] = group.coverGroup.flatMap((policy: CoverGroup) => {
      const selectedFeatures: FeatureList[] = []
      policy.covers.forEach(cover => {
        const {
          premiumStyle,
          tpdDefinition,
          type,
          benefitType = '',
          benefitDescription = '',
          featuresList = [],
          benefitAsOptionsList = [],
          subBenefitsAsOptionsList = [],
          coverStyle,
          waitingPeriod,
          coverPeriod,
        } = cover
        selectedPremiumStyles.push(premiumStyle)
        if (tpdDefinition) {
          selectedTPDDefinition.push(tpdDefinition)
        }
        if (!showWaitingPeriod && waitingPeriod) {
          showWaitingPeriod = true
        }
        if (!showBenefitPeriod && coverPeriod) {
          showBenefitPeriod = true
        }
        if (coverStyle) {
          selectedCoverStyle.push(coverStyle)
        }
        selectedBenefitTypes.push({
          type,
          heading: benefitType,
          description: benefitDescription,
        })
        selectedFeatures.push(...featuresList, ...benefitAsOptionsList, ...subBenefitsAsOptionsList)
      })
      return selectedFeatures
    })

    return {
      benefitTypes: Array.from(
        selectedBenefitTypes
          .reduce((map, item) => map.set(item.type, item), new Map<string, TypeDetails>())
          .values()
      ),
      tpdDefinition: [...new Set(selectedTPDDefinition)],
      showWaitingPeriod,
      showBenefitPeriod,
      hideOptionsText: selectedBenefitTypes.some(benefit => isSGBOBenefit(benefit.type)),
      coverStyle: [...new Set(selectedCoverStyle)],
      coverGroupName: group.coverGroupName,
      featuresList: Array.from(
        features
          .reduce((map, item) => {
            // SWP option is present for both CI versions, explainer text is different.
            const key = `${item.featureName}_${item.displayedFeatureName}`
            if (!map.has(key)) {
              map.set(key, item)
            }
            return map
          }, new Map())
          .values()
      ),
    }
  })
  return {
    premiumStyle: [...new Set(selectedPremiumStyles)],
    coversExplainerText: explainerTextData,
  }
}

export const getOptionDetails = (
  field: ListItemWithHeading,
  key: string,
  benefitType?: string,
  productCode?: string,
  duration?: string
): { headingText: string; description: string; readonly: boolean } => {
  const matchingData = field.fields?.Data?.find(dataItem => {
    const codeValue = dataItem?.fields?.Code.value
    const benefitTypes = dataItem?.fields?.BenefitType
    const productTypes = dataItem?.fields?.ProductType
    const optionDuration = dataItem?.fields?.Duration?.value
    if (optionDuration) {
      return (
        codeValue === key &&
        benefitTypes?.some(type => type.fields.Key.value === benefitType) &&
        productTypes?.some(type => type.fields.Key.value === productCode) &&
        duration === optionDuration
      )
    }
    return (
      codeValue === key &&
      benefitTypes?.some(type => type.fields.Key.value === benefitType) &&
      productTypes?.some(type => type.fields.Key.value === productCode)
    )
  })
  return {
    headingText: matchingData?.fields?.HeadingText?.value ?? '',
    description: matchingData?.fields?.Description?.value ?? '',
    readonly: matchingData?.fields?.ReadOnly?.value ?? false,
  }
}

export const getSectionDetails = (
  field: ListItemWithHeading,
  key: string,
  productCode?: string
): { headingText: string; description: string } => {
  const matchingData = field.fields?.Data?.find(dataItem => {
    const codeValue = dataItem?.fields?.Code.value
    const productTypes = dataItem?.fields?.ProductType
    if (productCode) {
      const isProductTypePresent = field.fields?.Data?.some(item => {
        if (item.fields && item.fields.ProductType) {
          // Check if the product code exists in any ProductType Key field
          return item.fields.ProductType.some(
            productType => productType.fields && productType.fields.Key.value === productCode
          )
        }
        return false
      })
      if (isProductTypePresent) {
        return (
          codeValue === key && productTypes?.some(type => type.fields.Key.value === productCode)
        )
      }
    }
    return codeValue === key
  })
  return {
    headingText: matchingData?.fields?.HeadingText?.value ?? '',
    description: matchingData?.fields?.Description?.value ?? '',
  }
}

export const getPaymentDetails = (
  fields: PremiumCalculatorQuotePreviewFields,
  code: string,
  paymentDetails: PaymentDetails | undefined
) => {
  const paymenDetailsLabelMap: Record<string, string> = {
    [CREDIT_CARD_ID]: `${fields?.PaidByCreditCard?.value} ${
      paymentDetails?.creditCardDetails?.panID as string
    }`,
    [DIRECT_DEBIT_ID]: `${fields?.PaidByBankAccount?.value} ${
      paymentDetails?.debitCardDetails?.accountNo.substring(
        Math.max(0, paymentDetails?.debitCardDetails?.accountNo.length - 4)
      ) as string
    }`,
    [ROLL_OVER_ID]: `${fields?.PaidBy?.value} ${
      paymentDetails?.fundDetails?.externalFundName || getPaymentMethod(code)
    }`,
    [CHEQUE]: fields?.NotAvailable?.value,
    [BPAY]: fields?.NotAvailable?.value,
    [MLC_ACC]: `${fields?.PaidBy?.value} ${getPaymentMethod(
      sourceFundSystem2paymentMethod[paymentDetails?.fundDetails?.sourceFundingSystem ?? '']
    )}`,
  }
  return paymenDetailsLabelMap[code] || fields?.PaymentNotFound?.value
}

export const getExistingAndUpdatedPaymentFrequency = (
  paymentFrequency: string,
  newPremiumPayingFrequency: string | undefined
) => {
  const paymentFrequencyOption = REPORTING_PAYMENT_FREQUENCY_OPTIONS.find(
    frequency => frequency.value === paymentFrequency
  )
  // existing payment frequency
  const existingPaymentFrequency = {
    label: paymentFrequencyOption?.label?.toLowerCase(),
    value: paymentFrequencyOption?.value,
  }
  const updatedPaymentFrequencyOption = REPORTING_PAYMENT_FREQUENCY_OPTIONS.find(
    frequency => frequency.value === newPremiumPayingFrequency
  )
  // updated data for payment frequency
  // if payment frequency not altered, it will have value of existing frequency
  const updatedPaymentFrequency = updatedPaymentFrequencyOption
    ? {
        label: updatedPaymentFrequencyOption?.label?.toLowerCase(),
        value: updatedPaymentFrequencyOption?.value,
      }
    : existingPaymentFrequency
  return {
    existingPaymentFrequency,
    updatedPaymentFrequency,
  }
}
