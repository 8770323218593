import { Variables } from '@mlcl-digital/mlcl-design'

import { VariablesType } from '../../../../types/ComponentLibrary'

const { space, pxToRem, colours, fontWeights } = Variables as VariablesType

const anchorStyles = {
  color: colours.mlcAquaAccessible,
  textDecoration: 'none',
}
const styles = {
  errorMessage: {
    display: 'flex',
    marginBottom: space(5, true),
    '> div': {
      background: colours.functionalDangerLight,
      color: colours.functionalDangerDark,
      padding: space(2),
      fontWeight: fontWeights.semiBold,
      svg: {
        marginRight: space(1.5),
        paddingTop: space(0.5),
      },
    },
  },
  sidePanelContent: {
    color: colours.tertiaryOne,
    padding: space(3, true),
    '& a': {
      ...anchorStyles,
    },
  },
  quoteChangesContainer: {
    marginTop: space(4),
    ul: {
      display: 'flex',
      flexDirection: 'column' as const,
      li: {
        display: 'flex',
        padding: `${space(1, true)} 0`,
        color: colours.tertiaryOne,
        flexDirection: 'row' as const,
        h4: {
          marginBottom: space(0),
        },
        '> div': {
          '&:first-of-type': {
            fontSize: pxToRem(24),
            padding: `${space(0.5, true)} ${space(0.75, true)}`,
            borderRadius: space(2.5),
            border: '2px solid',
            backgroundColor: colours.tertiarySix,
            color: colours.mlcAquaAccessible,
            marginRight: space(2),
          },
          display: 'flex',
        },
        alignItems: 'center',
        marginBottom: space(1),
      },
    },
  },
  disclaimer: {
    fontSize: pxToRem(14),
    color: colours.tertiaryTwo,
    margin: `${space(5, true)} 0 ${space(20, true)} 0`,
    '> div:first-of-type': {
      fontWeight: fontWeights.semiBold,
      marginBottom: space(2),
    },
    '& a': {
      ...anchorStyles,
    },
  },
  buttonContainer: {
    display: 'flex',
    marginTop: space(3),
    'a:first-of-type': {
      marginRight: space(2),
      lineHeight: pxToRem(18),
    },
  },
}

export default styles
