import {
  MLC_ACC,
  NAVIGATOR,
  CAPSIL,
  PLUM,
  WRAP,
  SOURCE_FUNDING_SYSTEM_COMPASS,
  MASTER_KEY_ACCOUNT_ID,
  PLUM_SUPER_ID,
  WRAPPER_NAVIGATOR_ID,
  FUND_PAYMENT_METHODS_IOOF,
} from '../constants/policies'
import { historyRecord, PaymentDetails } from '../types/ClientPolicies'
import { SafePick } from '../types/common'

const cashReceiptSubtypes: Record<string, string> = {
  WO: 'Write Off',
  ET: 'Electronic Transfer',
  ME: 'MLCI Eligible Account',
  ER: 'E-Rollover',
  CQ: 'Cheque',
  IN: 'Internal Transfer',
  BP: 'B-Pay',
  CC: 'Credit Card',
}

export const getPaymentMethod = (code: string | null, type?: string): string => {
  switch (code) {
    case 'CC':
      return 'Credit Card'
    case 'DD':
      return 'Direct Debit'
    case 'CHEQUE':
    case 'CQ':
      return 'Cheque'
    case 'BPAY':
    case 'BP':
      return 'BPAY'
    case 'EC':
      return 'E-Contribution'
    case 'ER':
      return 'E-Rollover'
    case 'EMA':
    case 'ME':
      return 'Masterkey Account'
    case 'WRAP':
      return 'Wrap/Navigator Account'
    case 'IFEX':
      return 'Expand Extra Super'
    case 'IFES':
      return 'Expand Essential Super'
    case 'IFPE':
      return 'IOOF Personal Super'
    case 'IFSS':
      return 'Shadforth Portfolio Service - Super'
    case 'IFXI':
      return 'Expand Extra Investment'
    case 'IFEI':
      return 'Expand Essential Investment'
    case 'PLUM':
      return 'Plum Super'
    case 'CS':
    case 'RC':
      return (type && cashReceiptSubtypes[type]) ?? 'Other'
    default:
      return '-'
  }
}

const creditCardType2Display: Record<string, string> = {
  MC: 'Mastercard',
  VI: 'Visa',
}

export const sourceFundSystem2paymentMethod: Record<string, string> = {
  [SOURCE_FUNDING_SYSTEM_COMPASS]: MASTER_KEY_ACCOUNT_ID,
  [CAPSIL]: MASTER_KEY_ACCOUNT_ID,
  [PLUM]: PLUM_SUPER_ID,
  [NAVIGATOR]: WRAPPER_NAVIGATOR_ID,
  [WRAP]: WRAPPER_NAVIGATOR_ID,
  [FUND_PAYMENT_METHODS_IOOF[0]]: FUND_PAYMENT_METHODS_IOOF[0],
  [FUND_PAYMENT_METHODS_IOOF[1]]: FUND_PAYMENT_METHODS_IOOF[1],
  [FUND_PAYMENT_METHODS_IOOF[2]]: FUND_PAYMENT_METHODS_IOOF[2],
  [FUND_PAYMENT_METHODS_IOOF[3]]: FUND_PAYMENT_METHODS_IOOF[3],
  [FUND_PAYMENT_METHODS_IOOF[4]]: FUND_PAYMENT_METHODS_IOOF[4],
  [FUND_PAYMENT_METHODS_IOOF[5]]: FUND_PAYMENT_METHODS_IOOF[5],
}

export const getPaymentCollectionMethod = (
  premiumPaymentMethod: string | null,
  paymentDetails?: {
    fundDetails?: SafePick<PaymentDetails['fundDetails'], 'sourceFundingSystem'>
    creditCardDetails?: SafePick<PaymentDetails['creditCardDetails'], 'cardType'>
    collectionMethod?: PaymentDetails['collectionMethod']
  }
) => {
  const paymentMethod = paymentDetails?.collectionMethod || premiumPaymentMethod
  // This is for an edge case where we get premiumPaymentMethod incorrectly
  // as all payment types are suspended
  if (premiumPaymentMethod && (!paymentDetails || Object.keys(paymentDetails).length === 0)) {
    if (!['CHEQUE', 'CQ', 'BP', 'BPAY', 'EC'].includes(premiumPaymentMethod)) {
      return '-'
    }
  }
  if (paymentMethod === MLC_ACC) {
    const sourceFundSystem = paymentDetails?.fundDetails?.sourceFundingSystem ?? ''
    return getPaymentMethod(sourceFundSystem2paymentMethod[sourceFundSystem])
  }
  if (paymentMethod === 'CC') {
    const creditCardType = creditCardType2Display[paymentDetails?.creditCardDetails?.cardType ?? '']
    return `${getPaymentMethod(paymentMethod)}${creditCardType ? ` (${creditCardType})` : ''}`
  }
  return getPaymentMethod(paymentMethod)
}

export const getPaymentCollectionMethodOnPaymentHistory = (
  _: PaymentDetails | undefined | null,
  historyItem: Partial<Pick<historyRecord, 'paymentMethod' | 'paymentType' | 'sourceFundingSystem'>>
) => {
  let { paymentMethod = '' } = historyItem
  const { paymentType, sourceFundingSystem } = historyItem
  // sourceFundingSystem of Navigator can never have a payment history of non Navigator payments
  if (paymentMethod === MLC_ACC) {
    paymentMethod = sourceFundSystem2paymentMethod[sourceFundingSystem ?? '']
  }
  return getPaymentMethod(paymentMethod, paymentType)
}
