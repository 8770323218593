/* eslint-disable no-param-reassign */
// @flow
import React, { PureComponent, Fragment } from 'react'
import get from 'lodash/get'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ApplicationDeclarationComponent from '../../../organisms/ApplicationDeclaration'

import PSChangeModalComponent from '../../../organisms/CompletionMethod/components/PSChangeModal'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

// actions
import { actionCreators } from '../../../../actions'
import { NAVIGATION_CUSTOMER_PERSONAL_STATEMENT } from '../../../../constants/navigation'
import { handleUREByOtherParty } from '../../../../utils/quoteUtils'
import { getUREStatus, getActiveBenefitList, sortUreBenefitList } from '../../../../utils/ureUtils'
import WithLoader from '../../../molecules/WithLoader'

import {
  IP_COVER_DIGITAL_KEY_AIP,
  IP_COVER_DIGITAL_KEY_IP,
  IP_COVER_STYLE_AGREED,
  IP_WAIT_PERIOD_WEEKWISE,
  BE_COVER_DIGITAL_KEY_BE,
  CALCULATED_URE_TERM,
  DECISON_TYPE_REFER,
  CALCULATED_URE_REFERRED,
  CALCULATED_URE_ACCEPTED,
} from '../../../../constants/ure'
import { CUSTOMER_PORTAL } from '../../../../constants/site'
import { POLICY_BENEFIT_CI_PLUS } from '../../../../constants/policies'
import { TELE, MYLINK } from '../../../../constants/application'
import { getIsUREHealthyLivingDiscount } from '../../../../utils/commonUtils'
import { hasSeriesCodeExpired } from '../../../../utils/extendedQuoteUtils'
import {
  getHealthyEligibleCovers,
  getIsHealthyLivingDisount,
} from '../../../../selectors/common.selectors'
import { isFeatureEnabled } from '../../../../utils/featureToggling'

type ApplicationDeclarationProps = {
  fields: Object<Object>,
  actions: Object<Object>,
  isOpenUWChangeModal: Boolean,
  createQuote: Object<Object>,
  ure: Object<Object>,
  eligibleHealthyCovers: Array<string>,
  isHealthyLivingDiscount: Boolean,
  isFromCustomerPortal: Boolean,
  products: Object,
  isUreAdditionalInfoFeature: Boolean,
}

export class ApplicationDeclaration extends PureComponent<ApplicationDeclarationProps> {
  componentDidMount() {
    const {
      actions: { changeNavigationType, fetchFullUREdata, updateLoading },
      createQuote,
      ure: {
        answers,
        activeEnquiryIds,
        initialData: { umeBenefits, umeExpiryPeriod },
      },
    } = this.props
    changeNavigationType(NAVIGATION_CUSTOMER_PERSONAL_STATEMENT)
    // show app has beeen assigned to other party
    handleUREByOtherParty(createQuote)

    const unifiedEnquiryIds = activeEnquiryIds.map(data => data.enquiryId)

    const enquiries = Object.keys(answers)
      .filter(answerId => unifiedEnquiryIds.includes(answerId))
      .map(answerId => ({
        ...answers[answerId],
        expiry: {
          noOfDays: umeExpiryPeriod,
          createdOn: get(
            activeEnquiryIds.find(enquiry => enquiry.enquiryId === answerId),
            'createdOn'
          ),
        },
      }))

    fetchFullUREdata({
      enquiries,
      umeBenefits,
    })

    updateLoading([])
  }

  componentDidUpdate(prevProps) {
    const {
      ure: { isLoading: prevIsloading },
    } = prevProps
    const {
      ure: { isLoading },
    } = this.props

    if (prevIsloading && !isLoading) {
      this.saveLoadings()
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleUWCloseModal = () => {
    const {
      actions: { closeUWMethodChangeModal },
    } = this.props
    closeUWMethodChangeModal()
  }

  getConsolidatedFullUreResults() {
    const {
      ure: { fullUREresult },
    } = this.props

    // TODO: Move to constant
    const decisionMap = {
      Accepted: 'accept',
      Reffered: 'refer',
    }

    if (fullUREresult && fullUREresult.results && fullUREresult.results.length) {
      const { results } = fullUREresult
      return [...results].reduce((acc, result) => {
        const { umeBenefits, enquiryId } = result
        const transformedUmeBenefit = [...umeBenefits]
        transformedUmeBenefit.forEach(umeBenefit => {
          // eslint-disable-next-line no-param-reassign
          umeBenefit.enquiryId = enquiryId
          umeBenefit.isTermsAccepted = umeBenefit.cta
            ? ''
            : decisionMap[umeBenefit.highLevelResults.decisionValue]
          let { umeCode } = umeBenefit
          const { waitingPeriod } = umeBenefit
          // eslint-disable-next-line prefer-destructuring
          if (umeCode !== 'CHILD_CI') umeCode = umeCode.split('_')[0]

          if (umeBenefit.loadings && umeBenefit.loadings.length) {
            // eslint-disable-next-line no-return-assign
            umeBenefit.loadings.map(loading => (loading.benefit = umeCode))
          } else {
            umeBenefit.loadings = []
          }

          if (umeBenefit.exclusion && umeBenefit.exclusion.length) {
            // eslint-disable-next-line no-return-assign
            umeBenefit.exclusion.map(exc => {
              exc.benefit = umeCode
              if (waitingPeriod) exc.waitingPeriod = waitingPeriod
              return exc
            })
          } else {
            umeBenefit.exclusion = []
          }
        })
        return [...acc, ...transformedUmeBenefit]
      }, [])
    }
    return []
  }

  getUmeMasterData() {
    const {
      ure: {
        initialData: { umeBenefits },
      },
    } = this.props

    if (umeBenefits) {
      const transformedUmeBenefit = [...umeBenefits]

      return transformedUmeBenefit
        .filter(benefit => benefit.types.includes('retail'))
        .map(benefit => {
          if (!Array.isArray(benefit.covers)) benefit.covers = [benefit.umeCode]
          else if (Array.isArray(benefit.covers) && !benefit.covers.length)
            benefit.covers = [...benefit.covers, benefit.umeCode]
          return benefit
        })
    }
    return []
  }

  getFilteredResultMappedWithBenfit(targetedCoverItem, targetedKey, enquiryId) {
    const targetedType = targetedCoverItem.type
    const targetedCover = this.getUmeMasterData().find(benefit =>
      benefit.covers.some(cover => cover.toLowerCase() === targetedType.toLowerCase())
    )
    const targetedEnquiryId = get(targetedCoverItem, 'childCIUnderwritingInfo.enquiryId', enquiryId)

    if (targetedCover) {
      let targetedUmeCode = targetedCover.umeCode

      if (
        targetedUmeCode === IP_COVER_DIGITAL_KEY_AIP ||
        targetedUmeCode === IP_COVER_DIGITAL_KEY_IP
      ) {
        const {
          coverStyle,
          waitingPeriod: { unit, value },
        } = targetedCoverItem

        targetedUmeCode = `${
          coverStyle === IP_COVER_STYLE_AGREED ? IP_COVER_DIGITAL_KEY_AIP : IP_COVER_DIGITAL_KEY_IP
        }_${
          IP_WAIT_PERIOD_WEEKWISE.find(
            period => period.unit === unit && period.value === Number(value)
          ).week
        }`
      } else if (targetedUmeCode === BE_COVER_DIGITAL_KEY_BE) {
        const {
          waitingPeriod: { unit, value },
        } = targetedCoverItem

        targetedUmeCode = `${BE_COVER_DIGITAL_KEY_BE}_${
          IP_WAIT_PERIOD_WEEKWISE.find(
            period => period.unit === unit && period.value === Number(value)
          ).week
        }`
      }

      const matchedResult = this.getConsolidatedFullUreResults().find(
        resultItem =>
          resultItem.umeCode.toLowerCase() === targetedUmeCode.toLowerCase() &&
          resultItem.enquiryId === targetedEnquiryId
      )
      if (matchedResult && matchedResult[targetedKey]) {
        return matchedResult[targetedKey]
      }
    }
    return null
  }

  getUnderwriterEntityData() {
    const {
      ure: {
        fullUREresult,
        activeEnquiryIds,
        initialData: { umeBenefits },
      },
      createQuote: { quotes, activeIndex },
      isUreAdditionalInfoFeature,
    } = this.props
    const { results, calculatedUREstatus, benefitList, evidences, timeReceived } = fullUREresult
    const activeQuoteBenefitList = getActiveBenefitList(quotes[activeIndex])
    return [...results].map(result => {
      const { enquiryId, isOpen, isSatisfied, isVoid } = result
      const isChildCI =
        activeEnquiryIds[0] &&
        activeEnquiryIds.find(enquiry => enquiry.enquiryId === enquiryId).isChildCI
      return {
        isChildCI,
        enquiryId,
        underwritingData: {
          calculatedUREStatus: calculatedUREstatus,
          ...(isUreAdditionalInfoFeature
            ? {
                benefitList: sortUreBenefitList(benefitList, umeBenefits),
                evidences,
                timeReceived,
              }
            : {}),
          isOpen,
          isSatisfied,
          isVoid,
          status: getUREStatus(
            enquiryId,
            activeQuoteBenefitList,
            isChildCI,
            fullUREresult,
            umeBenefits
          ),
          isGeneticTest: get(fullUREresult, 'geneticTest', false),
        },
      }
    })
  }

  updateBmiResults = () => {
    const {
      ure,
      actions: {
        createQuoteUpdateCoversCampaign,
        setHealthyLivingDiscountConsent,
        createQuoteAddHealthyLivingDiscountInMissingRequirements,
        updateCalculateUreStatus,
        createQuoteUpdateOption,
      },
      eligibleHealthyCovers,
      isHealthyLivingDiscount,
      ure: { fullUREresult },
    } = this.props

    const { calculatedUREstatus } = fullUREresult
    // bmi lies within the range or not
    const isBmiDiscountApplicable = getIsUREHealthyLivingDiscount(ure)

    // when healthy living discount is ticked and Bmi is not within the range
    if (isHealthyLivingDiscount && !isBmiDiscountApplicable) {
      // updating the ure status to TERM based on healthy living discount and Bmi
      if (calculatedUREstatus === CALCULATED_URE_ACCEPTED) {
        updateCalculateUreStatus(CALCULATED_URE_TERM)
      }
      // adding healthy living disount in missingRequirements in quotes based on URE status
      if (calculatedUREstatus === CALCULATED_URE_REFERRED) {
        createQuoteAddHealthyLivingDiscountInMissingRequirements()
      }
    }

    // updating option in store based on BMI value
    createQuoteUpdateOption({
      healthyLivingDiscount: isBmiDiscountApplicable,
    })
    // adding/removing campaign in covers based on BMI value
    createQuoteUpdateCoversCampaign({
      healthyLivingDiscount: isBmiDiscountApplicable,
      eligibleHealthyCovers,
    })
    // adding healthy living discount consent in quotes based on BMI value
    setHealthyLivingDiscountConsent(false)
  }

  saveLoadings() {
    const {
      actions: {
        updateLoading,
        createQuoteCalculate,
        removeAllExclusionsInQuoteNApplyFromURE,
        createQuoteApplyGeneticTestResult,
        updateUREDetailToUnderwriterEntity,
        confirmSmokerTerms,
        createQuoteChangeSmokerStatus,
        createQuoteUpdateIsNonStandard,
        createQuoteUpdateBMI,
        updateCalculateUreStatus,
      },
      createQuote,
      ure,
      isFromCustomerPortal,
      products,
    } = this.props
    const consolidatedFullUreResults = this.getConsolidatedFullUreResults()
    const changeInSmokerStatus = get(ure, 'fullUREresult.changeInSmokerStatus', false)
    const smokerStatusInURE = get(ure, 'fullUREresult.smokerStatusInURE', false)
    const calculatedUREstatus = get(ure, 'fullUREresult.calculatedUREstatus', '')
    if (changeInSmokerStatus) {
      confirmSmokerTerms()
      createQuoteChangeSmokerStatus(smokerStatusInURE)
    }
    const geneticTest = get(ure, 'fullUREresult.geneticTest', false)
    updateUREDetailToUnderwriterEntity(this.getUnderwriterEntityData())
    const loadingsList =
      consolidatedFullUreResults.length &&
      consolidatedFullUreResults.reduce((acc, ureItem) => [...acc, ...ureItem.loadings], [])
    const exlcusionList =
      consolidatedFullUreResults.length &&
      consolidatedFullUreResults.reduce((acc, ureItem) => [...acc, ...ureItem.exclusion], [])
    createQuoteApplyGeneticTestResult(geneticTest)
    if (loadingsList && loadingsList.length) updateLoading(loadingsList)
    if (exlcusionList) removeAllExclusionsInQuoteNApplyFromURE(exlcusionList)

    // update underwriting results based on underwriting decision for TELE and mylink
    const { quotes, activeIndex } = createQuote
    const underwritingDetails = quotes[activeIndex]
      ? get(quotes[activeIndex], 'underwritingDetails', '')
      : ''
    let doesHighLevelDecisionHasReferred = false

    if (underwritingDetails && underwritingDetails.status) {
      const { status } = underwritingDetails
      doesHighLevelDecisionHasReferred = status.some(
        ({ benefitCode, underwritingDecision }) =>
          benefitCode === POLICY_BENEFIT_CI_PLUS && underwritingDecision === DECISON_TYPE_REFER
      )
    }
    // update underwriting results before the condition of fullUREresult
    // in order to prevent it from overwritten
    if (
      underwritingDetails &&
      (underwritingDetails.underwritingMethod === MYLINK ||
        underwritingDetails.underwritingMethod === TELE) &&
      doesHighLevelDecisionHasReferred
    ) {
      createQuoteUpdateIsNonStandard(false)
      updateCalculateUreStatus(CALCULATED_URE_REFERRED)
    } else if (get(ure, 'fullUREresult.calculatedUREstatus', '') === CALCULATED_URE_TERM) {
      createQuoteUpdateIsNonStandard(true)
    } else {
      createQuoteUpdateIsNonStandard(false)
    }

    if (get(ure, 'fullUREresult.bmi', '')) {
      createQuoteUpdateBMI(ure.fullUREresult.bmi)
      this.updateBmiResults()
    }

    if (calculatedUREstatus) {
      createQuoteCalculate({
        initiateSaveQuote: true,
        notUpdateSeriesCode: hasSeriesCodeExpired(quotes[activeIndex], products),
        isFromCustomerPortal,
      })
    }
  }

  render() {
    const {
      fields,
      isOpenUWChangeModal,
      createQuote: { isFetchingData },
    } = this.props
    return (
      <Fragment>
        <WithLoader isLoading={isFetchingData} overlay>
          <ApplicationDeclarationComponent
            isTeleTermsConditions
            isPaddingNeeded={false}
            fields={fields}
            isCustomerPortal
          />
          {isOpenUWChangeModal && (
            <PSChangeModalComponent
              fields={reduceAuthorableFields(fields)}
              handleUWCloseModal={this.handleUWCloseModal}
            />
          )}
        </WithLoader>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  myLink: { showModal },
  createQuote,
  ure,
  productRules,
  config,
}) => {
  const isUreAdditionalInfoFeature = isFeatureEnabled('UreAdditionalInformation', config.FEATURES)
  return {
    isOpenUWChangeModal: showModal,
    createQuote,
    ure,
    eligibleHealthyCovers: getHealthyEligibleCovers(productRules),
    isHealthyLivingDiscount: getIsHealthyLivingDisount({ createQuote }),
    isFromCustomerPortal: config?.SITE === CUSTOMER_PORTAL,
    products: productRules.data,
    isUreAdditionalInfoFeature,
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDeclaration)
