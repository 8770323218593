import React from 'react'
import { type Field, Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import type { SitecoreField } from '../types/sitecore'

type Falsey = null | undefined | false | 0 | -0 | 0n | ''
type FieldValue<T> = T extends Field<infer V> ? V : T extends Falsey ? T : undefined
export type ResolvedFieldValues<T = unknown> = {
  [K in keyof T]: FieldValue<T[K]>
} & Record<string, never>

function valueOfTruthyField<T>(field: T): FieldValue<T> {
  if (field) {
    if (typeof field === 'object' && 'value' in field) {
      return field.value as FieldValue<T>
    }
    return undefined as FieldValue<T>
  }
  return field as FieldValue<T>
}

/**
 * Use this to extract the "value" field from the Sitecore fields
 * for easier consumption within our components.
 *
 * Where a field is not truthy, the field itself will be returned,
 * otherwise the 'value' property will be returned. The former is required
 * for badly authored tests which do not use the `makeField` or `makeBulkFields`
 * methods in `testUtils` to generate their properties.
 *
 * @param fields a key/value object from which to extract the values
 * @returns the extracted values from the `SitecoreField` objects
 */
export function reduceAuthorableFields<T extends object>(fields: T): ResolvedFieldValues<T> {
  return Object.entries(fields).reduce((obj, next) => {
    const key = next[0]
    const field: unknown = next[1]
    return {
      ...obj,
      [key]: valueOfTruthyField(field),
    }
  }, {} as Partial<ResolvedFieldValues<T>>) as ResolvedFieldValues<T>
}

/**
 * Type-guard for native JSS `Field<unknown>` objects
 *
 * @param field the object to test for type-safety
 * @returns `true` if the field is an object with a `value` property
 */
export function isAnySitecoreField(field?: unknown): field is Field {
  return typeof field === 'object' && field !== null && 'value' in field
}

/**
 * Type-guard for native JSS `Field<string>` objects (also checks 'value' type)
 *
 * @param field the object to test for type-safety
 * @returns `true` if the field is an object with a string `value` property
 */
export function isSitecoreField(field?: unknown): field is SitecoreField {
  return isAnySitecoreField(field) && typeof field.value === 'string'
}

function getAnySitecoreFieldValue(field?: unknown): unknown {
  return isAnySitecoreField(field) ? field.value : field
}

/**
 * Renders the passed-in Sitecore field as a plain text JSS element.
 *
 * @param field Sitecore Field to render as a text field
 * @returns the created `<Text />` element or `null` if the field is undefined
 */
function renderTextField(field: unknown): React.ReactNode

/**
 * Renders the passed-in Sitecore field as a plain text JSS element.
 *
 * @param field Sitecore Field to render as a text field
 * @param richText literal `false` for a plain text field
 * @param textProps additional properties for the `<Text />` element
 * @returns the created `<Text />` element or `null` if the field is undefined
 */
function renderTextField(
  field: unknown,
  richText: false,
  textProps?: React.ComponentPropsWithRef<typeof Text>
): React.ReactNode

/**
 * Renders the passed-in Sitecore field as a rich-text JSS element.
 *
 * @param field Sitecore Field to render as a text field
 * @param richText literal `true` for a rich text field
 * @param textProps additional properties for the `<RichText />` element
 * @returns the created `<RichText />` element or `null` if the field is undefined
 */
function renderTextField(
  field: unknown,
  richText: true,
  richTextProps?: React.ComponentPropsWithRef<typeof RichText>
): React.ReactNode

/**
 * Renders the passed-in Sitecore field as a plain-text or rich-text JSS element.
 *
 * @param field Sitecore Field to render as a text field
 * @param richText boolean value to indicate a rich-text field (defaults to 'false')
 * @param textProps additional properties for the `<Text />` or `<RichText />` element
 * @returns the created element or `null` if the field is undefined
 */
function renderTextField(
  field: unknown,
  richText?: boolean,
  extraProps?: React.ComponentPropsWithRef<typeof Text | typeof RichText>
): React.ReactNode

function renderTextField(
  field: unknown,
  richText = false,
  extraProps?: React.ComponentPropsWithRef<typeof Text | typeof RichText>
): React.ReactNode {
  if (field) {
    const value = getAnySitecoreFieldValue(field) as string // Not always true but we'll ignore this
    if (richText) {
      return <RichText field={{ value }} {...extraProps} />
    }
    return <Text field={{ value }} {...extraProps} />
  }
  return null
}

export { renderTextField }

/**
 * Extracts the "value" field from each of the Sitecore fields in the
 * `fields` property of each object within an array of content.
 *
 * Internally this calls {@link reduceAuthorableFields} and will use
 * the same reduction logic as that function.
 *
 * @param list an array of objects with an inner 'field' key/value object
 *  from which to extract the values
 * @returns the mapped array of extracted values from the `SitecoreField` objects
 */
export function reduceContentListFields<T extends object>(
  list: ReadonlyArray<{ fields: T }>
): Array<ResolvedFieldValues<T>> {
  return list.map(item => reduceAuthorableFields(item.fields))
}

/**
 * Very simple string interpolation function to convert '{key}' placeholders
 * in the 'field' value with the matching key's value from the 'data' object.
 *
 * When the expected 'key' is not available in the 'data' object then the placeholder
 * is returned as-is and not interpolation occurs (see example).
 *
 * @example
 * replaceLabelPlaceholders('hello {location}, foo {bar}', {location: 'world'})
 * // returns: 'hello world, foo {bar}'
 *
 * @param field a `SitecoreField` or simple string to use as the interpolation format
 * @param data a key/value object to use for the values of the interpolation
 * @returns the interpolated string, or `''` (blank string) if the field is not a string value
 */
export function replaceLabelPlaceholders(field: unknown, data: Record<string, unknown>) {
  const regExForBraces = /{(\w+)}/g
  // Field can be sitecore field or string
  const label = getAnySitecoreFieldValue(field)
  if (typeof label !== 'string') {
    return ''
  }
  return (
    label.replace(regExForBraces, (placeholder, key: string) =>
      key in data ? String(data[key]) : placeholder
    ) || ''
  )
}
