export const NAVIGATION_MAIN = 'NAVIGATION_MAIN'
export const NAVIGATION_QUOTE = 'NAVIGATION_QUOTE'
export const NAVIGATION_DECREASE = 'NAVIGATION_DECREASE'
export const NAVIGATION_APPLY = 'NAVIGATION_APPLY'
export const NAVIGATION_APPLICATION_OVERVIEW = 'NAVIGATION_APPLICATION_OVERVIEW'
export const NAVIGATION_DISABLED = 'NAVIGATION_DISABLED'
export const FOOTER_MAIN = 'FOOTER_MAIN'
export const FOOTER_DECREASE = 'FOOTER_DECREASE'
export const FOOTER_NO_LINKS = 'FOOTER_NO_LINKS'
export const FOOTER_DISABLED = 'FOOTER_DISABLED'
export const NAVIGATION_CUSTOMER = 'NAVIGATION_CUSTOMER'
export const NAVIGATION_ONLY_LOGO = 'NAVIGATION_ONLY_LOGO'
export const NAVIGATION_CUSTOMER_PERSONAL_STATEMENT = 'NAVIGATION_CUSTOMER_PERSONAL_STATEMENT'
export const NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION = 'NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION'
export const TASK_DASHBOARD_NAV_ID = 'taskDashboard'
export const RETENTION_NAV_ID = 'retention'
export const BENEFICIARIES_NAV_ID = 'beneficiaries'
export const CP_MAKE_A_PAYMENT_NAV_ID = 'makeAPayment'
export const CP_PRIMARY_MAKE_A_PAYMENT_NAV_ID = 'customer-primaryNavigationMakeAPayment'
export const REPORTING_NAV_ID = 'reporting'
export const CLIENT_LIST_NAV_ID = 'clientList'
export const MAKE_A_CHANGE_NAV_ID = 'make-a-change-link'
export const MY_ADVISER_NAV_ID = 'my-adviser'
export const PAYMENT_DETAILS_NAV_ID = 'paymentDetails'
export const DOCUMENTS_NAV_ID = 'documents'
