import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, space, fontWeights, fontSize } = Variables as VariablesType

const styles = {
  wrapper: {
    color: colours.tertiaryOne,
  },
  coverDetails: ({ isThreeColumnLayout }: { isThreeColumnLayout: boolean }) => ({
    display: 'flex',
    borderLeft: `1px solid ${colours.lightestGrey}`,
    borderRight: `1px solid ${colours.lightestGrey}`,
    '> div': {
      flexBasis: '180px',
      borderRight: `1px solid ${colours.lightestGrey}`,
      padding: space(3),
      '&:nth-of-type(2)': {
        flexBasis: isThreeColumnLayout ? '136px' : '195px',
      },
      '&:last-of-type': {
        flexGrow: 10,
        borderRight: 0,
        padding: 0,
      },
    },
  }),
  itemsGroup: ({ isThreeColumnLayout }: { isThreeColumnLayout: boolean }) => ({
    display: 'flex',
    '&:first-of-type': {
      borderBottom: `1px solid ${colours.lightestGrey}`,
    },
    '> div': {
      flexBasis: isThreeColumnLayout ? '33.33%' : '50%',
      padding: space(3),
      borderRight: `1px solid ${colours.lightestGrey}`,
      ...(isThreeColumnLayout
        ? {
            '&:nth-of-type(3)': {
              borderRight: 0,
            },
          }
        : {
            '&:nth-of-type(2)': {
              borderRight: 0,
            },
          }),
    },
  }),
  lifeInsured: {
    marginTop: space(3),
    fontSize: fontSize.xxs,
    '> div': {
      marginBottom: space(3),
      '&:first-of-type': {
        fontWeight: fontWeights.semiBold,
        marginBottom: space(1),
      },
    },
  },
  label: {
    fontWeight: fontWeights.semiBold,
    marginBottom: space(3),
    display: 'block',
  },
  childBenefits: {
    marginTop: space(3),
    fontSize: fontSize.xxs,
  },
  extendedBenefitLabel: {
    marginBottom: space(0.5),
  },
  childBenefitsList: {
    paddingLeft: space(2),
    'li::marker': {
      fontSize: fontSize.xs,
    },
  },
  premiumContainer: {
    padding: space(2.5, true),
    background: colours.tertiaryFive,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: space(2.5, true),
  },
  displayedPremium: ({ isNewPremium }: { isNewPremium?: boolean }) => ({
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
    marginTop: space(0.5),
    ...(isNewPremium && {
      color: colours.mlcAquaAccessible,
    }),
  }),
  sumInsuredItem: ({ isError }: { isError: boolean }) => ({
    ...(!isError && {
      input: {
        '&:not(:disabled)': {
          border: `1px solid ${colours.tertiaryThree}`,
          background: colours.tertiarySix,
        },
      },
    }),
  }),
  value: {
    fontSize: fontSize.xsm,
  },
  parentBenefit: {
    display: 'flex',
    flexDirection: 'column' as const,
    marginTop: space(3),
    svg: {
      marginBottom: space(0.5),
      fontSize: fontSize.smd,
    },
    '> div': {
      fontSize: fontSize.xxs,
    },
  },
  radioGroupWrapper: {
    '> div': {
      display: 'block',
    },
  },
  periodModalBtnContainer: {
    button: {
      '&:first-of-type(1)': {
        marginRight: space(3),
      },
    },
  },
}

export default styles
